import '../SASS/base/PaymentPage/_Payment.scss';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import PayOptions from '../components/Payment/PayOptions';
import LeftBox from '../components/LeftBox';
import {
  chosenFieldIdState,
  chosenSeatState,
  chosenTicketState,
  currentTeamStripeKey,
  matchState,
  ticketOrderLoading,
} from '../atom';
import useAuth from '../hooks/useAuth';
import api from '../api/api';
import {
  IMatch, IReceipt, ISeat, ITeam,
} from '../Interfaces';
import ReceiptSummary from '../components/Receipt/ReceiptSummary';
import Loading from '../components/Loading';
import useTransactionId from '../hooks/useTransactionId';

const PayUnpaidTicket = () => {
  const { team, matchId } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const [teamStripe, setTeamStripe] = useRecoilState(currentTeamStripeKey);
  const publicKey = teamStripe;
  let stripePromise = null;

  if (teamStripe !== '') {
    stripePromise = loadStripe(String(publicKey));
  }

  const [, setUnpaidTickets] = useState<any[] | undefined>(undefined);
  const [, setMatch] = useRecoilState<IMatch | undefined>(matchState);
  const [, setMatchLoading] = useRecoilState(ticketOrderLoading);
  const [, setTickets] = useRecoilState(chosenTicketState);
  const [, setSeats] = useRecoilState(chosenSeatState);
  const [, setFieldId] = useRecoilState(chosenFieldIdState);

  const [receipt, setReceipt] = useState<IReceipt | undefined>(undefined);
  const [receiptLoading, setReceiptLoading] = useState<boolean>(true);

  const transactionId = useTransactionId(location);

  const navigate = useNavigate();

  useEffect(() => {
    if (team && transactionId) {
      const fetchReceipt = async (runCount = 0) => {
        const maxRunCount = 3;
        setReceiptLoading(true);
        try {
          const receiptRes: IReceipt = await api().payments.getReceipt(
            team,
            transactionId,
          );

          setReceipt(receiptRes);
          const priceGroups = receiptRes.tickets.map(
            (ticket) => ticket.price_group,
          );
          setTickets(priceGroups);
          const ticketFieldId = receiptRes.tickets[0].seat_match.seat.row.field.id;
          setFieldId(ticketFieldId);
          const seats = receiptRes.tickets.map(
            (ticket) => ticket.seat_match.seat,
          );
          setSeats(seats);
          setReceiptLoading(false);
        } catch (err) {
          toast.error(String(err));
        }
      };

      fetchReceipt();
    }
  }, []);

  useEffect(() => {
    if (team) {
      const fetchTeamStripe = async () => {
        try {
          const teamRes: ITeam = await api().teams.getOneTeam(team);
          setTeamStripe(teamRes.stripe_publishable_key);
          stripePromise = loadStripe(String(teamRes.stripe_publishable_key));
        } catch (err) {
          if (err instanceof Error) {
            toast.error(err.message);
          }
        }
      };
      fetchTeamStripe();
    }

    return () => {
      setTeamStripe('');
    };
  }, [team]);

  useEffect(() => {
    if (team) {
      const fetchMatches = async () => {
        try {
          setMatchLoading(true);
          const matchRes = await api().matches.getMatches(team);
          setMatch(
            matchRes.find((_match: IMatch) => _match.id === Number(matchId)),
          );
        } catch (err) {
          const errMessage = 'Noe gikk galt ved henting av kamper';
          if (err instanceof Error) {
            err.message = errMessage;
          }
          toast.error(errMessage);
        } finally {
          setMatchLoading(false);
        }
      };

      const fetchUnpaidTickets = async () => {
        try {
          const unpaidTicketsRes = await api().tickets.getUnpaidTickets(team);
          setUnpaidTickets(Object.values(unpaidTicketsRes ?? '').flat());
        } catch (err) {
          toast.error(String(err));
        }
      };
      fetchUnpaidTickets();
      fetchMatches();
    }
  }, [team]);

  const retryTicketPaymentPayload: any = {
    transaction_id: transactionId,
    ticket_ids: receipt?.tickets?.map((ticket) => ticket.id),
    payment_method: receipt?.payment_method,
    fallBack: '',
    isApp: false,
    match_id: matchId,
  };

  return (
    <div className="payment">
      <div className="payment-container">
        <div className="payment-container_imgOverlay">
          <img
            src={`${process.env.PUBLIC_URL}/imgs/never-offside.jpg`}
            className="payment-container_imgOverlay--img"
            alt="background"
          />
        </div>

        <div
          className="Tickets-container_leftBox"
          style={{ paddingTop: '10rem' }}
        >
          <LeftBox
            siteTitle={`5. ${t('TicketNavigator.TitlePayment')}`}
            chosenField={undefined}
            chosenRow={undefined}
            teamParam={team}
            matchIdParam={matchId}
            bottomSectionClassCategory="payment"
            component={(
              <>
                <h1 className="payment-container_leftBox-bottomSection--title">
                  {t('UnpaidTickets.PageTitle')}
                </h1>
                <ReceiptSummary
                  team={team}
                  transactionId={transactionId}
                  receipt={receipt}
                  receiptLoading={receiptLoading}
                  matchId={matchId}
                />

                {!receiptLoading && (
                <span className="border" />
                )}

                {teamStripe !== '' && publicKey !== '' ? (
                  <Elements stripe={stripePromise}>
                    <PayOptions
                      team={team}
                      matchId={matchId}
                      setReceiptLoading={setReceiptLoading}
                      receiptLoading={receiptLoading}
                      transactionId={transactionId}
                      retryTicketPaymentPayload={retryTicketPaymentPayload}
                    />
                  </Elements>
                ) : (
                  <PayOptions
                    team={team}
                    matchId={matchId}
                    setReceiptLoading={setReceiptLoading}
                    receiptLoading={receiptLoading}
                    transactionId={transactionId}
                    retryTicketPaymentPayload={retryTicketPaymentPayload}
                  />
                )}
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default PayUnpaidTicket;
