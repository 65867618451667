/* eslint-disable react/jsx-no-useless-fragment */
import { RecaptchaVerifier, signInWithCustomToken } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import api from '../api/api';
import CorrectCredentials from '../components/Login/CorrectCredentials';
import OverlayLoading from '../components/OverlayLoading';
import PasswordReset from '../components/PasswordReset';
import { auth } from '../firebase';
import useAuth from '../hooks/useAuth';
import '../SASS/base/_LogIn.scss';
import { throwErrorToast } from '../utils/utils';

interface ILogin {
  team: string;
}

interface EmailLoginPayload {
  email: string;
  password: string;
}

const RestorePasswordPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const navigate = useNavigate();
  const { identity, token } = useParams<{identity:string;token:string}>();
  const confirmPasswordReset = async (e:any) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (password !== repeatPassword) {
        throw Object.assign(new Error(''), { code: 'auth/password-doesnt-match' });
      }
      if (password.length < 8) {
        throw Object.assign(new Error(''), { code: 'auth/password-too-short' });
      }
      await api().authorization.redeemPasswordReset({
        token,
        email: identity,
        password,
      });

      const { idToken } = await api().authorization.signInWithEmailPass(
        {
          email: identity || '',
          password,
        },
      );
      const { token: customToken } = await api().authorization.getCustomToken(idToken);
      await signInWithCustomToken(auth, customToken);
      navigate('/');
      return setLoading(false);
    } catch (err) {
      setLoading(false);
      return throwErrorToast(err);
    }
  };

  return (
    <>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        style={{ fontSize: '1.35rem', zIndex: 99999 }}
      />
      <div className="Login">
        <div id="recaptcha-container" />
        <OverlayLoading loading={loading} />
        <div className="Login-container">
          <div className="Login-container_textWrap">
            <h1 className="Login-container_textWrap--title">
              {t('RestorePassword.Title')}
            </h1>
          </div>
          <form
            className="Login-container_Form"
            onSubmit={(e) => confirmPasswordReset(e)}
          >
            <div className="Login-container_Form--inputWrapEmail">
              <div className="Login-container_Form--inputWrapEmail-div">
                <label htmlFor="email">{t('LogIn.Password')}</label>
                <input
                  type="password"
                  value={password}
                  required
                  name="password"
                  id="password"
                  className="Login-container_Form--inputWrapEmail-div_Input textInput"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="Login-container_Form--inputWrapEmail-div">
                <label htmlFor="password">{t('RestorePassword.RepeatPassword')}</label>
                <input
                  type="password"
                  value={repeatPassword}
                  required
                  name="repeatPassword"
                  id="repeatPassword"
                  className="Login-container_Form--inputWrapEmail-div_Input textInput"
                  onChange={(e) => setRepeatPassword(e.target.value)}
                />
              </div>
            </div>
            <button
              type="submit"
              className="Login-container_Form--btnOK buttonPrimary"
              disabled={repeatPassword === '' || password === ''}
            >
              {t('LogIn.ValidInputText')}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default RestorePasswordPage;
