import '../../SASS/base/FieldPage/_Fields.scss';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { IFieldType } from '../../Interfaces';

interface IFieldTypeProps {
    fieldType: IFieldType,
    updateIsOpen: (fieldTypeId: number) => void,
    isDark: (color: string) => boolean,
}

const FieldType = ({ fieldType, updateIsOpen, isDark }: IFieldTypeProps) => (
  <div
    className="fieldTypes"
    role="button"
    aria-hidden="true"
    onClick={() => updateIsOpen(fieldType.id)}
    key={fieldType.id}
    style={{ boxShadow: isDark(fieldType.colour) ? '' : 'rgba(0, 0, 0, 0.1) 0px 4px 12px', borderRadius: '1rem' }}
  >
    <div className="fieldTypes-titleWrap" style={{ backgroundColor: fieldType.colour, color: isDark(fieldType.colour) ? 'white' : 'black' }}>
      <div className="fieldTypes-titleWrap_icon" style={{ backgroundColor: isDark(fieldType.colour) ? '#fff' : '#000' }}>
        {!fieldType.isOpen ? <AiOutlinePlus style={{ fill: fieldType.colour }} />
          : <AiOutlineMinus style={{ fill: fieldType.colour }} className="minus" />}
      </div>
      {' '}
      <h1 className="fieldTypes-titleWrap_title">{fieldType.display_name}</h1>
    </div>
  </div>
);

export default FieldType;
