import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMatchedChosenTicketPackage, IQuantityPackageRule } from '../Interfaces';

interface IQuantityDiscountBannerProps {
    discountPackage: IMatchedChosenTicketPackage | undefined;
}

const QuantityDiscountBanner = ({ discountPackage }: IQuantityDiscountBannerProps) => {
  const { t } = useTranslation();

  return (
    <div className="discountPackageBanner">
      <p>
        {t('Discount.QuantityDiscount')}
        :
        {' '}
        {discountPackage?.ruleName}
        <br />
        {t('Discount.ReducedPrice')}
        :
        {' '}
        {discountPackage?.rulePrice}
        ,-
      </p>
    </div>
  );
};

export default QuantityDiscountBanner;
