import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const CountDown = () => {
  const { team, matchId } = useParams();

  const [count, setCount] = useState(900);
  const intervalCount = useRef<any | undefined>(undefined);

  const decNum = () => setCount((prev) => prev - 1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    intervalCount.current = setInterval(decNum, 1000);

    if (count === 0) {
      toast.info('Tiden for reservasjonen har utløpt, vennligst legg inn ordre på nytt.', {
        autoClose: false,
      });
      navigate(`/${team}/${matchId}/tickets`, { replace: true });
    }

    return () => clearInterval(intervalCount.current);
  }, [count]);
  return (
    <div className="countDown">
      <p>
        {t('PaymentPage.ReservationCounter')}
        {' '}
        {count === 900 ? '15:00'
          : `${Math.floor(count / 60)}:${count % 60 < 10 ? `0${count % 60}` : count % 60}`}

      </p>
    </div>
  );
};

export default CountDown;
