import { Link } from 'react-router-dom';
import {
  format, isPast, isSameDay, isFuture, parseISO,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import '../SASS/base/_TeamsPage.scss';
import { nb, enUS, sv } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { IMatch } from '../Interfaces';
import useWindowSize from '../hooks/useWindowSize';

interface IMatchCardProps {
    match: IMatch,
    currentTeamShortname: string | string[],
}

const MatchCard = ({ match, currentTeamShortname }: IMatchCardProps) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();

  let isActiveSale = isPast(parseISO(match.sale_start))
    && isFuture(parseISO(match.sale_end)) && match.use_websale === 1;
  if (match.sale_start === null && match.use_websale === 0) {
    isActiveSale = false;
  }

  const date = new Date();

  const locales: any = { nb, enUS, sv };

  const startsAtFull = format(
    parseISO(match.match_starts_at),
    'EEEE dd. MMMM HH:mm',
    { locale: locales[i18n.use(LanguageDetector).language] },
  );

  const startsAtMonth = format(
    parseISO(match.match_starts_at),
    'MMM',
    { locale: locales[i18n.use(LanguageDetector).language] },
  );

  const matchContent = (
    <div className="TeamsPage-container_matchList-match">
      <div className="TeamsPage-container_matchList-match_leftBox">
        <h1 className="TeamsPage-container_matchList-match_leftBox--date">
          {format(parseISO(match.match_starts_at), 'd')}
        </h1>
        <h2 className="TeamsPage-container_matchList-match_leftBox--month">
          {startsAtMonth}
        </h2>
      </div>
      <h1 className="TeamsPage-container_matchList-match_teamName">
        {match.home_team.name}
        {' '}
        -
        {' '}
        {match.away_team.name}
      </h1>
      <div className="TeamsPage-container_matchList-match_startDate">
        <h3 className="TeamsPage-container_matchList-match_startDate--date" style={{ textTransform: 'capitalize' }}>
          {startsAtFull}
        </h3>
      </div>

      <div className="TeamsPage-container_matchList-match_filters">
        <h3
          className="TeamsPage-container_matchList-match_filters-today"
          style={isSameDay(Date.parse(match.match_starts_at), date)
            ? { display: 'block' }
            : { display: 'none' }}
        >
          {isSameDay(parseISO(match.match_starts_at), date) ? t('MatchCard.MatchToday') : ''}
        </h3>
        <h3
          className="TeamsPage-container_matchList-match_filters-fewTickets"
          style={match.available_seats <= 300
            ? { display: 'block' }
            : { display: 'none' }}
        >
          {match.available_seats <= 300 && t('MatchCard.FewTickets')}
        </h3>
      </div>

      {width > 992 ? (
        <>
          {isActiveSale && (
          <button type="button" className="TeamsPage-container_matchList-match_ticketBtn">
            <p className="TeamsPage-container_matchList-match_ticketBtn--text">{t('MatchCard.TicketBtn')}</p>
          </button>
          )}

          {!isActiveSale && (
            <p className="TeamsPage-container_matchList-match_notActive">{t('MatchCard.NotActive')}</p>
          )}
        </>
      ) : (
        <img className="TeamsPage-container_matchList-match_mobileArrow" src="/imgs/right-arrow.svg" alt="Right arrow" />
      )}
    </div>
  );
  if (!isActiveSale) {
    return (
      <div className="TeamsPage-container_matchList-matchLink">
        {matchContent}
      </div>
    );
  }
  return (
    <Link
      className="TeamsPage-container_matchList-matchLink"
      to={`/${currentTeamShortname}/${match.id}/tickets`}
      key={`Match - ${match.id}`}
    >
      {matchContent}
    </Link>
  );
};

export default MatchCard;
