import '../SASS/base/_LogIn.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import api from '../api/api';
import { throwErrorToast } from '../utils/utils';

interface IPasswordResetProps{
    setPasswordResetPage: (arg0: boolean) => void;
    setLoading:(isLoading:boolean)=>void
}

const PasswordReset = ({ setPasswordResetPage, setLoading }: IPasswordResetProps) => {
  const [email, setEmail] = useState<string>('');
  const { t } = useTranslation();

  const handleSend = async (e:any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api().authorization.sendPasswordResetEmail(email);
      setPasswordResetPage(false);
      toast.info(t('RestorePassword.EmailSent'));
      return setLoading(false);
    } catch (err) {
      const error = err as {code:string};
      setLoading(false);
      return throwErrorToast(error);
    }
  };

  return (
    <form className="resetPassword" onSubmit={(e) => handleSend(e)}>
      <div className="resetPassword-container">
        <label htmlFor="emailReset">
          {t('LogIn.Email')}
        </label>
        <input
          type="email"
          placeholder={t('LogIn.Email')}
          value={email}
          required
          name="emailReset"
          id="emailReset"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          className="textInput"
        />
      </div>
      <button
        type="submit"
        className={email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? 'buttonPrimary' : 'buttonDisabled'}
      >
        {t('LogIn.ResetPasswordTitle')}
      </button>
    </form>

  );
};

export default PasswordReset;
