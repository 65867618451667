/* eslint-disable react/jsx-no-useless-fragment */
import { RecaptchaVerifier, signInWithCustomToken, UserCredential } from 'firebase/auth';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate, useParams, Link, useLocation,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import api from '../api/api';
import { AuthState } from '../atom';
import CorrectCredentials from '../components/Login/CorrectCredentials';
import OverlayLoading from '../components/OverlayLoading';
import PasswordReset from '../components/PasswordReset';
import { auth } from '../firebase';
import useAuth from '../hooks/useAuth';
import '../SASS/base/_LogIn.scss';
import { throwErrorToast } from '../utils/utils';

interface ILogin {
  team: string;
}

interface EmailLoginPayload {
  email: string;
  password: string;
}

const LogIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { team } = useParams();
  const [newUser, setNewUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const [captchaVerifier, setCaptchaVerifier] = useState<RecaptchaVerifier>();
  const [confirmation, setConfirmation] = useState<string>('');
  const [SMSCode, setSMSCode] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const user = useRecoilValue(AuthState);
  const recaptchaWrapperRef = createRef<HTMLDivElement>();

  const [emailLoginPayload, setEmailLoginPayload] = useState<EmailLoginPayload>(
    {
      email: '',
      password: '',
    },
  );
  const [passwordResetPage, setPasswordResetPage] = useState<boolean>(false);

  const [correctNum, setCorrectNum] = useState<boolean>(false);
  const [emailLogin, setEmailLogin] = useState<boolean>(false);

  const handleEmailLogin = async () => {
    const { email, password } = emailLoginPayload;
    const lowerCaseEmail = email?.toLowerCase();
    const trimmedEmail = lowerCaseEmail?.trim();
    setLoading(true);
    try {
      const emailExists = await api().authorization.doesUserExist({
        email: trimmedEmail,
      });
      if (!emailExists) {
        setLoading(false);
        return toast.info(t('Errors.NotRegisteredEmail'));
      }
      const { idToken } = await api().authorization.signInWithEmailPass({
        email: trimmedEmail,
        password,
      });
      const { token: customToken } = await api().authorization.getCustomToken(
        idToken,
      );
      await signInWithCustomToken(auth, customToken);
      toast.success(t('Auth.LoggedIn'));

      return setLoading(false);
    } catch (err) {
      const error = err as { message: string };
      setLoading(false);
      return throwErrorToast({ code: error.message });
    }
  };
  const generateCaptcha = () => {
    if (resendCode || correctNum) {
      window.recaptchaVerifier.clear();
      recaptchaWrapperRef.current!.innerHTML = '<div id="recaptcha-container"></div>';
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
      auth,
    );
  };
  const handlePhoneLogin = async () => {
    setLoading(true);
    try {
      const phoneExists = await api().authorization.doesUserExist({ phone: phoneNumber });
      if (!phoneExists) {
        setLoading(false);
        return toast.info(t('Errors.NotRegisteredPhone'));
      }

      if (!resendCode) {
        generateCaptcha();
      }

      if (!correctNum) {
        const recaptchaToken = await window.recaptchaVerifier.verify();
        const { sessionInfo } = await api().authorization.sendSignInWithPhone({
          phoneNumber,
          recaptchaToken,
        });
        setLoading(false);

        setConfirmation(sessionInfo);
      }
      return setCorrectNum(true);
    } catch (err) {
      const error = err as { message: string };
      setLoading(false);
      return throwErrorToast({ code: error.message });
    }
  };
  const sendNewVerifyCode = async () => {
    setLoading(true);
    try {
      generateCaptcha();

      const recaptchaToken = await window.recaptchaVerifier.verify();

      const { sessionInfo } = await api().authorization.sendSignInWithPhone({
        phoneNumber,
        recaptchaToken,
      });

      setResendCode(true);
      setConfirmation(sessionInfo);
      setCorrectNum(true);
    } catch (err) {
      throwErrorToast(err);
    }
    return null;
  };
  const verifyPhoneCode = async () => {
    setLoading(true);
    try {
      const { idToken } = await api().authorization.verifyPhoneCode({
        sessionInfo: confirmation,
        phoneNumber,
        code: SMSCode,
      });
      const { token: customToken } = await api().authorization.getCustomToken(
        idToken,
      );
      await signInWithCustomToken(auth, customToken);
      toast.success(t('Auth.LoggedIn'));
    } catch (err) {
      const error = err as { message: string };
      setLoading(false);
      return throwErrorToast({ code: error.message });
    }

    return null;
  };
  const switchInputTypes = () => {
    setEmailLogin((currentState) => !currentState);
    setEmailLoginPayload({ email: '', password: '' });
    setSMSCode('');
    setPhoneNumber('');
    setCorrectNum(false);
    setResendCode(false);
  };
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    if (emailLogin) {
      return handleEmailLogin();
    }
    return handlePhoneLogin();
  };

  useEffect(() => {
    if (user.isLoggedIn === true || user.isLoggedIn === 'newEmail') {
      const { state }: any = location;
      const from = state?.from ?? `/${team}`;
      setLoading(false);
      navigate(from, { replace: true });
    }
  }, [user]);

  if (passwordResetPage) {
    return (
      <div className="Login">
        <div className="Login-container">
          <div className="Login-container_textWrap">
            <h1 className="Login-container_textWrap--title">
              {t('LogIn.ResetPasswordTitle')}
            </h1>
            <p className="Login-container_textWrap--text">
              {t('LogIn.ResetPasswordText')}
            </p>
          </div>
          <PasswordReset
            setPasswordResetPage={setPasswordResetPage}
            setLoading={setLoading}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="Login">
      <div ref={recaptchaWrapperRef}>
        <div id="recaptcha-container" />
      </div>
      <OverlayLoading loading={loading} />
      <div className="Login-container">
        <div className="Login-container_textWrap">
          <h1 className="Login-container_textWrap--title">
            {t('LogIn.Title')}
          </h1>
          <p className="Login-container_textWrap--text">{t('LogIn.Text')}</p>
        </div>
        <form className="Login-container_Form" onSubmit={handleFormSubmit}>
          {emailLogin ? (
            <div className="Login-container_Form--inputWrapEmail">
              <div className="Login-container_Form--inputWrapEmail-div">
                <label htmlFor="email">{t('LogIn.Email')}</label>
                <input
                  type="email"
                  placeholder={t('LogIn.Email')}
                  value={emailLoginPayload.email}
                  required
                  name="email"
                  id="email"
                  className="Login-container_Form--inputWrapEmail-div_Input textInput"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailLoginPayload({
                    ...emailLoginPayload,
                    email: e.target.value,
                  })}
                />
              </div>
              <div className="Login-container_Form--inputWrapEmail-div">
                <label htmlFor="password">{t('LogIn.Password')}</label>
                <input
                  type="password"
                  value={emailLoginPayload.password}
                  required
                  name="password"
                  id="password"
                  className="Login-container_Form--inputWrapEmail-div_Input textInput"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailLoginPayload({
                    ...emailLoginPayload,
                    password: e.target.value,
                  })}
                />
              </div>
            </div>
          ) : (
            <div className="Login-container_Form--inputWrap">
              <label
                htmlFor="phoneNumber"
                className="Login-container_Form-div--label"
              >
                {t('LogIn.PhoneNumberText')}
              </label>
              <PhoneInput
                defaultCountry={team === 'taby' ? 'SE' : 'NO'}
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </div>
          )}
          {!correctNum && (
            <>
              {emailLogin ? (
                <button
                  type="submit"
                  name="emailLogin"
                  className="Login-container_Form--btnOK buttonPrimary"
                  disabled={
                    emailLoginPayload.password === ''
                    || emailLoginPayload.email === ''
                  }
                >
                  {t('LogIn.ValidInputText')}
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={phoneNumber === ''}
                  className={
                    phoneNumber === '' ? 'buttonDisabled' : 'buttonPrimary'
                  }
                  name="phoneLogin"
                >
                  {phoneNumber === ''
                    ? t('LogIn.NoLengthPhoneText')
                    : t('LogIn.ValidInputText')}
                </button>
              )}
            </>
          )}

          <CorrectCredentials
            correctNum={correctNum}
            SMSCode={SMSCode}
            setSMSCode={setSMSCode}
            resendCode={sendNewVerifyCode}
            handleSubmit={verifyPhoneCode}
            setResendCode={setResendCode}
          />

          <div className="Login-container_options">
            <button
              type="button"
              onClick={switchInputTypes}
              className="buttonPrimary"
            >
              {!emailLogin
                ? t('LogIn.SwitchButtonEmail')
                : t('LogIn.SwitchButtonPhone')}
            </button>

            {emailLogin && (
              <button
                type="button"
                onClick={() => setPasswordResetPage(true)}
                name="goToForgot"
                className="buttonLink"
              >
                {t('LogIn.ForgotPassword')}
              </button>
            )}
            <div className="Login-container_options-register">
              <p>{t('LogIn.FirstTimeHere')}</p>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to={`/${team}/register`}
                type="button"
                aria-label="button"
              >
                <p>{t('LogIn.Register')}</p>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LogIn;
