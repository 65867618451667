import {
  ApolloClient, createHttpLink, InMemoryCache,
} from '@apollo/client';
import axios, { AxiosError, ResponseType } from 'axios';
import { auth, firebaseConfig } from '../firebase';
import {
  afterSignUpMutation, assignUserToTeamMutation,
  redeemPasswordResetMutation, sendPasswordResetMutation,
  termsQuery,
  updateUserInfoMutation, userServiceInfoQuery,
} from '../hooks/useGql';
import {
  ITeam,
  IMatch,
  IField,
  IPriceGroup,
  IFee,
  IRow,
  ISeat,
  IPaymentOptions,
  IUserTicket,
  ITicketPaymentObj,
  IRetryMatchTicketPayment,
  IClubTerm,
  IRedeemPasswordReset,
  IClubConfig,
} from '../Interfaces';
import isDev from '../utils/isDev';
import {
  throwErrorToast, IDENTITY_RELYING_PARTY_URL, IDENTITY_TOOLKIT_URL,
} from '../utils/utils';
import { IAfterSignup } from './api.types';

type FetchMethod='GET'|'POST'
export default () => {
  const selectedTeam = window.location.pathname.split('/')[1];
  const getServiceUrl = (service: string) => `https://${service}.api${isDev() ? '-dev' : ''}.fangroup.io`;
  const userServiceUrl = `${getServiceUrl('user')}/api`;
  const websaleServiceUrl = `${getServiceUrl('websale')}`;

  const fetchData = async (
    method:FetchMethod,
    url:string,
    _payload?:{[key:string]:any},
    authenticate = true,
    responseType: ResponseType = 'json',
  ) => {
    const { header, ...payload } = _payload || {};
    const headers:{[key:string]:any} = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(header || {}),
    };
    if (authenticate) {
      try {
        const FIRToken = await auth?.currentUser?.getIdToken();
        headers.Authorization = `Bearer ${FIRToken}`;
      // eslint-disable-next-line no-empty
      } catch {
        throwErrorToast({ err: 'auth/token-not-present' });
        await auth.signOut();
        return '';
      }
    }
    try {
      const { data } = await axios({
        url,
        method,
        data: payload,
        headers,
        responseType,
        params: method.toLowerCase() === 'get' && payload,
      });
      return data;
    } catch (err) {
      const error = err as AxiosError;
      const errorData = error?.response?.data as {error:any};
      throw errorData.error;
    }
  };
  const getGraphQLClient = async (type = 'user', authenticate = true, oneTimeToken = '', team:{name:string;id:string;}|null = null) => {
    let graphQLUrl = `${getServiceUrl(type)}/graphql`;
    if (type === 'user') {
      graphQLUrl = `${getServiceUrl(type)}/api/graphql`;
    }
    const headers:{Authorization?:string} = {};
    if (authenticate) {
      const FIRToken = await auth?.currentUser?.getIdToken();
      headers.Authorization = `Bearer ${FIRToken}`;
    }
    if (oneTimeToken) {
      headers.Authorization = `Bearer ${oneTimeToken}`;
    }
    if (type === 'user-term') {
      // @ts-ignore
      headers['fep-context-name'] = team.name;
      // @ts-ignore
      headers['fep-context'] = team.id;
    }

    const link = createHttpLink({
      uri: graphQLUrl,
      headers,
    });
    const apolloClient = new ApolloClient({
      link,
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: {
          fetchPolicy: 'no-cache',
        },
      },
    });
    return apolloClient;
  };
  const getClubConfig = async (clubShort:string):Promise<IClubConfig> => fetchData('GET', `${getServiceUrl('club')}/club/teamConfig`, {
    header: {
      'fep-context-name': clubShort,
    },
  });
  return {
    getServiceUrl,
    websaleServiceUrl,
    teams: {
      getClubConfig,
      getOneTeam: (
        team: string,
      ): Promise<ITeam> => fetchData('GET', `${websaleServiceUrl}/teams/${team}`, {}, false),
      getTeams: (): Promise<ITeam[]> => fetchData('GET', `${websaleServiceUrl}/teams/`, {}, false),
    },
    terms: {
      getAllTerms: async () => {
        // @ts-ignore
        const clubConfig = await getClubConfig(selectedTeam);
        const client = await getGraphQLClient('user-term', true, '', {
          id:clubConfig?.id as string,
          name:clubConfig?.club_name_short,
        });
        const { data: { terms } } = await client.query({
          query: termsQuery,
        }) as any as {
          data:{
            terms:{
              type:string;
              title:string;
              intro:string;
              description:string;
          }[]
        }};
        return terms;
      },
      getTerms: (
        team: string,
      ): Promise<IClubTerm[]> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/allTerms`, {}, false),
    },
    matches: {
      getSeats: (
        team:string,
        matchId: string,
        rowId: number,
      ): Promise<ISeat[]> => fetchData('POST', `${websaleServiceUrl}/teams/${team}/matches/${matchId}/availableSeatAlternatives`, { row_id: rowId }),
      getRows: (
        team: string,
        matchId: string,
        fieldId: number,
      ): Promise<IRow[]> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/matches/${matchId}/rowAlternatives/${fieldId}`),
      getFees: (
        team: string,
        matchId: string,
      ): Promise<IFee[]> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/matches/${matchId}/fees`),
      getFields: (
        team: string,
        matchId: string,
      ): Promise<IField[]> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/matches/${matchId}/fields`),
      getPriceGroups: (
        team: string,
        matchId: string,
      ): Promise<IPriceGroup[]> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/matches/${matchId}/priceGroups`),
      getMatches: (
        team: string,
      ): Promise<IMatch[]> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/matches`, {}, false),
      getUnmarkedSeats: (team: string, matchId: string, payload: { field_id: number, number_of_tickets: number }): Promise<ISeat[]> => fetchData('POST', `${websaleServiceUrl}/teams/${team}/matches/${matchId}/giveUnmarkedSeatReservations`, payload),
    },
    payments: {
      retryMatchTicketPayment: (team: string, payload: IRetryMatchTicketPayment): Promise<any> => fetchData('POST', `${websaleServiceUrl}/teams/${team}/tickets/retryMatchTicketPayment`, payload),
      initiateMatchTicketPayment: (team: string, payload: ITicketPaymentObj): Promise<any> => fetchData('POST', `${websaleServiceUrl}/teams/${team}/websales/initiateMatchTicketPayment`, payload),
      checkPaymentOptions: (team: string): Promise<IPaymentOptions> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/paymentMethods`, {}, false),
      getReceipt: (team: string, transactionId?: string): Promise<any> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/tickets/receipt/${transactionId}`),
      sendReceiptEmail: (team: string, transactionId: string, payload: { email: string }, locale: string): Promise<any> => fetchData('POST', `${websaleServiceUrl}/teams/${team}/tickets/send_receipt/${transactionId}?lang=${locale}`, payload),
      checkDiscount: (team: string, code: string): Promise<any> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/checkDiscountValidity/${code}`, {}, false),
      verifyPayment: (team: string, transactionId: string): Promise<any> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/verifyPayment/${transactionId}`),
      packages: (team: string): Promise<any> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/rule_packages`),
    },
    tickets: {
      getUnpaidTickets: (team: string) => fetchData('GET', `${websaleServiceUrl}/teams/${team}/tickets/getUnpaidTickets`),
      getUserTickets: (team: string): Promise<IUserTicket[]> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/tickets/getUserTickets`),
      getTicketReceiptPDF: (team: string, transactionId: string, locale: string): Promise<any> => fetchData('GET', `${websaleServiceUrl}/teams/${team}/tickets/printReceipt/${transactionId}?lang=${locale}`, {}, true, 'blob'),
    },
    authorization: {
      sendSignInWithPhone: (payload: {recaptchaToken:string; phoneNumber:string}): Promise<{sessionInfo:string}> => fetchData('POST', `${IDENTITY_TOOLKIT_URL}/accounts:sendVerificationCode?key=${firebaseConfig.apiKey}`, payload, false),
      verifyPhoneCode: (payload: {sessionInfo:string; phoneNumber:string;code:string}): Promise<{idToken:string}> => fetchData('POST', `${IDENTITY_TOOLKIT_URL}/accounts:signInWithPhoneNumber?key=${firebaseConfig.apiKey}`, payload, false),
      afterSignup: async (payload:IAfterSignup, oneTimeToken:string) => {
        const {
          email, password, lastName, firstName,
        } = payload;
        const client = await getGraphQLClient('user', false, oneTimeToken);
        const { signUp } = await client.mutate({
          mutation: afterSignUpMutation,
          variables: {
            email,
            password,
            lastName,
            firstName,
            team: selectedTeam,
          },
        }) as {signUp:boolean};
        return signUp;
      },
      signInWithEmailPass: (payload: {email:string; password:string;}): Promise<{idToken:string}> => fetchData('POST', `${IDENTITY_RELYING_PARTY_URL}/verifyPassword?key=${firebaseConfig.apiKey}`, { ...payload, returnSecureToken: true }, false),
      getCustomToken: (firebaseToken:string):Promise<{token:string}> => fetchData('GET', `${getServiceUrl('authorization')}/getToken`, {
        header: {
          authorization: `Bearer ${firebaseToken}`,
        },
      }, false),
      doesUserExist: async (
        { phone, email }: {phone?: string, email?: string},
      ):Promise<boolean> => {
        let paramQuery: string | undefined = `phoneNumber=${encodeURIComponent(phone || '')}`;
        if (email) {
          paramQuery = `email=${encodeURIComponent(email)}`;
        }
        try {
          await fetchData('GET', `${userServiceUrl}/doesUserExist?${paramQuery}`, {}, false);
          return true;
        } catch {
          return false;
        }
      },
      sendPasswordResetEmail: async (email:string) => {
        await fetchData('POST', `${userServiceUrl}/setDeepLinkToApp`, {
          email,
          appName: `websale${selectedTeam === 'tfg' ? '-dev' : ''}`,
        }, false);
        const client = await getGraphQLClient('user', false);
        const { data: { sendUserPasswordResetLink } } = await client.mutate({
          mutation: sendPasswordResetMutation,
          variables: {
            email,
          },
        }) as {data:{sendUserPasswordResetLink:boolean}};
        if (!sendUserPasswordResetLink) {
          throw Object.assign(new Error(), { code: 'EMAIL_NOT_SENT' });
        }
        return sendUserPasswordResetLink;
      },
      redeemPasswordReset: async ({ email, token, password }:IRedeemPasswordReset) => {
        const client = await getGraphQLClient('user', false);
        const { data: { redeemUserPasswordResetToken } } = await client.mutate({
          mutation: redeemPasswordResetMutation,
          variables: {
            email, token, password,
          },
        }) as {data:{redeemUserPasswordResetToken:{code:string}}};
        if (redeemUserPasswordResetToken) {
          throw Object.assign(new Error(), { code: redeemUserPasswordResetToken.code });
        }
        return true;
      },
    },
    users: {
      assignUserToTeam: async () => {
        const client = await getGraphQLClient('user', true);
        const email = auth?.currentUser?.email;
        const { data: { signUp } } = await client.mutate({
          mutation: assignUserToTeamMutation,
          variables: {
            email,
            team: selectedTeam,
          },
        }) as { data: {
            signUp: boolean}
          };
        return signUp;
      },
      fetchUserServiceInfo: async () => {
        const client = await getGraphQLClient('user', true);
        const firUserUid = auth?.currentUser?.uid;
        const { data: { users } } = await client.query({
          query: userServiceInfoQuery,
          variables: {
            whereClause: {
              userCloudId: {
                equals: firUserUid,
              },
            },
          },
        }) as any as {data:{users:{id:string;
          teams:
          {monolithUserId:string;team:{name:string}}[]}[]}};
        return users[0];
      },
      editUserServiceInfo: async (userServiceId:string, data:{[key:string]:string}) => {
        const client = await getGraphQLClient('user', true);
        const { updateUser } = await client.mutate({
          mutation: updateUserInfoMutation,
          variables: {
            where: {
              id: userServiceId,
            },
            data,
          },
        }) as {updateUser:{[key:string]:string}};
        return updateUser;
      },

    },
  };
};
