/* eslint-disable no-unsafe-optional-chaining */
import '../../SASS/base/PaymentPage/_Payment.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { MdClose } from 'react-icons/md';
import api from '../../api/api';
import InputDiscountField from './Discount/InputDiscountField';
import DiscountSummary from './Discount/DiscountSummary';
import {
  chosenRowState,
  chosenFieldState,
  chosenSeatQuantity,
  chosenTicketState,
  chosenSeatState,
  matchState,
  FeeState,
  discountState,
  quantityDiscountPackageRules,
  showQuantityDiscountBanner,
} from '../../atom';
import {
  IPriceGroup, ISeat, IDiscount, IRecoilHoc,
} from '../../Interfaces';
import calculatePrice from '../../utils/CalcPrice';
import RecoilHoc from '../RecoilHoc';
import { convertCurrency } from '../../utils/utils';

const Summary = ({ currentTeamInfo: { currency } }:IRecoilHoc) => {
  const { team, matchId } = useParams<string>();

  const [tickets] = useRecoilState(chosenTicketState);
  const [field] = useRecoilState(chosenFieldState);
  const [row] = useRecoilState(chosenRowState);
  const [seatCount] = useRecoilState(chosenSeatQuantity);
  const [chosenSeats, setChosenSeats] = useRecoilState(chosenSeatState);
  const [match] = useRecoilState(matchState);
  const [fees] = useRecoilState(FeeState);

  const [showAddDiscount, setShowAddDiscount] = useState<boolean>(true);
  const [showCampaign, setShowCampaign] = useState<boolean>(false);

  const [discountValue, setDiscountValue] = useState<string>('');

  const [discountRes, setDiscountRes] = useRecoilState<IDiscount | undefined>(
    discountState,
  );

  const matchedChosenTicketPackage = useRecoilValue(quantityDiscountPackageRules);
  const showQuantityDiscountBannerState = useRecoilValue(showQuantityDiscountBanner);

  const { t } = useTranslation();

  const handleDiscount = async (e: any) => {
    e.preventDefault();

    if (team) {
      try {
        const resDiscountCode = await api().payments.checkDiscount(team, discountValue);

        if (resDiscountCode.season === 1 && resDiscountCode.exists === true) {
          setDiscountRes({
            ...resDiscountCode,
            exists: false,
          });
        } else {
          setDiscountRes(resDiscountCode);
        }

        if (!discountRes?.active) {
          setShowCampaign(false);
        } else {
          setShowCampaign(true);
        }
      } catch (err) {
        if (err instanceof Error) {
          toast.error(err.message);
        }
      }
    }
  };

  const discountInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (discountInputRef.current) {
      discountInputRef.current.focus();
    }
  }, [showAddDiscount]);

  return (
    <div className="summary">
      <div className="summary-matchInfo">
        <h2 className="summary-matchInfo_match">
          {`${match?.home_team.name} - ${match?.away_team.name}`}
        </h2>
        <h3 className="summary-matchInfo_matchDate">
          {match?.match_starts_at_readable}
        </h3>
      </div>

      <div className="summary-seats">
        <h1 className="summary-seats_title">
          {t('PaymentPage.SeatSectionTitle')}
        </h1>
        <div className="summary-seats_chosen">
          <p className="summary-seats_chosen--field">
            {t('PaymentPage.SeatSectionField')}
            {' '}
            {field?.name}
            ,
          </p>
          {field?.field_type.is_unmarked ? (
            <p className="summary-seats_chosen--row">
              Free seating
            </p>
          ) : (
            <>
              <p className="summary-seats_chosen--row">
                {t('PaymentPage.SeatSectionRow')}
                {' '}
                {row?.number}
                ,
              </p>
              <p className="summary-seats_chosen--seats">
                {seatCount <= 1 || 0
                  ? t('PaymentPage.SeatSectionSeatSingle')
                  : t('PaymentPage.SeatSectionSeatMultiple')}
                {`${chosenSeats.map((seat) => ` ${seat.seats_seat_id}`)}`}
              </p>
            </>

          )}

        </div>
      </div>

      <div className="summary-quantity">
        <h1 className="summary-quantity_title">
          {t('PaymentPage.QuantitySectionTitle')}
        </h1>
        <div className="summary-quantity_info">
          {tickets.map((ticket: IPriceGroup) => (
            <div className="summary-quantity_info-Wrap" key={ticket.id}>
              <p className="summary-quantity_info-Wrap--quantity">
                {ticket.amount}
                {' '}
                {ticket.title}
              </p>
              <p className="summary-quantity_info-Wrap--price">
                {`${convertCurrency(currency)} ${Number(field?.field_type.price) * Number(ticket.percentage) * 0.01 * ticket.amount},-`}
              </p>
            </div>
          ))}
        </div>

      </div>

      {fees.length > 0 ? (
        <div className="summary-fees">

          <h1 className="summary-fees_title">{t('ReceiptPage.OrderFees')}</h1>

          <div className="summary-fees_info">
            {fees.map((fee) => (
              <div className="summary-fees_info-Wrap" key={fee.id}>
                <p className="summary-fees_info-Wrap--title">
                  {fee.name}
                  {' '}
                  x
                  {' '}
                  {tickets.map((ticket) => ticket.amount).reduce((a, b) => a + b, 0)}
                </p>
                <p className="summary-fees_info-Wrap---price">
                  {fee.percentage}
                  %
                </p>
              </div>
            ))}
          </div>

        </div>
      ) : null}

      {discountRes === undefined && showAddDiscount && (
        <button
          type="button"
          className="summary-discountButton"
          onClick={() => setShowAddDiscount(false)}
        >
          {t('PaymentPage.CampaignSectionAddCode')}
        </button>
      )}
      {!showAddDiscount && !showCampaign && !discountRes?.active && (
        <InputDiscountField
          handleDiscount={handleDiscount}
          setDiscountValue={setDiscountValue}
          setShowAddDiscount={setShowAddDiscount}
          setShowCampaign={setShowCampaign}
          setDiscountRes={setDiscountRes}
          discountValue={discountValue}
          discountInputRef={discountInputRef}
          discountRes={discountRes}
        />
      )}
      {(showCampaign && discountRes?.exists === true) || showQuantityDiscountBannerState ? (
        <DiscountSummary
          setShowAddDiscount={setShowAddDiscount}
          setShowCampaign={setShowCampaign}
          setDiscountRes={setDiscountRes}
          discountRes={discountRes}
          showQuantityDiscountBannerState={showQuantityDiscountBannerState}
          matchedChosenTicketPackage={matchedChosenTicketPackage}
        />
      ) : null}

      {(discountRes?.active === false || discountRes?.exists === false) && (
        <div className="summary-campaign">
          <p className="summary-campaign_discountTitle wrongDiscountCode">
            {t('PaymentPage.CampaignNoCode')}
            {' '}
            {discountRes?.code}
          </p>
        </div>
      )}

      <div className="summary-prices">
        <div className="summary-prices_partialPrice">
          <p>{t('PaymentPage.CampaignSectionPartialPrice')}</p>
          <p>
            {`${convertCurrency(currency)}
             ${calculatePrice(tickets, Number(field?.field_type.price), fees)},-`}
          </p>
        </div>
        {discountRes?.active && (
          <div className="summary-prices_discountPrice">
            <p>{t('PaymentPage.CampaignSectionTitle')}</p>

            {discountRes.percentage === 1 ? (
              <span>
                {`-${convertCurrency(currency)} ${discountRes?.discount
                  * 0.01
                  * calculatePrice(
                    tickets,
                    Number(field?.field_type.price),
                    fees,
                    undefined,
                    showQuantityDiscountBannerState,
                    matchedChosenTicketPackage?.rulePrice,
                  )},-`}
              </span>
            ) : (
              <span>
                {`-${convertCurrency(currency)} ${discountRes?.discount},-`}
              </span>
            )}

          </div>
        )}
        {showQuantityDiscountBannerState && matchedChosenTicketPackage && (
          <div className="summary-prices_package">
            <p>
              {matchedChosenTicketPackage?.ruleName}

            </p>
            <span>
              {`-${convertCurrency(currency)} ${matchedChosenTicketPackage?.rulePrice

              },-`}
            </span>
          </div>
        )}

        <div className="summary-prices_finalPrice">
          <p>{t('PaymentPage.CampaignSectionTotalPrice')}</p>
          <span>
            {`${convertCurrency(currency)} ${discountRes?.exists
              ? calculatePrice(
                tickets,
                Number(field?.field_type.price),
                fees,
                discountRes,
                showQuantityDiscountBannerState,
                matchedChosenTicketPackage?.rulePrice,
              )
              : calculatePrice(
                tickets,
                Number(field?.field_type.price),
                fees,
                undefined,
                showQuantityDiscountBannerState,
                matchedChosenTicketPackage?.rulePrice,
              )},-`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default RecoilHoc(Summary);
