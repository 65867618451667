/* eslint-disable react/jsx-no-useless-fragment */
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { format, isTomorrow, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { nb, enUS, sv } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import '../SASS/base/PurchasePage/_PurchaseHistory.scss';
import { IUserTicket } from '../Interfaces';

interface IPurchaseHistoryCard {
  userTicket: IUserTicket | any;
  currentTeamShortname: string;
  paid: boolean;
  disabled?: boolean;
}

const PurchaseHistoryCard = ({
  userTicket,
  currentTeamShortname,
  paid,
  disabled,
}: IPurchaseHistoryCard) => {
  const { t } = useTranslation();
  const [, setBrowserLang] = useState<string>('');

  const locales: any = { nb, enUS, sv };

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      setBrowserLang(navigator.language);
    }
  }, [navigator]);

  const startsAt = format(
    parseISO(userTicket?.seat_match.match.match_starts_at),
    'EEEE dd. MMMM HH:mm',
    { locale: locales[i18n.use(LanguageDetector).language] },
  );

  const ticketContent = (
    <div className="PurchaseHistory-container_ticketList-ticket">
      <div className="splitTicket">

        <div className="ticketInfoWrap">

          <div
            className="PurchaseHistory-container_ticketList-ticket_filters"
            style={
          isTomorrow(Number(startsAt))
            ? { display: 'block' }
            : { display: 'none' }
        }
          >
            <div className="PurchaseHistory-container_ticketList-ticket_filters-tomorrow" />
            <h3 className="PurchaseHistory-container_ticketList-ticket_filters-tomorrow-text">
              {isTomorrow(parseISO(userTicket.seat_match.match.match_starts_at))
                ? t('PurchaseHistoryCard.MatchTomorrow')
                : ''}
            </h3>

          </div>

          {paid === false ? (
            <div
              className="PurchaseHistory-container_ticketList-ticket_filters"
            >
              <div className="PurchaseHistory-container_ticketList-ticket_filters-toBePaid" />
              <h3 className="PurchaseHistory-container_ticketList-ticket_filters-toBePaid-text">
                {t('UnpaidTickets.NeedsAction')}
              </h3>
            </div>
          ) : ('')}

          <h1 className="PurchaseHistory-container_ticketList-ticket_teamName">
            {userTicket.seat_match.match.home_team.name}
            {' '}
            -
            {' '}
            {userTicket.seat_match.match.away_team.name}
          </h1>
          <div className="PurchaseHistory-container_ticketList-ticket_startDate">
            <h3
              className="PurchaseHistory-container_ticketList-ticket_startDate--date"
              style={{ textTransform: 'capitalize' }}
            >
              {startsAt}
            </h3>
          </div>

          {userTicket.seat_match.match.company?.address
        && userTicket.seat_match.match.company.city && (
          <div className="PurchaseHistory-container_ticketList-ticket_address">
            <h3 className="PurchaseHistory-container_ticketList-ticket_address--location">
              {userTicket.seat_match.match.company?.address}
              {' '}
              ,
              {' '}
              {userTicket.seat_match.match.company?.city}
            </h3>
          </div>
          )}

          <div className="PurchaseHistory-container_ticketList-ticket_tickets">
            {/* <h3 className="PurchaseHistory-container_ticketList-ticket_tickets--amount">
              <TbTicket
                style={{ color: '#525252' }}
              />
              {receipt?.tickets?.length ?? 0}
              {' '}
              {receipt?.tickets?.length === 1
                ? t('PurchaseHistoryCard.Ticket')
                : t('PurchaseHistoryCard.Tickets')}
            </h3> */}
            {userTicket?.payment?.came_from && (
            <h3 className="PurchaseHistory-container_ticketList-ticket_tickets--amount">
              {t('PurchaseHistoryCard.Bought_From')}
              :
              {' '}
              {userTicket?.payment.came_from}
            </h3>
            )}
            <h3
              className="PurchaseHistory-container_ticketList-ticket_tickets--amount"
              style={{ textTransform: 'capitalize' }}
            >
              {t('PurchaseHistoryCard.Bought')}
              :
              {' '}
              {navigator
            && format(
              parseISO(paid ? userTicket.payment.bought_at : userTicket.created_at),
              'EEEE dd. MMMM HH:mm',
              { locale: locales[i18n.use(LanguageDetector).language] },
            )}
            </h3>
          </div>
        </div>

        {disabled ? ('') : (
          <button
            type="button"
            className="buttonPrimary seeTicketsBtn"
            disabled={disabled}
          >
            {t('PurchaseHistoryCard.SeeTicket')}
          </button>
        ) }
      </div>

    </div>
  );
  return (
    !disabled ? (
      <Link
        className="PurchaseHistory-container_ticketList-ticketLink"
        to={`/${currentTeamShortname}/${userTicket.seat_match.match.id}/${paid ? 'transaction'
          : 'unpaid'}?${userTicket?.payment?.transaction_id}`}
        key={`Match - ${userTicket.seat_match.match.id}`}
      >
        {ticketContent}
      </Link>
    ) : (
      <button
        type="button"
        disabled
        className="PurchaseHistory-container_ticketList-ticketLink"
      >
        {ticketContent}
      </button>
    )

  );
};

PurchaseHistoryCard.defaultProps = {
  disabled: false,
};

export default PurchaseHistoryCard;
