import '../SASS/base/FieldPage/_Fields.scss';
import { useNavigate, useParams } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  ticketOrderLoading,
  FieldTypeState,
  fieldLoading,
  matchState,
  fieldImage,
  chosenTicketState,
} from '../atom';
import TicketOrder from '../components/Tickets/TicketOrder';
import { IField, IFieldType, IMatch } from '../Interfaces';
import useWindowSize from '../hooks/useWindowSize';
import Dropdown from '../components/Fields/Dropdown';
import FieldType from '../components/Fields/FieldType';
import LeftBox from '../components/LeftBox';
import MobileMapModal from '../components/Mobile/MobileMapModal';
import Loading from '../components/Loading';

const Fields = () => {
  const { team, matchId } = useParams<string>();
  const [match] = useRecoilState<IMatch | undefined>(matchState);

  const [matchLoading] = useRecoilState<boolean>(ticketOrderLoading);
  const [_fieldLoading] = useRecoilState(fieldLoading);
  const [_fieldTypes, _setFieldTypes] = useRecoilState(FieldTypeState);
  const [, _setFieldImage] = useRecoilState(fieldImage);
  const [chosenPriceGroup] = useRecoilValue(chosenTicketState);

  const navigate = useNavigate();

  useEffect(() => {
    if (!chosenPriceGroup) {
      return navigate(`/${team}/${matchId}/tickets`, { replace: true });
    }

    return undefined;
  }, []);

  const [toggleMap, setToggleMap] = useState<boolean>(false);

  const { t } = useTranslation();
  const { width } = useWindowSize();

  const updateIsOpen = (fieldTypeId: number) => {
    const fieldTypeIndex = _fieldTypes.findIndex(
      (fieldType: IFieldType) => fieldType.id === fieldTypeId,
    );
    const tmpFieldTypes = JSON.parse(JSON.stringify(_fieldTypes));
    tmpFieldTypes[fieldTypeIndex].isOpen = !tmpFieldTypes[fieldTypeIndex].isOpen;
    _setFieldTypes(tmpFieldTypes);
  };

  const isDark = (color: string, limit: number = 150) => {
    const splitColor = color.charAt(0) === '#' ? color.substring(1, 7) : color;
    const r = parseInt(splitColor.substring(0, 2), 16); // hexToR
    const g = parseInt(splitColor.substring(2, 4), 16); // hexToG
    const b = parseInt(splitColor.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 < limit;
  };

  useEffect(() => {
    _setFieldImage(`${window.location.origin}/imgs/stadium/${team}-stadium.png`);
  }, []);

  return (
    <div className="section">
      <div className="section-container">
        {width > 680 ? (
          <img
            src={`${window.location.origin}/imgs/stadium/${team}-stadium.png`}
            className="section-container_img"
            alt="Background"
          />
        ) : (
          <MobileMapModal
            toggleMap={toggleMap}
            setToggleMap={setToggleMap}
            imageA={`${window.location.origin}/imgs/stadium/${team}-stadium.png`}
          />
        )}
        <div className="Tickets-container_leftBox">
          <LeftBox
            siteTitle={`2. ${t('TicketNavigator.TitleFields')}`}
            chosenField={undefined}
            chosenRow={undefined}
            teamParam={team}
            matchIdParam={matchId}
            bottomSectionClassCategory="section"
            component={
              !_fieldLoading ? (
                _fieldTypes
                  ?.filter((fieldType: IFieldType) => fieldType.fields
                    ?.some((field) => field.buyable_websale))
                  .map((fieldType: IFieldType) => (
                    <React.Fragment key={fieldType.id}>
                      <FieldType
                        fieldType={fieldType}
                        updateIsOpen={updateIsOpen}
                        isDark={isDark}
                      />

                      <div
                        className={
                        fieldType.isOpen ? 'dropDown isOpen' : 'dropDown'
                      }
                      >
                        {fieldType.fields
                          ?.filter((field: IField) => field.buyable_websale)
                          ?.map((field: IField) => (
                            <Dropdown
                              field={field}
                              fieldType={fieldType}
                              key={field.id}
                            />
                          ))}
                      </div>
                    </React.Fragment>
                  ))
              ) : (
                <Loading />
              )
            }
          />
        </div>

        <div className="section-container_bottomBox">
          <TicketOrder
            matchLoading={matchLoading}
            homeTeam={match?.home_team.name}
            awayTeam={match?.away_team.name}
            date={match?.match_starts_at}
            routeTo="/rows"
            btnDisabledText={t('TicketOrder.BtnDisabledFields')}
            setToggleMap={setToggleMap}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default Fields;
