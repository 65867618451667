import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ITeam } from '../Interfaces';
import '../SASS/base/_Home.scss';

interface ITeamProps {
  teams: ITeam[];
}

const Teams = ({ teams }: ITeamProps) => {
  const { t } = useTranslation();

  return (
    <div className="Home-container_teamWrap">
      {teams && (
        teams.filter((team) => team.visible === 1).map((team: ITeam) => (
          <Link
            onClick={() => window.scrollTo(0, 0)}
            key={`team - ${team.id}`}
            to={`/${team.shortname.toLowerCase()}`}
            type="button"
            aria-label="button"
            className="Home-container_teamWrap--teamLink"
          >
            <div className="Home-container_teamWrap--team">
              <div className="Home-container_teamWrap--team-leftBox" style={{ backgroundColor: team.color }}>
                <img className="Home-container_teamWrap--team-leftBox_image" src={team.image} alt={team.name} />
              </div>
              <h1 className="Home-container_teamWrap--team-title">{team.name}</h1>
              <p className="Home-container_teamWrap--team-buyTicket">
                {`${t('TeamCard.BuyTicket')} ${team.name} ${t('TeamCard.HomeGame')}`}
              </p>
              <p className="Home-container_teamWrap--team-toBuy">
                {`${t('TeamCard.ToBuy')} ${team.name}`}
              </p>
              <img src="/imgs/right-arrow.svg" alt="right arrow" className="Home-container_teamWrap--team-rightArrow" />
            </div>
          </Link>
        ))
      )}
    </div>
  );
};

export default Teams;
