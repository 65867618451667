import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import api from '../api/api';
import { stripeSuccessRetry } from '../atom';
import LeftBox from '../components/LeftBox';
import Loading from '../components/Loading';
import ReceiptSummary from '../components/Receipt/ReceiptSummary';
import useTransactionId from '../hooks/useTransactionId';
import { IReceipt } from '../Interfaces';
import '../SASS/base/ReceiptPage/_ReceiptPage.scss';

const ReceiptPage = () => {
  const { team, matchId } = useParams();
  const location = useLocation();
  const transactionId = useTransactionId(location);

  const [receipt, setReceipt] = useState<IReceipt | undefined>(undefined);
  const [receiptLoading, setReceiptLoading] = useState<boolean>(true);

  const stripeSuccessRetryPayment = useRecoilValue(stripeSuccessRetry);

  const navigate = useNavigate();

  useEffect(() => {
    const wrapper = async () => {
      if (team && transactionId) {
        const fetchReceipt = async (runCount = 0) => {
          const maxRunCount = 4;
          setReceiptLoading(true);
          try {
            const receiptRes: IReceipt = await api().payments.getReceipt(
              team,
              transactionId,
            );
              // if (runCount >= 1 &&
              //  receiptRes?.tickets[0].ticket_state.description === 'Ikke betalt') {
              //   navigate(`/${team}/${matchId}/unpaid?${transactionId}`, { replace: true });
              // }

            if (
              runCount <= maxRunCount
              && receiptRes?.pay_date === '0000-00-00 00:00:00'
            ) {
              setTimeout(() => {
                fetchReceipt(runCount + 1);

                return <Loading />;
              }, 1000);
              return;
            }

            setReceipt(receiptRes);
            setReceiptLoading(false);
          } catch (err) {
            toast.error(String(err));
          }
        };
        fetchReceipt();
      }
    };

    wrapper();

    return () => {
      setReceipt(undefined);
    };
  }, []);
  return (
    <div className="receipt">
      <div className="receipt-container">
        <div className="receipt-container_imgOverlay">
          <img
            src={`${process.env.PUBLIC_URL}/imgs/never-offside.jpg`}
            className="receipt-container_imgOverlay--img"
            alt="background"
          />
        </div>

        <div className="Tickets-container_leftBox">
          <LeftBox
            siteTitle=""
            chosenField={undefined}
            chosenRow={undefined}
            teamParam={team}
            matchIdParam={matchId}
            bottomSectionClassCategory="receipt"
            component={(
              <ReceiptSummary
                team={team}
                transactionId={transactionId}
                receipt={receipt}
                receiptLoading={receiptLoading}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ReceiptPage;
