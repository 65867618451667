import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import api from '../api/api';
import { UserTeams } from '../atom';
import { auth } from '../firebase';
import '../SASS/base/AcceptTeamRegistration.scss';
import Sleep from '../utils/Sleep';
import { fetchCustomClaims } from '../utils/utils';
import Loading from './Loading';

interface IAcceptTeamRegistrationProps {
  team: string | undefined;
  fullTeamName: string;
}

const AcceptTeamRegistration = ({
  team, fullTeamName,
}: IAcceptTeamRegistrationProps) => {
  const setUserTeams = useSetRecoilState<any[]>(UserTeams);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const assignUserToTeamFunc = async () => {
    if (auth?.currentUser) {
      try {
        setLoading(true);
        const success = await api().users.assignUserToTeam();
        await Sleep(3000);
        const userTeams = await fetchCustomClaims();
        setUserTeams(userTeams);
        if (success) {
          toast.success(t('LogIn.YouHaveBeenLinked', { teamName: fullTeamName }));
        }
        setLoading(false);
      } catch (err) {
        toast.error(String(err));
        setLoading(false);
      }
    }
  };

  const navigate = useNavigate();
  return (
    <div className="AcceptTeamRegistration">
      <div className="AcceptTeamRegistration-box">
        <h1>{t('AcceptTeamRegistration.NotRegistered')}</h1>
        <h2>
          {t('AcceptTeamRegistration.RegisterInfo')}
        </h2>
        <div className="buttons">
          {loading ? <Loading />
            : (
              <>
                <button
                  type="button"
                  className="buttonDisabled"
                  style={{ opacity: 1, cursor: 'pointer', pointerEvents: 'all' }}
                  onClick={() => {
                    navigate('/', { replace: true });
                  }}
                >
                  {t('AcceptTeamRegistration.RegisterCancel')}
                </button>
                <button
                  type="button"
                  className="buttonPrimary"
                  onClick={() => {
                    assignUserToTeamFunc();
                  }}
                >
                  {t('AcceptTeamRegistration.RegisterAccept')}
                </button>
              </>
            )}
        </div>
      </div>

    </div>
  );
};

export default AcceptTeamRegistration;
