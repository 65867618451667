/* eslint-disable react/jsx-no-useless-fragment */
import '../SASS/base/RowPage/_Rows.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import api from '../api/api';
import {
  ticketOrderLoading,
  RowsState,
  rowLoading,
  chosenFieldState,
  matchState,
  chosenFieldIdState,
  fieldImage,
  chosenTicketState,
  chosenRowState,
  chosenSeatState,
} from '../atom';
import RowCard from '../components/Rows/RowCard';
import TicketOrder from '../components/Tickets/TicketOrder';
import {
  IMatch, IRow, IField,
} from '../Interfaces';
import LeftBox from '../components/LeftBox';
import useWindowSize from '../hooks/useWindowSize';
import MobileMapModal from '../components/Mobile/MobileMapModal';
import useAuth from '../hooks/useAuth';
import Loading from '../components/Loading';

const Rows = () => {
  const { team, matchId } = useParams<string>();
  const [match] = useRecoilState<IMatch | undefined>(matchState);
  const [matchLoading] = useRecoilState<boolean>(ticketOrderLoading);
  const [chosenField] = useRecoilState<IField | undefined>(chosenFieldState);
  const [rows, setRows] = useRecoilState<IRow[]>(RowsState);
  const [_rowLoading, setRowLoading] = useRecoilState<boolean>(rowLoading);
  const [_fieldImage] = useRecoilState(fieldImage);
  const [chosenFieldId] = useRecoilState<number>(chosenFieldIdState);
  const [chosenPriceGroup] = useRecoilValue(chosenTicketState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!chosenPriceGroup || !chosenField) {
      return navigate(`/${team}/${matchId}/tickets`, { replace: true });
    }

    return undefined;
  }, []);

  const [toggleMap, setToggleMap] = useState<boolean>(false);

  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [, setRowValue] = useRecoilState(chosenRowState);
  const [, setSeatValue] = useRecoilState(chosenSeatState);

  useEffect(() => {
    setRowValue(undefined);
    setSeatValue([]);
    if (team && matchId) {
      const fetchRows = async () => {
        if (!chosenField?.id) {
          return;
        }
        try {
          setRowLoading(true);
          const rowRes = await api().matches.getRows(
            team,
            matchId,
            chosenFieldId,
          );
          setRows(rowRes);
        } catch (err) {
          if (err instanceof Error) {
            toast.error(err.message);
          }
        } finally {
          setRowLoading(false);
        }
      };
      fetchRows();
    }
  }, [chosenField, team, matchId]);

  return (
    <div className="rows">
      <div className="rows-container">
        {chosenField?.overview_image !== null ? (
          <>
            {width > 680 ? (
              <img
                src={chosenField?.overview_image.url}
                alt="Overview of current row"
                className="rows-container_img"
              />
            ) : (
              <MobileMapModal
                toggleMap={toggleMap}
                setToggleMap={setToggleMap}
                imageA={chosenField?.overview_image.url}
                imageB={_fieldImage}
              />
            )}
          </>
        ) : (
          <img
            src={_fieldImage}
            alt="Overview of current row"
            className="rows-container_img"
          />
        )}

        <div className="Tickets-container_leftBox">
          <LeftBox
            siteTitle={`3. ${t('TicketNavigator.TitleRows')}`}
            chosenField={`Felt ${chosenField?.name}`}
            chosenRow={undefined}
            teamParam={team}
            matchIdParam={matchId}
            bottomSectionClassCategory="rows"
            component={
              rows
              && (!_rowLoading ? (
                rows.map((row: IRow) => <RowCard key={row.id} wholeRow={row} />)
              ) : (
                <Loading />
              ))
            }
          />
        </div>

        <div className="rows-container_bottomBox">
          <TicketOrder
            matchLoading={matchLoading}
            homeTeam={match?.home_team.name}
            awayTeam={match?.away_team.name}
            date={match?.match_starts_at}
            routeTo="/rows"
            btnDisabledText={t('TicketOrder.BtnDisabledRows')}
            disabled
            setToggleMap={setToggleMap}
          />
        </div>
      </div>
    </div>
  );
};

export default Rows;
