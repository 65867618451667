import React from 'react';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from '../i18next';

const useLocale = () => {
  let locale = i18n.use(LanguageDetector).language;

  if (locale === 'enUS') {
    locale = 'en';
  } else if (locale === 'nb') {
    locale = 'no';
  }

  return { locale };
};

export default useLocale;
