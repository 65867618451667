import {
  IDiscount,
  IFee,
  IPriceGroup,
} from '../Interfaces';

const calculatePrice = (
  chosenTickets: IPriceGroup[],
  fieldTypePrice: number,
  fees: IFee[],
  discount?: IDiscount,
  showQuantityDiscount?: boolean,
  discountPackagePrice?: number,
) => {
  let price: number = 0;

  chosenTickets.forEach((priceGroup: IPriceGroup) => {
    price
      += Number(priceGroup.percentage)
      * priceGroup.amount
      * 0.01
      * Number(fieldTypePrice);
  });

  if (discount?.active) {
    if (discount.percentage === 0) {
      price -= discount.discount;

      if (showQuantityDiscount && discountPackagePrice) {
        price -= discountPackagePrice;
      }
    } else {
      price = Number((price -= price * (discount.discount * 0.01)).toFixed(0));

      if (showQuantityDiscount && discountPackagePrice) {
        price -= discountPackagePrice;
      }
    }
  }

  if (showQuantityDiscount && discountPackagePrice) {
    price -= discountPackagePrice || 0;
  }

  fees.forEach((fee: IFee) => {
    if (fee.active_tickets === 1) {
      price = (Number((price *= fee.percentage * 0.01 + 1).toFixed(0)));
    }
  });

  if (price < 0) {
    price = 0;
  }

  return Number(price.toFixed(0));
};

export default calculatePrice;
