import '../../SASS/base/PriceGroupPage/_Tickets.scss';
import { useTranslation } from 'react-i18next';
import { IRecoilHoc } from '../../Interfaces';
import RecoilHoc from '../RecoilHoc';
import { convertCurrency } from '../../utils/utils';

interface ITicketChooserProps extends IRecoilHoc {
  title: string;
  minPrice: number;
  description: string | undefined;
  setAmount: (priceGroupId: number, value: number) => void;
  id: number;
  amount: number;
  ticketsDisabledMaxAmount: boolean
}
const TicketChooser = ({
  id,
  title,
  minPrice,
  description,
  setAmount,
  amount,
  ticketsDisabledMaxAmount,
  currentTeamInfo: { currency },
}: ITicketChooserProps) => {
  const { t } = useTranslation();

  return (
    <div className="TicketChooser">
      <div className="TicketChooser-info">
        <h1 className="TicketChooser-info--title">{title}</h1>
        <p className="TicketChooser-info--price">
          {t('TicketChooser.PriceFrom', { currency: convertCurrency(currency, 'NONE') })}
          {' '}
          {minPrice}
          ,-
        </p>
        {description === undefined ? (
          ''
        ) : (
          <p className="TicketChooser-info--requirements">{description}</p>
        )}
      </div>
      <div className="TicketChooser-btns">
        {amount >= 1 && (
          <>
            <button
              type="button"
              className="TicketChooser-btns--btn"
              onClick={() => setAmount(id, -1)}
            >
              <img
                className="TicketChooser-btns--btn_minus"
                src={`${process.env.PUBLIC_URL}../../imgs/minus-btn.svg`}
                alt="minus icon"
                draggable={false}
              />
            </button>
            <h1 className="TicketChooser-btns--quantity">{amount}</h1>
          </>
        )}
        <button
          type="button"
          className="TicketChooser-btns--btn"
          style={ticketsDisabledMaxAmount ? { pointerEvents: 'none', opacity: 0.5, cursor: 'not-allowed' } : { pointerEvents: 'all' }}
          onClick={() => setAmount(id, 1)}
          disabled={ticketsDisabledMaxAmount}
        >
          <img
            className="TicketChooser-btns--btn_plus"
            src={`${process.env.PUBLIC_URL}../../imgs/plus-btn.svg`}
            alt="plus icon"
            draggable={false}
          />
        </button>
      </div>
    </div>
  );
};

export default RecoilHoc(TicketChooser);
