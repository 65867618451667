import {
  useState, useEffect, useCallback, useRef,
} from 'react';
import {
  Route, Routes, useNavigate, useLocation, Location,
} from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  signInWithCustomToken,
  User,
} from 'firebase/auth';
import TeamsPage from './pages/TeamsPage';
import HomePage from './pages/HomePage';
import './SASS/main.scss';
import LogInPage from './pages/LogInPage';
import ProtectedRoute from './components/ProtectedRoute';
import PriceGroupPage from './pages/PriceGroupPage';
import FieldsPage from './pages/FieldsPage';
import api from './api/api';
import { IAuth, ITeam } from './Interfaces';
import RowsPage from './pages/RowsPage';
import SeatsPage from './pages/SeatsPage';
import PaymentPage from './pages/PaymentPage';
import ReceiptPage from './pages/ReceiptPage';
import PurchaseHistory from './pages/PurchaseHistory';
import SaleTerms from './pages/SaleTermsPage';
import RegisterPage from './pages/RegisterPage';
import Layout from './Layout';
import PayUnpaidTicket from './pages/PayUnpaidTicket';
import { auth } from './firebase';
import { AuthState, UserTeams } from './atom';
import ClubTerms from './pages/ClubTermsPage';
import { fetchCustomClaims } from './utils/utils';
import RestorePasswordPage from './pages/RestorePasswordPage';
import Loading from './components/Loading';
import CheckingPayment from './pages/CheckingPayment';

const App = () => {
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [loading, setLoading] = useState(true);
  const setUserTeams = useSetRecoilState<any[]>(UserTeams);
  const [authState, setAuthState] = useRecoilState<IAuth>(AuthState);
  const location: Location = useLocation();
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        setLoading(true);
        const teamItems = await api().teams.getTeams();
        setTeams(teamItems);
      } catch (err) {
        if (err instanceof Error) {
          toast.error(err.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();
  }, []);
  const onAuthStateChanged = useCallback(async (user: User|null) => {
    if (user === null) {
      setLoading(false);
      setAuthState({
        isLoggedIn: false,
      });
      return;
    }
    const userTeams = await fetchCustomClaims();

    setUserTeams(userTeams);

    const hasFakeEmail = user?.email?.endsWith('fangroup.io') || !user?.email;
    if (hasFakeEmail) {
      setAuthState({
        isLoggedIn: 'newEmail',
      });
      return;
    }
    setAuthState({
      isLoggedIn: true,
    });
    setLoading(false);
  }, [location]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(onAuthStateChanged);
    return unsubscribe;
  }, []);
  if (authState.isLoggedIn === 'loading') {
    return (
      <div style={{
        margin: 'auto', width: '100%', height: '100%', position: 'absolute',
      }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<Layout teams={teams} />}>
        <Route
          index
          element={(
            <HomePage teams={teams} loading={loading} />
            )}
        />

        <Route
          path="/:team/"
          element={(
            <TeamsPage />
          )}
        />

        <Route
          path="/:team/:matchId/tickets"
          element={(
            <ProtectedRoute
              path="/:team/:matchId/tickets"
              component={PriceGroupPage}
            />
        )}
        />

        <Route
          path="/:team/:matchId/fields"
          element={(
            <ProtectedRoute
              path="/:team/:matchId/fields"
              component={FieldsPage}
            />
        )}
        />

        <Route
          path="/:team/:matchId/rows"
          element={(
            <ProtectedRoute
              path="/:team/:matchId/rows"
              component={RowsPage}
            />
)}
        />

        <Route
          path="/:team/:matchId/seats"
          element={(
            <ProtectedRoute
              path="/:team/:matchId/seats"
              component={SeatsPage}
            />
        )}
        />
        <Route
          path="/:team/:matchId/payment"
          element={(
            <ProtectedRoute
              path="/:team/:matchId/payment"
              component={PaymentPage}
            />
        )}
        />
        <Route
          path="/:team/:matchId/checkingPayment"
          element={(
            <ProtectedRoute
              path="/:team/:matchId/checkingPayment"
              component={CheckingPayment}
            />
        )}
        />
        <Route
          path="/:team/:matchId/transaction"
          element={(
            <ProtectedRoute
              path="/:team/:matchId/transaction"
              component={ReceiptPage}
            />
        )}
        />
        <Route
          path="/:team/:matchId/unpaid"
          element={(
            <ProtectedRoute
              path="/:team/:matchId/unpaid"
              component={PayUnpaidTicket}
            />
        )}
        />
        <Route
          path="/:team/purchaseHistory"
          element={(
            <ProtectedRoute
              path="/:team/purchaseHistory"
              component={PurchaseHistory}
            />
        )}
        />

        <Route
          path="/:team/login"
          element={(
            <LogInPage />
            )}
        />

        <Route
          path="/:team/register"
          element={(
            <RegisterPage />
            )}
        />

        <Route path="/:team/terms_of_sale" element={<SaleTerms />} />

        <Route path="/:team/terms" element={<ClubTerms />} />
      </Route>
      <Route
        path="/restore_password/:identity/:token"
        element={(
          <RestorePasswordPage />
            )}
      />
    </Routes>
  );
};

export default App;
