/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  useNavigate, useLocation, Link, useParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { nb, enUS, sv } from 'date-fns/locale';
import i18n from 'i18next';
import { RiArrowRightSLine } from 'react-icons/ri';
import LanguageDetector from 'i18next-browser-languagedetector';
import { TbTicket } from 'react-icons/tb';
import { MdArrowBackIosNew, MdClose, MdLogout } from 'react-icons/md';
import { useSetRecoilState } from 'recoil';
import useWindowSize from '../hooks/useWindowSize';
import '../SASS/base/_Nav.scss';
import useAuth from '../hooks/useAuth';
import { IClubConfig, IMatch, ITeam } from '../Interfaces';
import api from '../api/api';
import Loading from './Loading';
import { auth } from '../firebase';
import { CurrentTeamInfo } from '../atom';

interface INavProps {
  teams: ITeam[];
}

const Nav = ({ teams }: INavProps) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { team, matchId } = useParams<string>();
  const { signOut } = useAuth(team);

  const [match, setMatch] = useState<IMatch | undefined>(undefined);
  const [matchFetchLoading, setMatchFetchLoading] = useState<boolean>(true);
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);

  const seePurchases = () => {
    navigate(`/${team}/purchaseHistory`);
  };

  const [teamInfo, setTeamInfo] = useState<ITeam | undefined>(undefined);
  const setCurrentTeamInfo = useSetRecoilState(CurrentTeamInfo);
  useEffect(() => {
    const fetchTeamInfo = async () => {
      const currentTeam = teams.find((_team) => _team.shortname === team);
      setTeamInfo(currentTeam);
      if (currentTeam) {
        const res = await api().teams.getClubConfig(currentTeam?.shortname as string) || { currency: 'NOK' } as IClubConfig;
        setCurrentTeamInfo(res);
      }
    };
    fetchTeamInfo();
  }, [teams, team]);

  useEffect(() => {
    const fetchSingleMatch = async () => {
      if (team) {
        try {
          setMatchFetchLoading(true);
          const matchesRes = await api().matches.getMatches(team);
          const currentMatch = matchesRes.find((_match: IMatch) => _match.id === Number(matchId));

          setMatch(currentMatch);

          return currentMatch;
        } catch (err) {
          toast.error(String(err));
        } finally {
          setMatchFetchLoading(false);
        }
      }

      return undefined;
    };

    fetchSingleMatch();
  }, [location.pathname === `/${team}/${matchId}/${'tickets' || 'fields' || 'rows' || 'seats' || 'payment'}`, team, matchId]);

  const locales: any = { nb, enUS, sv };

  const startsAt = match?.match_starts_at
    && format(parseISO(match?.match_starts_at), 'E dd. MMMM HH:mm', {
      locale: locales[i18n.use(LanguageDetector).language],
    });

  const teamInfoNameBool = (location.pathname === `/${team}/terms`
    || location.pathname === `/${team}/terms_of_sale`
     || location.pathname === `/${team}`
     || location.pathname === `/${team}/purchaseHistory`
     || location.pathname === `/${team}/login`
     || location.pathname === `/${team}/register`
     || location.pathname === `/${team}/${matchId}/transaction`
     || location.pathname === `/${team}/${matchId}/payment`
     || location.pathname === `/${team}/${matchId}/unpaid`
     || location.pathname === `/${team}/${matchId}/tickets`
     || location.pathname === `/${team}/${matchId}/fields`
     || location.pathname === `/${team}/${matchId}/rows`
     || location.pathname === `/${team}/${matchId}/seats`
     || location.pathname === `/${team}/${matchId}/checkingPayment`) && width >= 1300;

  const mobileMatchInfoBool = location.pathname !== `/${team}/terms`
     && location.pathname !== `/${team}/terms_of_sale` && location.pathname !== `/${team}`
     && location.pathname !== `/${team}/purchaseHistory`
      && location.pathname !== `/${team}/login`
     && location.pathname !== `/${team}/register`;

  return (
    <>
      {showUserMenu
      && <div className="overlayNav" onClick={() => setShowUserMenu(false)} />}
      <nav className="Nav">
        <div
          className="Nav_container"
          style={location.pathname !== '/' ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}
        >
          {location.pathname !== '/' && (
          <>
            <div className="left">
              {location.pathname !== `/${team}` && (
                <button
                  className="arrowBackButton nostyle"
                  type="button"
                  onClick={() => {
                    navigate(-1);

                    setShowUserMenu(false);
                  }}
                >
                  <MdArrowBackIosNew fill="#fff" height={25} width={25} />
                </button>
              )}

              {matchFetchLoading ? (
                <Loading size={40} color="#fff" />
              ) : (
                (location.pathname !== `/${team}/terms` || location.pathname !== `/${team}/terms_of_sale` || location.pathname !== `/${team}`) && (
                  <button type="button" className="Nav_container-teamInfo">
                    <span
                      className="Nav_container-teamInfo"
                      onClick={() => {
                        navigate(`/${team}`);
                        setShowUserMenu(false);
                      }}
                    >
                      <img
                        src={teamInfo?.image}
                        alt={teamInfo?.name}
                        height={25}
                        width={25}
                      />
                      {teamInfoNameBool && (
                        <h2>{teamInfo?.name}</h2>
                      )}
                    </span>

                    {width <= 1300 && mobileMatchInfoBool && (
                    <div className="Nav_container-teamInfo_matchInfo">

                      <h4>
                        {`${match?.home_team.name} - ${match?.away_team.name}`}
                      </h4>
                      <p style={{ textTransform: 'capitalize' }}>{startsAt}</p>
                    </div>
                    )}

                  </button>
                )
              )}
            </div>

            {auth?.currentUser ? (
              <div
                className="Nav_container-nameBtnWrap"
                onMouseEnter={() => setShowUserMenu(true)}
                onClick={() => setShowUserMenu(!showUserMenu)}
              >
                <button type="button" className="Nav_container-nameBtnWrap_btn nostyle">
                  <p className="Nav_container-login" />
                  <FaUser className="Nav_container-nameBtnWrap_btn--logo" />
                  {width < 415 ? (
                    ''
                  ) : (
                    <p className="Nav_container-nameBtnWrap_btn--name">
                      {auth.currentUser.email}
                    </p>
                  )}

                </button>

              </div>
            ) : !['login', 'register'].some((path) => location.pathname.includes(path)) && (
              <Link to={`/${team}/login`} className="Nav_container-nameBtnWrap" state={{ from: location }}>
                <button type="button" className="Nav_container-nameBtnWrap_btn">
                  <p className="Nav_container-nameBtnWrap--name">
                    {t('Shared.LogIn')}
                  </p>
                </button>
              </Link>
            )}
          </>

          )}

        </div>
      </nav>

      {showUserMenu && auth?.currentUser && (
      <div className="userContainer">
        {width <= 768 && (
        <div className="userContainer-header">

          <button type="button" onClick={() => setShowUserMenu(false)}>
            <MdClose />
          </button>
        </div>
        )}

        <div className="userContainer-options">
          <button
            type="button"
            className="userContainer-options_btn"
            onClick={() => {
              seePurchases();
              setShowUserMenu(false);
            }}
          >
            <span className="info">
              <TbTicket />
              {t('Nav.SeePurchases')}
            </span>
            <span className="rightArrow">
              <RiArrowRightSLine />
            </span>
          </button>
          <button
            type="button"
            className="userContainer-options_btn"
            onClick={() => {
              navigate(`/${team}`);
              signOut();
              setShowUserMenu(false);
            }}
          >
            <span className="info">
              <MdLogout />
              {t('Nav.SignOut')}
            </span>
            <span className="rightArrow">
              <RiArrowRightSLine />
            </span>
          </button>
        </div>
      </div>
      )}
    </>

  );
};

export default Nav;
