/* eslint-disable react/jsx-no-useless-fragment */
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import Loading from '../components/Loading';
import Nav from '../components/Nav';
import Teams from '../components/Teams';
import { ITeam } from '../Interfaces';
import '../SASS/base/_Home.scss';

interface IHomeProp {
  teams: ITeam[];
  loading: boolean;
}

const Home = ({ teams, loading }: IHomeProp) => {
  const { t } = useTranslation();

  return (
    <div className="Home">
      <div className="Home-container">
        {teams ? (
          <>
            {!loading ? (
              <>
                <h1 className="Home-container_title">{t('Home.Title')}</h1>
                <Teams teams={teams} />
              </>
            ) : (
              <>
                <h1 className="Home-container_title">{t('Shared.Loading')}</h1>

                <Loading />
              </>
            )}
          </>
        ) : (
          <h1 className="Home-container_title">{t('Shared.NoContent')}</h1>
        )}
      </div>
    </div>
  );
};

export default Home;
