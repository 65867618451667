import { useEffect } from 'react';
import {
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AuthState } from '../atom';
import { auth } from '../firebase';
import NewCredentialScreen from '../pages/NewCredentialScreen';
import Loading from './Loading';

interface IProtectedRoute {
  component: any;
  path: string;
}

const ProtectedRoute = ({
  component: Component,
}: IProtectedRoute) => {
  const { team } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useRecoilValue(AuthState);
  useEffect(() => {
    if (!auth?.currentUser && team && !user.isLoggedIn) {
      navigate(`/${team}/login`, { replace: true, state: { from: location } });
    }
  }, [navigate]);

  if (user.isLoggedIn === 'loading') {
    return (
      <div style={{
        margin: 'auto', width: '100%', height: '100%', position: 'absolute',
      }}
      >
        <Loading />
      </div>
    );
  }

  if (user.isLoggedIn === 'newEmail') {
    return (
      <NewCredentialScreen />
    );
  }

  if (user.isLoggedIn !== true) {
    return <Navigate to={`/${team}/login`} state={{ from: location }} />;
  }

  return <Component />;
};

export default ProtectedRoute;
