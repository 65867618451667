import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import { toggleCart } from '../../atom';
import useWindowSize from '../../hooks/useWindowSize';
import RecoilHoc from '../RecoilHoc';
import '../../SASS/base/_MobileButtons.scss';
import { IRecoilHoc } from '../../Interfaces';
import { convertCurrency } from '../../utils/utils';

interface IMobileButtonsProps extends IRecoilHoc {
  price: number | undefined;
  setToggleMap?: (v: any) => void;
}

const MobileButtons = ({ price, setToggleMap, currentTeamInfo: { currency } }:
  IMobileButtonsProps) => {
  const { team, matchId } = useParams<string>();
  const { width } = useWindowSize();
  const location = useLocation();
  const [_toggleCart, setToggleCart] = useRecoilState(toggleCart);
  const { t } = useTranslation();

  return width <= 700 ? (
    <>
      <button
        type="button"
        className="TicketOrderMobile-cart"
        onClick={() => setToggleCart(true)}
      >
        <img
          className="TicketOrderMobile-cart_icon"
          src={`${process.env.PUBLIC_URL}/imgs/shopping-cart-icon.svg`}
          alt="shopping cart icon"
        />
        <h3 className="TicketOrderMobile-cart_price">
          {price ? `${convertCurrency(currency)} ${price?.toFixed?.(0)},-` : '0,-'}
        </h3>
      </button>
      {location.pathname !== `/${team}/${matchId}/tickets`
      && location.pathname !== `/${team}/${matchId}/payment` && (
        <button
          type="button"
          className="TicketOrderMobile-map"
          onClick={() => setToggleMap?.((v: any) => !v)}
        >
          <img
            className="TicketOrderMobile-map_icon"
            src={`${process.env.PUBLIC_URL}/imgs/map-icon.svg`}
            alt="map icon"
          />
          <h3 className="TicketOrderMobile-map_text">{t('Mobile.MobileMapModalButton')}</h3>
        </button>
      )}
    </>
  ) : (
    <div className="btnWrap">
      <button
        type="button"
        className="TicketOrderMobile-cart"
        onClick={() => setToggleCart(true)}
      >
        <img
          className="TicketOrderMobile-cart_icon"
          src={`${process.env.PUBLIC_URL}/imgs/shopping-cart-icon.svg`}
          alt="shopping cart icon"
        />
        <h3 className="TicketOrderMobile-cart_price">
          {price ? `${convertCurrency(currency)} ${price?.toFixed?.(0)},-` : '0,-'}
        </h3>
      </button>
      {/* {location.pathname !== `/${team}/${matchId}/tickets`
      && location.pathname !== `/${team}/${matchId}/payment` && (
        <button
          type="button"
          className="TicketOrderMobile-map"
          onClick={() => {
            setToggleMap?.(true);
          }}
        >
          <img
            className="TicketOrderMobile-map_icon"
            src={`${process.env.PUBLIC_URL}/imgs/map-icon.svg`}
            alt="map icon"
          />
          <h3 className="TicketOrderMobile-map_text">{t('Mobile.MobileMapModalButton')}</h3>
        </button>
      )} */}
    </div>
  );
};

MobileButtons.defaultProps = {
  setToggleMap: false,
};

export default RecoilHoc(MobileButtons);
