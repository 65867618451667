import {
  useLocation, useNavigate, useParams,
  Link,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';

interface ITicketNavigator {
  teamParam: string | undefined;
  matchIdParam: string | undefined;
  siteTitle: string;
  chosenField: string | undefined;
  chosenRow: string | undefined;
}

const TicketNavigator = ({
  siteTitle,
  teamParam,
  matchIdParam,
  chosenField,
  chosenRow,
}: ITicketNavigator) => {
  const { team, matchId } = useParams<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const pages = [
    {
      name: 'Crumb1',
      path: `/${teamParam}/${matchIdParam}/tickets`,
      visited: true,
    },
    {
      name: 'Crumb2',
      path: `/${teamParam}/${matchIdParam}/fields`,
      visited: false,
    },
    {
      name: 'Crumb3',
      path: `/${teamParam}/${matchIdParam}/rows`,
      visited: false,
    },
    {
      name: 'Crumb4',
      path: `/${teamParam}/${matchIdParam}/seats`,
      visited: false,
    },
    {
      name: 'Crumb5',
      path: `/${teamParam}/${matchIdParam}/payment`,
      visited: false,
    },
  ];

  return (
    <div className="Navigator">
      <div className="Navigator-top">
        <button
          type="button"
          onClick={() => navigate(`/${team}`)}
          className="Navigator-top--cancel"
        >
          &#10005;
          {' '}
          {t('TicketNavigator.Cancel')}
        </button>
        <h1 className="Navigator-top--title">{siteTitle}</h1>
      </div>
      <div className="Navigator-links">
        <div className="Navigator-links--crumbWrap">
          {pages.map((page, i, arr) => {
            const currentPageIndex = arr.findIndex(
              (el) => el.path === location.pathname,
            );

            const currentItem = arr[currentPageIndex];
            const previousItems = arr.slice(0, currentPageIndex);

            currentItem.visited = true;
            previousItems.forEach((item) => {
              // eslint-disable-next-line no-param-reassign
              item.visited = true;

              return item.visited;
            });

            return (
              <Link
                to={`${page.path}`}
                className={`Navigator-links--crumbWrap_crumbs ${
                  page.visited ? 'visited' : ''
                }`}
                key={page.name}
                style={
                  page.visited
                    ? { color: '#000', pointerEvents: 'all' }
                    : { pointerEvents: 'none' }
                }
              >
                {t(`TicketNavigator.${page.name}`)}
                {' '}
                &#187;
              </Link>
            );
          })}
        </div>
      </div>
      {location.pathname === `/${team}/${matchId}/payment`
        ? (
          ''
        ) : (
          <div className="Navigator-overview">
            <button
              type="button"
              className="Navigator-overview--backarrow"
              onClick={() => {
                if (location.pathname === `/${team}/${matchId}/tickets`) {
                  navigate(`/${team}`);
                } else if (location.pathname === `/${team}/${matchId}/fields`) {
                  navigate(`/${team}/${matchId}/tickets`, { replace: true });
                } else if (location.pathname === `/${team}/${matchId}/rows`) {
                  navigate(`/${team}/${matchId}/fields`);
                } else if (location.pathname === `/${team}/${matchId}/seats`) {
                  navigate(`/${team}/${matchId}/rows`);
                }
              }}
            >
              <MdOutlineArrowBackIosNew />
            </button>

            <div className="Navigator-overview_chosenWrap">
              {chosenField === undefined && ''}
              {chosenRow === undefined && (
              <h3 className="Navigator-overview_chosenWrap--chosenField">
                {chosenField}
              </h3>
              )}
              {chosenRow && chosenField !== undefined && (
              <>
                <h3 className="Navigator-overview_chosenWrap--chosenField">
                  {chosenField}
                </h3>
                <h1>/</h1>
                <h3 className="Navigator-overview_chosenWrap--chosenRow">
                  {chosenRow}
                </h3>
              </>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default TicketNavigator;
