import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import '../SASS/base/_Loader.scss';

interface IOverlayLoadingProps {
  size?: number;
  color?: string;
  loading?:boolean
}

const OverlayLoading = ({ size, color, loading }: IOverlayLoadingProps) => (
  <div>
    { loading
      ? (
        <div
          className="loading-full"
        >
          <TailSpin color={color} height={size} width={size} />
        </div>
      )
      : ''}
  </div>

);

OverlayLoading.defaultProps = {
  size: 100,
  color: '#0476D9',
  loading: false,
};

export default OverlayLoading;
