import { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import { BsInfoLg } from 'react-icons/bs';
import { animated, useTransition } from 'react-spring';

import '../../SASS/base/_MobileMapModal.scss';
import { useTranslation } from 'react-i18next';
import { chosenFieldState, chosenRowState } from '../../atom';

interface IMobileMapModalProps {
  setToggleMap?: (v: any) => void;
  imageA: string | undefined;
  imageB?: string | undefined;
  toggleMap: boolean;
}

const MobileMapModal = ({
  setToggleMap,
  imageA,
  imageB,
  toggleMap,
}: IMobileMapModalProps) => {
  const { team, matchId } = useParams<string>();
  const [toggleOverviews, setToggleOverviews] = useState<boolean>(true);

  const [_chosenField] = useRecoilState(chosenFieldState);
  const [_chosenRow] = useRecoilState(chosenRowState);

  const location = useLocation();

  const transition = useTransition(toggleMap, {
    from: { opacity: 0, y: 40 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 40 },
    delay: 100,
    reverse: toggleMap,
  });

  const { t } = useTranslation();

  return transition(
    (style, item) => item && (
      <>
        <animated.div className="overlay" style={style} onClick={() => setToggleMap?.(false)} />
        <animated.div className="mobileImgModal" style={style}>
          <div className="mobileImgModal-wrap">
            <button
              className="mobileImgModal-wrap_close"
              type="button"
              onClick={() => setToggleMap?.(false)}
            >
              <img
                className="mobileImgModal-wrap_close--icon"
                src={`${process.env.PUBLIC_URL}/imgs/cross-icon.svg`}
                alt="cross icon"
              />
            </button>
            <img
              src={`${toggleOverviews ? imageA : imageB}`}
              className="mobileImgModal-wrap_img"
              alt="Background"
            />

            {location.pathname !== `/${team}/${matchId}/fields` && (
            <button
              className="mobileImgModal-wrap_map"
              type="button"
              onClick={() => setToggleOverviews?.((v) => !v)}
            >
              <img
                className="mobileImgModal-wrap_map--icon"
                src={`${process.env.PUBLIC_URL}/imgs/map-icon.svg`}
                alt="map icon"
              />
            </button>
            )}
          </div>
          <div className="mobileImgModal-wrap_infoLabelWrap">
            {location.pathname === `/${team}/${matchId}/rows`
            || location.pathname === `/${team}/${matchId}/seats` ? (
              <button
                className="mobileImgModal-wrap_infoLabelWrap-btn"
                type="button"
                onClick={() => setToggleOverviews?.((v) => !v)}
              >
                <div className="infoLabelText">
                  <span className="infoLabelText-icon">
                    <BsInfoLg style={{ fill: '#000' }} />
                  </span>
                  {location.pathname === `/${team}/${matchId}/rows` && (
                    <p className="infoLabelText-text">
                      {toggleOverviews
                        ? t('Mobile.MobileMapModalInfo')
                        : `${t('Mobile.MobileMapModalField')} ${_chosenField?.name}`}
                    </p>
                  )}
                  {location.pathname === `/${team}/${matchId}/seats` && (
                    <p className="infoLabelText-text">
                      {toggleOverviews
                        ? t('Mobile.MobileMapModalInfo')
                        : `${t('Mobile.MobileMapModalField')} ${_chosenField?.name}, 
                        ${t('Mobile.MobileMapModalRow')} ${_chosenRow?.number}`}
                    </p>
                  )}
                </div>
              </button>
              ) : (
                ''
              )}
          </div>
        </animated.div>
      </>
    ),
  );
};

MobileMapModal.defaultProps = {
  setToggleMap: undefined,
  imageB: undefined,
};

export default MobileMapModal;
