import {
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInWithEmailAndPassword, signInWithPhoneNumber, UserCredential,
  User,
} from 'firebase/auth';
import React, { SetStateAction, useState } from 'react';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import api from '../api/api';
import { auth } from '../firebase';
import { AuthState, UserTeams } from '../atom';
import { IRegisterPayload } from '../Interfaces';
import { throwErrorToast } from '../utils/utils';

declare global {
  interface Window {
    recaptchaVerifier:any;
    confirmation:any;
  }
}

const useAuth = (team?: string) => {
  const [recoilAuth, setRecoilAuth] = useRecoilState(AuthState);
  const { t } = useTranslation();
  const signInEmail = async (email: string, password: string) => {
    try {
      const resSignIn: UserCredential = await signInWithEmailAndPassword(auth, email, password);
      if (resSignIn.user) {
        toast.success('Du er nå logget inn.');
        await resSignIn.user.reload();
      }
    } catch (err: any) {
      throwErrorToast(err);
    }
  };
  const sendNewVerifyCode = async (phoneNumber: string) => {
    try {
      const appVerifier = window.recaptchaVerifier;
      const resSendCode = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      return resSendCode;
    } catch (err) {
      throwErrorToast(err);
    }
    return null;
  };

  const passwordReset = async (email: string) => {
    try {
      const emailRes = await sendPasswordResetEmail(auth, email);
      toast.info(`${t('Shared.passwordReset')} ${email}`);
      return emailRes;
    } catch (err) {
      throwErrorToast(err);
    }

    return null;
  };

  const signOut = async () => {
    try {
      setRecoilAuth({ isLoggedIn: false });
      const signOutRes: void = await auth.signOut();
      toast.success(t('Shared.SignedOut'));
      return signOutRes;
    } catch (err: any) {
      throwErrorToast(err);
    }
    return false;
  };

  return {
    isLoggedIn: recoilAuth.isLoggedIn,
    signInEmail,
    signOut,
    sendNewVerifyCode,
    passwordReset,
  };
};

export default useAuth;
