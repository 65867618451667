/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { FaCreditCard } from 'react-icons/fa';
import '../../SASS/base/PaymentPage/_Payment.scss';
import { toast } from 'react-toastify';
import { CardElement, CardNumberElement } from '@stripe/react-stripe-js';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { MdOutlineMoneyOff } from 'react-icons/md';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import api from '../../api/api';
import {
  IPaymentOptions,
  ISeat,
  IDiscount,
  ITicketPaymentObj,
  IMatchedChosenTicketPackage,
} from '../../Interfaces';
import StripeContainer from './StripeContainer';
import {
  chosenFieldIdState,
  chosenFieldState,
  chosenSeatState,
  chosenTicketState,
  currentTeamStripeKey,
  discountState,
  FeeState,
  quantityDiscountPackageRules,
  stripeSuccessRetry,
} from '../../atom';
import Loading from '../Loading';
import calculatePrice from '../../utils/CalcPrice';
import StripeOptions from './StripeOptions';
import { auth } from '../../firebase';

interface IPayOptionsProps {
  team: string | undefined;
  matchId: string | undefined;
  transactionId: string | undefined | null;
  setReceiptLoading: (arg0: boolean) => void;
  receiptLoading: boolean | null;
  retryTicketPaymentPayload?: any;
}

interface ITicketMeta {
  transaction_id: string;
}

interface IinitiateMatchTicketPayment {
  metadata: ITicketMeta;
}

const PayOptions = ({
  team,
  matchId,
  setReceiptLoading,
  receiptLoading,
  retryTicketPaymentPayload,
}: IPayOptionsProps) => {
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState<
    string | null
  >(null);

  const location = useLocation();

  const [paymentOptions, setPaymentOptions] = useState<
    IPaymentOptions | undefined
  >(undefined);

  const [fieldId] = useRecoilState(chosenFieldIdState);
  const [_seats] = useRecoilState(chosenSeatState);
  const [tickets] = useRecoilState(chosenTicketState);
  const [discountCode] = useRecoilState<IDiscount | undefined>(discountState);
  const [, setTicketMetaData] = useState<
    IinitiateMatchTicketPayment | undefined
  >(undefined);
  const [field] = useRecoilState(chosenFieldState);
  const [fees] = useRecoilState(FeeState);
  const setStripeSuccess = useSetRecoilState<boolean>(stripeSuccessRetry);

  const matchedChosenTicketPackage = useRecoilValue(
    quantityDiscountPackageRules,
  );

  const [discountRes] = useRecoilState<IDiscount | undefined>(discountState);
  const { t } = useTranslation();
  const priceGroupPayload = tickets.map((el) => {
    const { amount, id, title } = el;

    return {
      amount,
      id,
      title,
    };
  });
  const navigate = useNavigate();

  const [cardReady, setCardReady] = useState<boolean>(false);
  const [cardNumberReady, setCardNumberReady] = useState<boolean>(false);
  const [cardExpiryReady, setCardExpiryReady] = useState<boolean>(false);
  const [cardCvcReady, setCardCvcReady] = useState<boolean>(false);

  const totalPrice = discountRes?.exists
    ? calculatePrice(
      tickets,
      Number(field?.field_type.price),
      fees,
      discountRes,
    )
    : calculatePrice(tickets, Number(field?.field_type.price), fees);

  const isFree = totalPrice === 0;

  useEffect(() => {
    if (cardNumberReady && cardExpiryReady && cardCvcReady) {
      setCardReady(true);
    }

    return () => {
      setCardReady(false);
    };
  }, [cardNumberReady, cardExpiryReady, cardCvcReady]);

  useEffect(() => {
    if (team) {
      const checkPaymentOptions = async () => {
        const response = await api().payments.checkPaymentOptions(team);
        setPaymentOptions(response);
      };
      checkPaymentOptions();
    }
  }, [team]);

  const isDark = (color: string, limit: number = 150) => {
    const splitColor = color.charAt(0) === '#' ? color.substring(1, 7) : color;
    const r = parseInt(splitColor.substring(0, 2), 16); // hexToR
    const g = parseInt(splitColor.substring(2, 4), 16); // hexToG
    const b = parseInt(splitColor.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 < limit;
  };

  const [randomSeats, setRandomSeats] = useState<ISeat[]>([]);
  const teamStripe = useRecoilValue(currentTeamStripeKey);

  const handleSubmit = async (
    e: any,
    stripe?: Stripe | null,
    elements?: StripeElements | null,
  ) => {
    e.preventDefault();

    if (team) {
      if (isFree) {
        try {
          setReceiptLoading(true);
          const ticketPaymentObj: ITicketPaymentObj = {
            match_id: Number(matchId),
            field: fieldId,
            seats:
              field?.field_type.is_unmarked === 1
                ? randomSeats
                : _seats.map((seat: ISeat) => seat.seats_id),
            price_groups: priceGroupPayload,
            fallBackPath: '',
            payment_method: 'free',
            discount_code: discountCode?.code,
            rule_package_id: matchedChosenTicketPackage?.ruleId,
          };
          const res = await api().payments.initiateMatchTicketPayment(
            team,
            ticketPaymentObj,
          );

          const { orderId } = res;

          if (orderId) {
            toast.info(
              `${t('ReceiptPage.EmailSentToast')} ${String(
                auth?.currentUser?.email,
              )}`,
            );
            navigate(`/${team}/${matchId}/transaction?${orderId}`);
          }
        } catch (err) {
          if (err instanceof Error) {
            toast.error(err.message);
          }
        } finally {
          setReceiptLoading(false);
        }
      } else if (
        selectedPaymentProvider === 'stripe'
        && teamStripe.length > 0
      ) {
        const cardElement = elements !== null && elements?.getElement(CardElement);

        if (cardElement && stripe) {
          const { error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });

          if (error) {
            toast.error(error.message);
          }
        }
        try {
          setReceiptLoading(true);

          const ticketPaymentObj: ITicketPaymentObj = {
            match_id: Number(matchId),
            field: fieldId,
            seats:
              field?.field_type.is_unmarked === 1
                ? randomSeats
                : _seats.map((seat: any) => seat.seats_id || seat.seat_id),
            price_groups: tickets,
            fallBackPath: '',
            payment_method: 'Stripe',
            discount_code: discountCode?.code,
            rule_package_id: matchedChosenTicketPackage?.ruleId,
          };

          const res = location.pathname === `/${team}/${matchId}/unpaid`
            ? await api().payments.retryMatchTicketPayment(
              team,
              retryTicketPaymentPayload,
            )
            : await api().payments.initiateMatchTicketPayment(
              team,
              ticketPaymentObj,
            );

          if (res.statusCode === 409) {
            toast.error(t('Errors.CardDeclinedError'));
            navigate(`/${team}/${matchId}/tickets`, { replace: true });
          }

          const cardNumElement = elements !== null && elements?.getElement(CardNumberElement);
          const cardElementContainer = document.querySelector('.card-element');
          const cardElementFinished = cardElementContainer
            && cardElementContainer.classList.contains('StripeElement--empty');

          if (cardNumElement && stripe) {
            const { error, paymentIntent } = await stripe.confirmCardPayment(
              res.stripeData.client_secret,
              {
                payment_method: {
                  card: cardNumElement,
                },
              },
            );

            if (error) {
              if (error.code === 'card_declined') {
                toast.error(t('Errors.CardDeclinedGeneral'));
                navigate(
                  `/${team}/${matchId}/unpaid?${res.stripeData.metadata.transaction_id}`,
                  { replace: true },
                );
              } else if (error.code === 'processing_error') {
                toast.error(t('Errors.CardDeclinedError'));
                navigate(
                  `/${team}/${matchId}/unpaid?${res.stripeData.metadata.transaction_id}`,
                  { replace: true },
                );
              } else if (error.code === 'expired_card') {
                toast.error(t('Errors.CardDeclinedExpired'));
                navigate(
                  `/${team}/${matchId}/unpaid?${res.stripeData.metadata.transaction_id}`,
                  { replace: true },
                );
              } else if (error.code === 'incorrect_number') {
                toast.error(t('Errors.CardDeclinedNumber'));
                navigate(
                  `/${team}/${matchId}/unpaid?${res.stripeData.metadata.transaction_id}`,
                  { replace: true },
                );
              } else {
                toast.error(t('Errors.CardDeclinedError'));
                navigate(
                  `/${team}/${matchId}/unpaid?${res.stripeData.metadata.transaction_id}`,
                  { replace: true },
                );
              }
            }

            if (paymentIntent?.status === 'succeeded') {
              setTicketMetaData({
                metadata: {
                  transaction_id: res.stripeData.metadata.transaction_id,
                },
              });
              toast.info(
                `${t('ReceiptPage.EmailSentToast')} ${String(
                  auth?.currentUser?.email,
                )}`,
              );
              navigate(
                `/${team}/${matchId}/transaction?${res.stripeData.metadata.transaction_id}`,
              );
              setStripeSuccess(true);
            }
          }
        } catch (err) {
          toast.error(String(err));
        } finally {
          setReceiptLoading(false);
        }
      } else if (selectedPaymentProvider === 'vipps') {
        try {
          if (team && matchId) {
            setReceiptLoading(true);
            setStripeSuccess(false);
            const ticketPaymentObj: ITicketPaymentObj = {
              match_id: Number(matchId),
              field: fieldId,
              seats:
                field?.field_type.is_unmarked === 1
                  ? randomSeats
                  : _seats.map((seat: any) => seat.seats_id || seat.seat_id),
              price_groups: priceGroupPayload,
              fallBackPath: '',
              payment_method: 'Vipps',
              discount_code: discountCode?.code,
              rule_package_id: matchedChosenTicketPackage?.ruleId,
            };

            const res = window.location.pathname === `/${team}/${matchId}/unpaid`
              ? await api().payments.retryMatchTicketPayment(
                team,
                retryTicketPaymentPayload,
              )
              : await api().payments.initiateMatchTicketPayment(
                team,
                ticketPaymentObj,
              );

            const { url } = res;

            if (url) {
              window.location.href = url;
            }
          }
        } catch (err) {
          if (err instanceof Error) {
            toast.error(err.message);
          }
        } finally {
          setReceiptLoading(false);
        }
      }
    }
    setSelectedPaymentProvider(null);
    setTicketMetaData(undefined);
    return false;
  };

  useEffect(() => {
    if (field?.field_type.is_unmarked && team && matchId) {
      const assignRandomSeats = async () => {
        const seatAmounts = tickets.reduce((acc, curr) => acc + curr.amount, 0);

        const randomSeating = await api().matches.getUnmarkedSeats(
          team,
          matchId,

          { field_id: Number(field.id), number_of_tickets: seatAmounts },
        );
        setRandomSeats(
          randomSeating.map((seat: any) => seat.seats_id || seat.seat_id),
        );
      };

      assignRandomSeats();
    }

    return () => {
      setRandomSeats([]);
    };
  }, [field?.field_type.is_unmarked === 1, team, matchId]);

  return (
    <div className="options">
      {receiptLoading && location.pathname !== `/${team}/${matchId}/unpaid` && (
        <div
          style={{
            textAlign: 'center',
            marginTop: '20px',
          }}
        >
          <Loading />
          <h1>{t('PaymentPage.PaymentInProgress')}</h1>
        </div>
      )}
      <div
        className="options-payments"
        style={receiptLoading ? { display: 'none' } : { display: 'flex' }}
      >
        <h1 className="options-payments_title">
          {t('PaymentPage.ChoosePaymentMethod')}
        </h1>
        {!isFree && paymentOptions?.vipps === 'active' && (
          <button
            className={
              selectedPaymentProvider === 'vipps'
                ? 'payoption chosenPayment'
                : 'payoption'
            }
            type="button"
            onClick={() => setSelectedPaymentProvider('vipps')}
          >
            <div className="payoption-left">
              <p
                className="payoption-left_text"
                style={
                  selectedPaymentProvider === 'vipps'
                    ? { color: '#fff' }
                    : { color: '#000' }
                }
              >
                Vipps
              </p>

              <div
                className={
                  selectedPaymentProvider === 'vipps'
                    ? 'circleContainer dark'
                    : 'circleContainer'
                }
              >
                {selectedPaymentProvider === 'vipps' && (
                  <div className="checkContainer">
                    <AiOutlineCheck
                      style={{ fill: isDark('#1fa387') ? '#fff' : '#000' }}
                    />
                  </div>
                )}
              </div>
            </div>

            <img
              src={`${process.env.PUBLIC_URL}/imgs/vipps.svg`}
              className="payoption-vippsLogo"
              alt="Vipps logo"
            />
          </button>
        )}

        {!isFree
          && paymentOptions?.stripe === 'active'
          && teamStripe.length > 0 && (
            <>
              <button
                className={
                  selectedPaymentProvider === 'stripe'
                    ? 'payoption chosenPayment'
                    : 'payoption'
                }
                type="button"
                onClick={() => setSelectedPaymentProvider('stripe')}
              >
                <div className="payoption-left">
                  <p
                    className="payoption-left_text"
                    style={
                      selectedPaymentProvider === 'stripe'
                        ? { color: '#fff' }
                        : { color: '#000' }
                    }
                  >
                    {t('PaymentPage.CardTitle')}
                  </p>

                  <div
                    className={
                      selectedPaymentProvider === 'stripe'
                        ? 'circleContainer dark'
                        : 'circleContainer'
                    }
                  >
                    {selectedPaymentProvider === 'stripe' && (
                      <div className="checkContainer">
                        <AiOutlineCheck
                          style={{ fill: isDark('#1fa387') ? '#fff' : '#000' }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="payoption-cardLogo">
                  <FaCreditCard />
                </div>
              </button>
              {selectedPaymentProvider === 'stripe' && (
                <StripeContainer
                  setCardNumberReady={setCardNumberReady}
                  setCardExpiryReady={setCardExpiryReady}
                  setCardCvcReady={setCardCvcReady}
                />
              )}
            </>
        )}

        {totalPrice === 0 && (
          <button className="payoption chosenPayment" type="button">
            <div className="payoption-left">
              <p
                className="payoption-left_text"
                style={isFree ? { color: '#fff' } : { color: '#000' }}
              >
                {t('PaymentPage.Free')}
              </p>

              <div className="circleContainer dark">
                <div className="checkContainer">
                  <AiOutlineCheck
                    style={{ fill: isDark('#1fa387') ? '#fff' : '#000' }}
                  />
                </div>
              </div>
            </div>

            <div className="payoption-cardLogo">
              <MdOutlineMoneyOff />
            </div>
          </button>
        )}
      </div>

      {selectedPaymentProvider === 'stripe' ? (
        <StripeOptions
          receiptLoading={receiptLoading}
          handleSubmit={handleSubmit}
          cardReady={cardReady}
        />
      ) : selectedPaymentProvider === 'vipps' || isFree ? (
        <button
          type="button"
          className="buttonPrimary"
          onClick={(e) => handleSubmit(e)}
          style={receiptLoading ? { display: 'none' } : { display: 'block' }}
        >
          {t('PaymentPage.GetTickets')}
        </button>
      ) : (
        !receiptLoading && (
          <button className="paybutton NONE" type="button" disabled>
            {t('PaymentPage.ChoosePaymentMethod')}
          </button>
        )
      )}
    </div>
  );
};

PayOptions.defaultProps = {
  retryTicketPaymentPayload: undefined,
};

export default PayOptions;
