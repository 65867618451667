import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import '../SASS/base/_Loader.scss';

interface ILoadingProps {
  size?: number;
  color?: string;
}

const Loading = ({ size, color }: ILoadingProps) => (
  <div className="ticketChooserLoader">
    <TailSpin color={color} height={size} width={size} />
  </div>
);

Loading.defaultProps = {
  size: 100,
  color: '#0476D9',
};

export default Loading;
