import React, { useEffect, useState } from 'react';
import { EmailAuthProvider, reauthenticateWithCredential, User } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { AuthState } from '../atom';
import { auth } from '../firebase';
import useAuth from '../hooks/useAuth';
import useGql from '../hooks/useGql';
import { IUpdateUserPayload } from '../Interfaces';
import '../SASS/base/_RegisterPage.scss';
import Sleep from '../utils/Sleep';
import { throwErrorToast } from '../utils/utils';
import api from '../api/api';
import OverlayLoading from '../components/OverlayLoading';

const NewCredentialScreen = () => {
  const { team } = useParams();
  const [registerPayload, setRegisterPayload] = useState<IUpdateUserPayload>({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  });
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [verification, setVerification] = useState<string>('');
  const [acceptedGeneralTerms, setAcceptedGeneralTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useRecoilValue(AuthState);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const pushUser = async () => {
    const {
      firstName, lastName, password,
      email,
    } = registerPayload;
    if (password.length < 8) {
      return throwErrorToast('auth/password-too-short');
    }
    const lowerCaseEmail = email.toLowerCase();
    const trimmedEmail = lowerCaseEmail.trim();
    try {
      const emailExists = await api().authorization.doesUserExist({ email: trimmedEmail });
      if (emailExists) {
        return throwErrorToast('auth/email-already-in-use');
      }
      const userServiceUser = await api().users.fetchUserServiceInfo();
      await api().users.editUserServiceInfo(userServiceUser.id, {
        email: trimmedEmail,
        password,
        firstname: firstName,
        lastname: lastName,
      });
      const credential = EmailAuthProvider
        .credential(trimmedEmail, password);
      await reauthenticateWithCredential(auth?.currentUser as User, credential);
      await auth?.currentUser?.reload();
      const { state }: any = location;
      const from = state?.from ?? `/${team}`;
      navigate(from, { replace: true });
      return toast.success('Your email was changed successfuly');
    } catch (err) {
      const error = err as {message:string};
      setLoading(false);
      return throwErrorToast({ code: error.message });
    }
  };

  useEffect(() => {
    if (user.isLoggedIn === true) {
      const { state }: any = location;
      const from = state?.from ?? `/${team}`;
      navigate(from, { replace: true });
    }
  }, [user]);

  return (
    <div className="register">
      <OverlayLoading loading={loading} />
      <div className="register-container">
        <div className="registerText">
          <h1>We need your email & name.</h1>
          <p>
            Unfortunately in our system we cannot find your account.
          </p>
        </div>

        <form
          className="registerForm"
          onSubmit={pushUser}
        >

          <div className="registerForm-container">
            <label htmlFor="email">{t('Register.Email')}</label>
            <input
              type="email"
              placeholder={t('Register.Email')}
              value={registerPayload.email}
              required
              name="email"
              id="email"
              className="textInput"
              onChange={(e) => setRegisterPayload((prevState) => ({
                ...prevState,
                email: e.target.value,
              }))}
            />
          </div>

          <div className="registerForm-container">
            <div className="names">

              <label htmlFor="firstName">{t('Register.FullName')}</label>

              <div className="names-inputs">
                <input
                  type="text"
                  placeholder={t('Register.firstName')}
                  value={registerPayload.firstName}
                  required
                  name="firstName"
                  id="firstName"
                  className="firstName textInput"
                  onChange={(e) => setRegisterPayload((prevState) => ({
                    ...prevState,
                    firstName: e.target.value,
                  }))}
                />
                <input
                  type="text"
                  placeholder={t('Register.lastName')}
                  value={registerPayload.lastName}
                  required
                  name="lastName"
                  id="lastName"
                  className="lastName textInput"
                  onChange={(e) => setRegisterPayload((prevState) => ({
                    ...prevState,
                    lastName: e.target.value,
                  }))}
                />
              </div>
            </div>
          </div>

          <div className="registerForm-container">
            <label htmlFor="password">{t('Register.Password')}</label>
            <input
              type="password"
              value={registerPayload.password}
              required
              name="password"
              id="password"
              className="textInput"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              onChange={(e) => setRegisterPayload((prevState) => ({
                ...prevState,
                password: e.target.value,
              }))}
            />
          </div>

          <label htmlFor="generalTerms" className="checkbox-container">
            <input
              type="checkbox"
              checked={acceptedGeneralTerms}
              name="generalTerms"
              id="generalTerms"
              onChange={() => setAcceptedGeneralTerms((prev: boolean) => !prev)}
            />
            <span className="checkmark" />
            <p>
              {t('Register.Terms1')}
              {' '}
              <Link to={`/${team}/terms`}>
                {' '}
                {t('Register.Terms2')}
              </Link>
              {' '}
              {t('Register.Terms3')}

            </p>
          </label>

          <button
            type="button"
            className="buttonPrimary"
            onClick={pushUser}
            disabled={registerPayload.password.length < 8 || !acceptedGeneralTerms}
          >
            {t('Register.RegisterButton')}
          </button>
        </form>

      </div>
    </div>
  );
};

export default NewCredentialScreen;
