import React from 'react';
import { Outlet } from 'react-router';
import { ToastContainer } from 'react-toastify';
import Nav from './components/Nav';
import { ITeam } from './Interfaces';

interface ILayoutProps {
  teams: ITeam[];
}

const Layout = ({ teams }: ILayoutProps) => (
  <>
    <ToastContainer
      theme="colored"
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      style={{ fontSize: '1.35rem', zIndex: 99999 }}
    />
    <Nav teams={teams} />
    <Outlet />
  </>
);

export default Layout;
