import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface StripeOptionsProps {
    receiptLoading: boolean | null;
    cardReady: boolean;
    handleSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
         stripe: Stripe | null, elements: StripeElements | null) => void;
}

const StripeOptions = ({ receiptLoading, cardReady, handleSubmit }: StripeOptionsProps) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  return (
    <button
      type="button"
      className={cardReady ? 'buttonPrimary' : 'buttonDisabled'}
      onClick={(e) => handleSubmit(e, stripe, elements)}
      style={receiptLoading ? { display: 'none' } : { display: 'block' }}
      disabled={!cardReady}
    >
      {t('PaymentPage.GetTickets')}
    </button>
  );
};

export default StripeOptions;
