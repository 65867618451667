import React from 'react';
import {
  CardNumberElement, CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import '../../SASS/base/_StripeContainer.scss';
import { useTranslation } from 'react-i18next';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent, StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

interface IStripeContainerProps {
  setCardNumberReady: (arg0: boolean) => void;
              setCardExpiryReady: (arg0: boolean) => void;
              setCardCvcReady: (arg0: boolean) => void;
}

const StripeContainer = ({
  setCardCvcReady,
  setCardExpiryReady, setCardNumberReady,
}: IStripeContainerProps) => {
  const { t } = useTranslation();
  return (
    <div className="stripeContainer">
      <div className="stripeContainer-cardWrap">
        <p>{t('StripeContainer.CardNum')}</p>
        <CardNumberElement
          className="stripeCard"
          onChange={(e: StripeCardNumberElementChangeEvent) => (e.complete
            ? setCardNumberReady(true) : setCardNumberReady(false))}
        />
      </div>
      <div className="stripeContainer-cardWrap">
        <p>{t('StripeContainer.CardExp')}</p>
        <CardExpiryElement
          className="stripeCard"
          onChange={(e: StripeCardExpiryElementChangeEvent) => (
            e.complete ? setCardExpiryReady(true) : setCardExpiryReady(false)
          )}
        />
      </div>
      <div className="stripeContainer-cardWrap">
        <p>{t('StripeContainer.CardCvc')}</p>
        <CardCvcElement
          className="stripeCard"
          onChange={(e:StripeCardCvcElementChangeEvent) => (e.complete
            ? setCardCvcReady(true) : setCardCvcReady(false))}
        />
      </div>
    </div>
  );
};

export default StripeContainer;
