import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationNO from './locales/no/translation.json';
import translationSWE from './locales/swe/translation.json';

const resources = {
  enUS: { translation: translationEN },
  nb: { translation: translationNO },
  sv: { translation: translationSWE },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'enUS',
    // lng: 'enUS',
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
