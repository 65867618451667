import React from 'react';
import { useLocation, useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import TicketNavigator from './Tickets/TicketNavigator';
import '../SASS/base/_LeftBox.scss';
import CountDown from './CountDown';
import useTransactionId from '../hooks/useTransactionId';
import QuantityDiscountBanner from './QuantityDiscountBanner';
import { quantityDiscountPackageRules, showQuantityDiscountBanner } from '../atom';

interface ILeftBoxProps {
  teamParam: string | undefined;
  matchIdParam: string | undefined;
  siteTitle: string;
  chosenField: string | undefined;
  chosenRow: string | undefined;
  component: React.ReactElement | React.ReactElement[];
  bottomSectionClassCategory: string;
}

const LeftBox = ({
  siteTitle,
  chosenField,
  chosenRow,
  teamParam,
  matchIdParam,
  component,
  bottomSectionClassCategory,
}: ILeftBoxProps) => {
  const location = useLocation();
  const { team, matchId } = useParams<string>();
  const { t } = useTranslation();

  const matchedChosenTicketPackage = useRecoilValue(quantityDiscountPackageRules);
  const showQuantityDiscountBannerState = useRecoilValue(showQuantityDiscountBanner);

  return (
    <div className="Tickets-container_leftBox-wrap">
      {location.pathname
      !== `/${team}/${matchId}/transaction` && location.pathname
      !== `/${team}/${matchId}/unpaid` && location.pathname
      !== `/${team}/${matchId}/checkingPayment` ? (
        <section className="Tickets-container_leftBox-topSection">
          {location.pathname === `/${team}/${matchId}/payment` && (
            <CountDown />
          )}
          {
   (location.pathname !== `/${team}/purchaseHistory`
     || location.pathname !== `/${team}/${matchId}/transaction`
     || location.pathname !== `/${team}/${matchId}/payment`
     || location.pathname !== `/${team}/${matchId}/unpaid`
     || location.pathname !== `/${team}/${matchId}/checkingPayment`) && showQuantityDiscountBannerState && (
     <QuantityDiscountBanner discountPackage={matchedChosenTicketPackage} />
   )
}
          <div className="paddingNavigator">
            <TicketNavigator
              siteTitle={siteTitle}
              chosenField={chosenField}
              chosenRow={chosenRow}
              teamParam={teamParam}
              matchIdParam={matchIdParam}
            />
          </div>

        </section>
        ) : (
          location.pathname !== `/${team}/${matchId}/unpaid` && location.pathname !== `/${team}/${matchId}/checkingPayment` && (
            <section className="receipt-container_leftBox-topSection" style={{ boxShadow: 'none' }}>
              <img
                className="receipt-container_leftBox-topSection--icon"
                src={`${process.env.PUBLIC_URL}/imgs/receipt-checkmark.svg`}
                alt="checkmark"
              />
              <h1 className="receipt-container_leftBox-topSection--title">
                {t('ReceiptPage.ReceiptTitle')}

              </h1>
            </section>
          )
        )}
      <section
        className={`${bottomSectionClassCategory}-container_leftBox-bottomSection`}
      >
        {component}
      </section>
    </div>
  );
};

export default LeftBox;
