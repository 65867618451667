import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { RecoilRoot } from 'recoil';
import ReactDOM from 'react-dom/client';
import './SASS/base/_base.scss';
import './fonts/Gellix-Regular.otf';
import './fonts/Gellix-Bold.otf';
import './fonts/Gellix-ExtraBold.otf';
import './fonts/Gellix-Medium.otf';
import './fonts/Gellix-SemiBold.otf';
import './fonts/Gellix-Thin.otf';
import './fonts/Gellix-Light.otf';
import App from './App';
import './i18next';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <App />
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
);
