import {
  RecaptchaVerifier, signInWithCustomToken,
} from 'firebase/auth';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import PhoneInput from 'react-phone-number-input';
import api from '../api/api';
import { auth } from '../firebase';
import { IRegisterPayload } from '../Interfaces';
import '../SASS/base/_RegisterPage.scss';
import Sleep from '../utils/Sleep';
import OverlayLoading from '../components/OverlayLoading';
import { throwErrorToast } from '../utils/utils';
import { AuthState } from '../atom';

const RegisterPage = () => {
  const navigate = useNavigate();
  const { team } = useParams();
  const [registerPayload, setRegisterPayload] = useState<IRegisterPayload>({
    email: '',
    phoneNumber: '',
    countryCode: '47',
    firstName: '',
    lastName: '',
    password: '',
  });
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [SMSCode, setSMSCode] = useState<string>('');
  const [confirmation, setConfirmation] = useState<string>('');
  const [acceptedGeneralTerms, setAcceptedGeneralTerms] = useState(false);
  const [captchaVerifier, setCaptchaVerifier] = useState<RecaptchaVerifier>();
  const [loading, setLoading] = useState(false);
  const [showPassTooShort, setShowPassTooShort] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState<boolean>(false);

  const user = useRecoilValue(AuthState);
  const recaptchaWrapperRef = createRef<HTMLDivElement>();

  const location = useLocation();
  const { t } = useTranslation();
  const pushUser = async () => {
    const {
      email, password, firstName, lastName,
    } = registerPayload;
    const lowerCaseEmail = email.toLowerCase();
    const trimmedEmail = lowerCaseEmail.trim();
    if (password.length < 8) {
      return setShowPassTooShort(true);
    }
    try {
      setLoading(true);
      const emailExists = await api().authorization.doesUserExist({ email: trimmedEmail });
      const phoneExists = await api().authorization.doesUserExist({ phone: phoneNumber });
      if (emailExists || phoneExists) {
        setLoading(false);
        return toast.error(t('Errors.AlreadyRegistered'));
      }
      const { idToken } = await api().authorization.verifyPhoneCode({
        sessionInfo: confirmation,
        phoneNumber,
        code: SMSCode,
      });
      await api().authorization.afterSignup({
        email: trimmedEmail,
        password,
        firstName,
        lastName,
      }, idToken);
      await Sleep(3000);
      const { idToken: newIdToken } = await api().authorization.signInWithEmailPass({
        email: trimmedEmail,
        password,
      });
      const { token: newCustomToken } = await api().authorization.getCustomToken(
        newIdToken,
      );

      await signInWithCustomToken(auth, newCustomToken);
      const { state }: any = location;
      const from = state?.from ?? `/${team}`;
      navigate(from, { replace: true });
      toast.success(t('Auth.SuccessFullRegistration'));
      return setLoading(false);
    } catch (err) {
      const error = err as {message:string};
      setLoading(false);
      return throwErrorToast({ code: error.message });
    }
  };
  const generateCaptcha = () => {
    if (codeSent || resendCode) {
      window.recaptchaVerifier.clear();
      recaptchaWrapperRef.current!.innerHTML = '<div id="recaptcha-container"></div>';
    }

    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
    }, auth);
  };
  const handleSendCode = async () => {
    const { password } = registerPayload;
    if (password.length < 8) {
      return setShowPassTooShort(true);
    }
    setLoading(true);
    try {
      const phoneExists = await api().authorization.doesUserExist({ phone: phoneNumber });
      if (phoneExists) {
        setLoading(false);
        return toast.error(t('Errors.AlreadyRegistered'));
      }
      if (!codeSent) {
        generateCaptcha();
      }
      const recaptchaToken = await window.recaptchaVerifier.verify();
      const { sessionInfo } = await api().authorization.sendSignInWithPhone(
        { phoneNumber, recaptchaToken },
      );

      setConfirmation(sessionInfo);
      setCodeSent(true);
      return setLoading(false);
    } catch (err: unknown) {
      setLoading(false);
      const { message } = err as Error;
      if (message.includes('INVALID_PHONE_NUMBER')) {
        setCodeSent(true);
        setInvalidPhoneNumber(true);
        return toast.error(t('Errors.InvalidPhoneNumber'));
      }
      return throwErrorToast({ code: 'auth/something-went-wrong' });
    }
  };

  const sendNewVerifyCode = async () => {
    setLoading(true);
    setInvalidPhoneNumber(false);

    try {
      generateCaptcha();
      const recaptchaToken = await window.recaptchaVerifier.verify();

      const { sessionInfo } = await api().authorization.sendSignInWithPhone(
        { phoneNumber, recaptchaToken },
      );

      setConfirmation(sessionInfo);
      setResendCode(true);
      return setLoading(false);
    } catch (err) {
      throwErrorToast(err);
    }
    return null;
  };

  useEffect(() => {
    if (user.isLoggedIn === true) {
      const { state }: any = location;
      const from = state?.from ?? `/${team}`;
      navigate(from, { replace: true });
    }
  }, [user]);

  return (
    <div className="register">
      <div ref={recaptchaWrapperRef}>
        <div id="recaptcha-container" />
      </div>
      <OverlayLoading loading={loading} />
      <div className="register-container">
        <div className="registerText">
          <h1>{t('Register.RegisterButton')}</h1>
        </div>

        <form
          className="registerForm"
          onSubmit={pushUser}
        >
          <div className="registerForm-container">
            <div className="phoneInputs">
              <label htmlFor="phoneNumber">
                {t('Register.PhoneNumber')}
              </label>
              <PhoneInput
                defaultCountry={team === 'taby' ? 'SE' : 'NO'}
                value={phoneNumber}
                onChange={setPhoneNumber}
              />

            </div>
          </div>

          <div className="registerForm-container">
            <label htmlFor="email">{t('Register.Email')}</label>
            <input
              type="email"
              placeholder={t('Register.Email')}
              value={registerPayload.email}
              required
              name="email"
              id="email"
              className="textInput"
              onChange={(e) => setRegisterPayload((prevState) => ({
                ...prevState,
                email: e.target.value,
              }))}
            />
          </div>

          <div className="registerForm-container">
            <label htmlFor="password">{t('Register.Password')}</label>
            <input
              type="password"
              value={registerPayload.password}
              required
              name="password"
              id="password"
              className="textInput"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              onChange={(e) => {
                setRegisterPayload((prevState) => ({
                  ...prevState,
                  password: e.target.value,
                }));
                setShowPassTooShort(false);
              }}
            />
            {showPassTooShort && <span className="registerForm-invalidPssword">{t('Errors.PasswordTooShort')}</span>}
          </div>

          <div className="registerForm-container">
            <div className="names">

              <label htmlFor="firstName">{t('Register.FullName')}</label>

              <div className="names-inputs">
                <input
                  type="text"
                  placeholder={t('Register.firstName')}
                  value={registerPayload.firstName}
                  required
                  name="firstName"
                  id="firstName"
                  className="firstName textInput"
                  onChange={(e) => setRegisterPayload((prevState) => ({
                    ...prevState,
                    firstName: e.target.value,
                  }))}
                />
                <input
                  type="text"
                  placeholder={t('Register.lastName')}
                  value={registerPayload.lastName}
                  required
                  name="lastName"
                  id="lastName"
                  className="lastName textInput"
                  onChange={(e) => setRegisterPayload((prevState) => ({
                    ...prevState,
                    lastName: e.target.value,
                  }))}
                />
              </div>
            </div>
          </div>

          <div className="Login-container_Form_verificationWrap">
            <p className="Login-container_Form_verificationWrap--verificationText">
              {t('Register.VerificationCode')}
            </p>
            <div className="Login-container_Form_verificationWrap--inputWrap">
              <input
                type="number"
                value={SMSCode}
                required
                name="countryCode"
                id="countryCode"
                step="1"
                className="Login-container_Form_verificationWrap--inputWrap-verificationInput"
                onChange={(e) => setSMSCode(e.target.value)}
                onWheel={(e) => (e.target as HTMLElement).blur()}
              />

              <button
                onClick={() => {
                  if (!codeSent) {
                    handleSendCode();
                  } else {
                    sendNewVerifyCode();
                  }
                }}
                type="button"
                className={`Login-container_Form_verificationWrap--inputWrap-sendNewBtn ${!phoneNumber
                  ? 'textDisabled' : ''}`}
                disabled={!phoneNumber}
              >
                {codeSent ? (
                  t('Register.ResendCode')
                ) : (
                  t('Register.SendCode')
                )}
              </button>

            </div>
          </div>

          <label htmlFor="generalTerms" className="checkbox-container">
            <input
              type="checkbox"
              checked={acceptedGeneralTerms}
              name="generalTerms"
              id="generalTerms"
              onChange={() => setAcceptedGeneralTerms((prev: boolean) => !prev)}
            />
            <span className="checkmark" />
            <p>
              {t('Register.Terms1')}
              {' '}
              <Link to={`/${team}/terms`} target="_blank">
                {' '}
                {t('Register.Terms2')}
              </Link>
              {' '}
              {t('Register.Terms3')}

            </p>
          </label>

          <button
            type="button"
            className="buttonPrimary"
            onClick={pushUser}
            disabled={registerPayload.password.length < 8 || !codeSent || !acceptedGeneralTerms}
          >
            {t('Register.RegisterButton')}
          </button>
        </form>

      </div>
    </div>
  );
};

export default RegisterPage;
