/* eslint-disable react/jsx-no-useless-fragment */
import {
  ChangeEvent,
  FormEvent, useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { TbTicket } from 'react-icons/tb';
import { useLocation } from 'react-router';
import api from '../../api/api';
import '../../SASS/base/ReceiptPage/_ReceiptLeftBox.scss';
import {
  IPriceGroup, IReceipt, IReceiptSeats, IRecoilHoc,
} from '../../Interfaces';
import Loading from '../Loading';
import useLocale from '../../hooks/useLocale';
import { auth } from '../../firebase';
import { convertCurrency } from '../../utils/utils';
import RecoilHoc from '../RecoilHoc';

interface IReceiptSummaryProps extends IRecoilHoc {
  team: string | undefined;
  transactionId: string | undefined;
  receipt: IReceipt | undefined;
  receiptLoading: boolean
  matchId: string | undefined;
}

interface ticketGroup {
  quantity: number;
  group: IPriceGroup;
  seats: IReceiptSeats;
}

interface seatGroup {
  row_id: number;
  fieldName: string;
}

const ReceiptSummary = ({
  team, transactionId, receipt, receiptLoading, currentTeamInfo: { currency }, matchId,
}: IReceiptSummaryProps) => {
  const [showEmailInput, setShowEmailInput] = useState<boolean>(true);
  const [emailSendingLoading, setEmailSendingLoading] = useState<boolean>(false);
  const [ticketDownloading, setTicketDownloading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { locale } = useLocale();
  const location = useLocation();

  const emailInputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [showEmailInput]);

  const groupMap: ticketGroup[] = [];
  const seatMap: seatGroup[] = [];
  receipt?.tickets?.forEach((ticket) => {
    const groupIndex = groupMap.findIndex(
      (g) => g.group.title === ticket.price_group.title,
    );
    if (groupIndex < 0) {
      groupMap.push({
        quantity: 1,
        group: ticket.price_group,
        seats: ticket.seat_match,
      });
    } else {
      groupMap[groupIndex].quantity += 1;
    }
  });

  receipt?.tickets?.map((ticket) => {
    const seatIndex = seatMap.findIndex(
      (s) => s.fieldName === ticket.seat_match.seat.row.field.name,
    );

    if (seatIndex === -1) {
      seatMap.push({
        row_id: ticket.seat_match.seat.row_id,
        fieldName: ticket.seat_match.seat.row.field.name,
      });
    }

    return seatMap;
  });

  const seats = receipt?.tickets?.flatMap(
    (ticket) => ticket.seat_match.seat.seat_id,
  );

  const [emailInput, setEmailInput] = useState<string>('');
  const [notValidEmail, setNotValidEmail] = useState<boolean>(true);

  useEffect(() => {
    setEmailInput(auth?.currentUser?.email || '');
  }, [auth?.currentUser]);

  const checkIsValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  useEffect(() => {
    if (emailInput) {
      checkIsValidEmail(emailInput);

      if (!checkIsValidEmail(emailInput)) {
        setNotValidEmail(true);
      } else {
        setNotValidEmail(false);
      }
    }
  }, [auth?.currentUser, emailInput, checkIsValidEmail]);

  const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!checkIsValidEmail(e.currentTarget.value)) {
      setEmailInput(e.currentTarget.value);
      setNotValidEmail(true);
    } else {
      setNotValidEmail(false);
      setEmailInput(e.currentTarget.value);
    }
  };

  const handleSendReceiptEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (team && transactionId) {
      try {
        setEmailSendingLoading(true);
        const emailReceiptRes = await api().payments.sendReceiptEmail(
          team,
          transactionId,
          { email: emailInput },
          locale,
        );

        toast.success(`${t('ReceiptPage.EmailSentToast')} ${emailInput}`);
        setEmailInput('');
        setShowEmailInput(false);
        setNotValidEmail(true);
        return emailReceiptRes;
      } catch (err) {
        toast.error(String(err));
      } finally {
        setEmailSendingLoading(false);
      }
    }

    return null;
  };

  const [areSeatsUnmarked, setAreSeatsUnmarked] = useState<boolean>(false);
  const [_row, setRow] = useState<number>(0);
  useEffect(() => {
    receipt?.tickets.map(
      (ticket) => ticket.seat_match.seat.row.field.field_type.is_unmarked,
    ).every((isUnmarked) => (isUnmarked === 1
      ? setAreSeatsUnmarked(true) : setAreSeatsUnmarked(false)));

    receipt?.tickets.map((ticket) => ticket.seat_match.seat.row.number).every((row) => setRow(row));
  }, [receipt]);

  const downloadTicket = async () => {
    setTicketDownloading(true);
    const response = await api().tickets.getTicketReceiptPDF(team || '', transactionId || '', locale);
    setTicketDownloading(false);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'ticket.pdf');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      {!receiptLoading ? (
        <>
          <div className="order">
            <h1 className="order-title">{t('ReceiptPage.OrderTitle')}</h1>
            <div className="order-description">
              <p className="order-description_teams">
                {receipt?.match?.home_team.name}
                {' '}
                -
                {' '}
                {receipt?.match?.away_team.name}
              </p>
              <p className="order-description_date">
                {receipt?.match?.match_starts_at_readable}
              </p>
            </div>
            <p className="order-orderId">
              {t('ReceiptPage.OrderNumber')}
              {' '}
              {receipt?.reference}
            </p>
            <div className="order-pay">
              <p className="order-pay_date">
                {t('ReceiptPage.OrderDate')}
                {' '}
                {receipt?.pay_date}
              </p>
              <p className="order-pay_method">
                {t('ReceiptPage.OrderPaymentMethod')}
                {' '}
                {receipt?.payment_method}
              </p>
            </div>
          </div>
          <div className="tickets">
            <h1 className="tickets-title">
              {seats?.length === 1
                ? t('ReceiptPage.OrderSingleTicket')
                : t('ReceiptPage.OrderMultipleTickets')}
            </h1>
            <div className="tickets-tickets">
              {groupMap?.map((ticket: ticketGroup) => (
                <div className="tickets-tickets_ticket" key={ticket.group.id}>
                  <p className="tickets-tickets_ticket--title">
                    {ticket.quantity}
                    {' '}
                    {ticket.group.title}
                  </p>
                </div>
              ))}

            </div>
          </div>

          <div className="ticketSeats">
            <h1 className="ticketSeats-title">
              {seats?.length === 1
                ? t('ReceiptPage.OrderSingleSeat')
                : t('ReceiptPage.OrderMultipleSeats')}
            </h1>
            {areSeatsUnmarked ? (
              seatMap?.flatMap((ticketSeat: seatGroup) => (
                <p className="ticketSeats-seat" key={ticketSeat.row_id}>
                  {t('ReceiptPage.OrderField')}
                  {' '}
                  {ticketSeat.fieldName}
                  ,
                  {' '}
                  Free seating
                </p>
              ))
            ) : (
              seatMap?.flatMap((ticketSeat: seatGroup) => (
                <p className="ticketSeats-seat" key={ticketSeat.row_id}>
                  {t('ReceiptPage.OrderField')}
                  {' '}
                  {ticketSeat.fieldName}
                  ,
                  {' '}
                  {t('ReceiptPage.OrderRow')}
                  {' '}
                  {_row}
                  ,
                  {' '}
                  {seats?.length === 1
                    ? t('ReceiptPage.OrderSingleSeat')
                    : t('ReceiptPage.OrderMultipleSeats')}
                  {' '}
                  {seats?.length === 1
                    ? seats?.map((seat) => `${seat}`)
                    : seats?.map((seat, index) => `${index ? ', ' : ''} ${seat}`)}
                </p>
              ))
            )}

          </div>

          {receipt && receipt.fees.length > 0 && (
            <div className="ticketSeats">
              <h1 className="ticketSeats-title">{t('ReceiptPage.OrderFees')}</h1>

              {receipt?.fees.map((fee) => (
                <div className="tickets-tickets_ticket" key={fee.id}>
                  <p className="tickets-tickets_ticket--title">
                    {fee.name}
                    {' '}
                    x
                    {' '}
                    {receipt.tickets.length}
                  </p>
                  <p className="tickets-tickets_ticket--price">
                    {fee.percentage}
                    %
                  </p>
                </div>
              ))}
            </div>
          )}

          <div className="ticketSeats">
            <h1 className="ticketSeats-title">{t('PaymentPage.CampaignSectionTitle')}</h1>
            {receipt?.rulePackage && (
            <div className="tickets-tickets_ticket">
              <p className="summary-campaign_discountTitle" style={{ gridColumn: '1 / 1', gridRow: '2 / 2' }}>{receipt.rulePackage.package?.name}</p>
              <span className="summary-campaign_discountPrice" style={{ gridColumn: '2 / 2', gridRow: '2 / 2' }}>
                {`-${convertCurrency(currency)} ${receipt.rulePackage.package?.price},-`}
              </span>
            </div>
            )}
          </div>

          <div className="totalPrice">
            <h1 className="totalPrice-title">{t('ReceiptPage.OrderTotalPrice')}</h1>
            <h1 className="totalPrice-price">
              {`${convertCurrency(currency)} ${receipt?.price?.toFixed(0)},-`}
            </h1>
          </div>

          {location.pathname !== `/${team}/${matchId}/unpaid` && (
          <form className="options-emailReceipt" onSubmit={(e) => handleSendReceiptEmail(e)}>
            <div className="options-emailReceipt_check">
              <input
                className="options-emailReceipt_check--input"
                name="emailReceipt"
                id="emailReceipt"
                type="checkbox"
                onChange={() => setShowEmailInput((v) => !v)}
                checked={showEmailInput}
              />
              <label
                className="options-emailReceipt_check--text"
                htmlFor="emailReceipt"
              >
                {t('ReceiptPage.EmailCheckbox')}
              </label>
            </div>
            {showEmailInput && (
            <input
              className="options-emailReceipt_check--fieldInput"
              type="email"
              name="emailReceipt"
              id="emailReceipt"
              placeholder={t('ReceiptPage.EmailPlaceholder')}
              value={emailInput!}
              // defaultValue={currentUser?.email!}
              onChange={(e) => handleEmailInputChange(e)}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              ref={emailInputRef}
              required
            />
            )}

            {emailSendingLoading ? (
              <Loading size={75} />
            ) : (
              showEmailInput && (
              <button
                type="submit"
                disabled={notValidEmail}
                className={notValidEmail
                  ? 'buttonDisabled' : 'buttonPrimary'}
              >
                {t('ReceiptPage.EmailValid')}
              </button>
              )
            )}

          </form>
          )}
          {location.pathname !== `/${team}/${matchId}/unpaid` && (
          <button
            onClick={downloadTicket}
            className="ticketPageBtn"
            disabled={ticketDownloading}
            type="button"
          >
            <TbTicket
              className="ticketPageBtn-icon"
            />
            {t('ReceiptPage.OrderDownloadTicket')}
          </button>
          )}
          {ticketDownloading && (
            <Loading size={30} />
          )}
        </>

      ) : (
        <div style={{ margin: '2rem auto' }}>
          <Loading />
          <p style={{ margin: '2rem 0' }}>{t('ReceiptPage.LoadingText')}</p>
        </div>
      )}

    </>
  );
};

export default RecoilHoc(ReceiptSummary);
