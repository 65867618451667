import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { IDiscount } from '../../../Interfaces';

interface IDiscountFieldProps {
    handleDiscount: (e: any) => void,
    setDiscountValue: (e: any) => void,
    setShowAddDiscount: (arg0: boolean) => void,
    setDiscountRes: (arg0: undefined) => void,
    setShowCampaign: (arg0: boolean) => void,
    discountValue: string,
    discountInputRef: MutableRefObject<HTMLInputElement | null>
    discountRes: IDiscount | undefined
}

const InputDiscountField = (
  {
    handleDiscount, setDiscountValue, setShowAddDiscount,
    discountValue, discountInputRef, discountRes,
    setDiscountRes, setShowCampaign,
  }: IDiscountFieldProps,
) => {
  const { t } = useTranslation();
  return (
    <form className="summary-discountInput" onSubmit={(e) => handleDiscount(e)}>
      <label
        htmlFor="discount"
        className="summary-discountInput_title"
      >
        {t('PaymentPage.CampaignSectionTitle')}

      </label>
      <button
        type="button"
        className="summary-discountInput_removeBtn"
        onClick={() => {
          setShowAddDiscount(true);
          setDiscountRes(undefined);
          setShowCampaign(false);
        }}
      >
        <MdClose />
        {' '}
        {t('PaymentPage.Close')}
      </button>
      <input
        name="discount"
        id="discount"
        className="summary-discountInput_input"
        type="text"
        placeholder={`${t('PaymentPage.Discount')}`}
        value={discountValue}
        onChange={(e) => setDiscountValue(e.target.value)}
        ref={discountInputRef}
        style={discountValue.length >= 2 ? { gridColumn: '1 / 1' } : { gridColumn: '1 / -1' }}
        disabled={discountRes?.active}
      />
      {discountValue.length >= 1 ? (
        <button
          type="button"
          className="summary-discountInput_addDiscountBtn"
          onClick={(e) => handleDiscount(e)}
          disabled={discountRes?.active}
        >
          {t('PaymentPage.CampaignSectionAddCode')}
        </button>
      ) : ''}
    </form>
  );
};

export default InputDiscountField;
