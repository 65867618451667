import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTransition, animated,
} from 'react-spring';
import { useRecoilState } from 'recoil';
import { nb, enUS, sv } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { format, parseISO } from 'date-fns';
import {
  IFieldType, IMatch, IPriceGroup, IRecoilHoc, ISeat,
} from '../../Interfaces';
import {
  chosenFieldState,
  chosenRowState,
  chosenSeatState,
  chosenTicketState,
  FeeState,
  FieldTypeState,
  matchState, PriceGroupsState, toggleCart,
} from '../../atom';
import calculatePrice from '../../utils/CalcPrice';
import RecoilHoc from '../RecoilHoc';
import { convertCurrency } from '../../utils/utils';

const MobileCart = ({ currentTeamInfo: { currency } }:IRecoilHoc) => {
  const [_toggleCart, setToggleCart] = useRecoilState(toggleCart);
  const [match] = useRecoilState<IMatch | undefined>(matchState);
  const [chosenField] = useRecoilState(chosenFieldState);
  const [chosenRow] = useRecoilState(chosenRowState);
  const [chosenSeat] = useRecoilState(chosenSeatState);
  const [tickets] = useRecoilState(chosenTicketState);
  const [fieldTypes] = useRecoilState<IFieldType[]>(FieldTypeState);
  const [priceGroups] = useRecoilState(PriceGroupsState);
  const [fees] = useRecoilState(FeeState);
  const [foo, setFoo] = useState<boolean>(false);

  const { t } = useTranslation();

  let cheapestFieldType: IFieldType | undefined;
  fieldTypes.forEach((fieldType) => {
    const price = Number(fieldType.price);

    if (price && (cheapestFieldType === undefined || Number(cheapestFieldType.price) > price)) {
      cheapestFieldType = fieldType;
    }
  });
  let price: number = 0;
  if (cheapestFieldType || chosenField?.field_type) {
    const fieldTypePrice: number = Number(
      chosenField?.field_type.price,
    ) || (Number(cheapestFieldType?.price));
    price = calculatePrice(
      priceGroups,
      fieldTypePrice,
      fees,
    );
  }

  const transitions = useTransition(_toggleCart, {
    from: { opacity: 0, y: 40 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 40 },
    delay: 100,
    reverse: _toggleCart,
  });

  const locales: any = { nb, enUS, sv };

  const matchStartsAt = match?.match_starts_at;

  const startsAt = matchStartsAt && format(parseISO(matchStartsAt), 'EEEE dd. MMMM HH:mm', {
    locale: locales[i18n.use(LanguageDetector).language],
  });

  return (
    <>
      {transitions((style, item) => (
        item && (
          <>
            <animated.div className="overlay" style={style} onClick={() => setToggleCart(false)} />
            <animated.div className="cartWrap" style={style}>
              <button
                className="closeCart"
                type="button"
                onClick={
                  () => {
                    setToggleCart(false);
                  }
                }
              >
                <img
                  className="closeCart_icon"
                  src={`${process.env.PUBLIC_URL}/imgs/cross-icon-black.svg`}
                  alt="cross icon"
                />
              </button>
              <div className="mobileCart">
                <div className="mobileCart-matchInfo">
                  <h3 className="mobileCart-matchInfo_title smallTitle">{t('Mobile.MobileCartOrder')}</h3>
                  <h2 className="mobileCart-matchInfo_info">
                    {`${match?.home_team.name} - ${match?.away_team.name}`}
                  </h2>
                  <h2 className="mobileCart-matchInfo_date">{startsAt}</h2>
                </div>
                <div className="mobileCart-seats">
                  <h3 className="mobileCart-seats_title smallTitle">{t('Mobile.MobileCartSeats')}</h3>
                  {chosenField === undefined ? (
                    <h2 className="mobileCart-seats_seats">{t('Mobile.MobileCartNoChosenFields')}</h2>
                  ) : (
                    <h2 className="mobileCart-seats_seats">
                      {`Section: ${chosenField?.name}, 
            Row ${chosenRow?.number}, Seat 
            ${chosenSeat.map((seat: ISeat) => ` ${seat.seats_seat_id}`)}`}
                    </h2>
                  )}
                </div>
                <div className="mobileCart-tickets">
                  <h3 className="mobileCart-tickets_title smallTitle">{t('Mobile.MobileCartTickets')}</h3>
                  {tickets.length === 0 ? (
                    <h2 className="mobileCart-tickets_noTicket">{t('Mobile.MobileCartNoChosenTickets')}</h2>
                  ) : (
                    tickets.map((ticket: IPriceGroup) => (
                      <div className="mobileCart-tickets_wrap" key={ticket.id}>
                        <p className="mobileCart-tickets_wrap--quantity">
                          {ticket.amount}
                          {' '}
                          {ticket.title}
                        </p>
                        <p className="mobileCart-tickets_wrap--price">
                          {`${convertCurrency(currency)} ${ticket.min_price * ticket.amount},-`}
                        </p>
                      </div>
                    ))
                  )}
                </div>
                <div className="mobileCart-totalPrice">
                  <h3 className="mobileCart-totalPrice_title smallTitle">{t('Mobile.MobileCartPrice')}</h3>
                  <h1 className="mobileCart-totalPrice_price">
                    {`${convertCurrency(currency)} ${price?.toFixed(0)},-`}
                  </h1>
                </div>
              </div>
            </animated.div>
          </>
        )
      ))}
    </>
  );
};

export default RecoilHoc(MobileCart);
