import { useEffect, useState } from 'react';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Markup } from 'interweave';
import '../SASS/base/_ClubTermsPage.scss';
import { useRecoilValue } from 'recoil';
import i18n from '../i18next';
import api from '../api/api';
import { CurrentTeamInfo } from '../atom';

interface IClubTerm {
    type:string;
    title:string;
    intro:string;
    description:string;
  }

const ClubTerms = () => {
  const [terms, setTerms] = useState<IClubTerm[]>([]);
  const [fetchError, setFetchError] = useState(false);
  const currentTeamInfo = useRecoilValue(CurrentTeamInfo);
  const siteLanguage = i18n.use(LanguageDetector).language;
  useEffect(() => {
    if (currentTeamInfo) {
      const fetchTerms = async () => {
        try {
          const fetchedTerms = await api().terms.getAllTerms();
          setTerms(fetchedTerms);
        } catch (err) {
          setFetchError(true);
        }
      };
      fetchTerms();
    }
  }, [currentTeamInfo]);

  return (
    fetchError ? (
      <div className="ClubTerms">
        <div className="ClubTerms-container">

          <h2>
            {siteLanguage === 'enUS' ? 'Unable to display Terms and Conditions at this time.' : `Kan ikke vise vilkår og betingelser for øyeblikket
            `}

          </h2>
        </div>
      </div>
    )
      : (
        <div className="ClubTerms">
          <div className="ClubTerms-container">

            {
              terms.map((term) => (
                <div key={term.description}>
                  <h1><Markup content={term?.title} /></h1>
                  <br />
                  <h2><Markup content={term?.intro} /></h2>
                  <span>
                    <span>
                      <div className="ClubTerms-container-text"><Markup content={term?.description} /></div>
                    </span>

                  </span>
                </div>
              ))
            }

          </div>
        </div>
      )

  );
};

export default ClubTerms;
