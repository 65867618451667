import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { nb, enUS, sv } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  PriceGroupsState,
  FieldTypeState,
  FeeState,
  teamColorState,
  chosenFieldState,
  toggleCart,
  quantityDiscountPackageRules,
  showQuantityDiscountBanner,
} from '../../atom';
import { IField, IFieldType, IRecoilHoc } from '../../Interfaces';
import calculatePrice from '../../utils/CalcPrice';
import useWindowSize from '../../hooks/useWindowSize';
import MobileButtons from '../Mobile/MobileButtons';
import '../../SASS/base/_MobileCart.scss';
import Loading from '../Loading';
import MobileCart from '../Mobile/MobileCart';
import { convertCurrency } from '../../utils/utils';
import RecoilHoc from '../RecoilHoc';

interface ITicketOrderProps extends IRecoilHoc {
  homeTeam: string | undefined;
  awayTeam: string | undefined;
  date: string | undefined;
  matchLoading: boolean | undefined;
  routeTo: string;
  btnDisabledText: string;
  disabled: boolean;
  setToggleMap?: (v: any) => void;
}

const TicketOrder = ({
  homeTeam,
  awayTeam,
  date,
  matchLoading,
  routeTo,
  btnDisabledText,
  disabled,
  setToggleMap,
  currentTeamInfo: { currency },
}: ITicketOrderProps) => {
  const { team, matchId } = useParams<string>();
  const [priceGroups] = useRecoilState(PriceGroupsState);
  const [fieldTypes] = useRecoilState<IFieldType[]>(FieldTypeState);
  const [chosenField] = useRecoilState<IField | undefined>(chosenFieldState);
  const [fees] = useRecoilState(FeeState);
  const [teamColor] = useRecoilState(teamColorState);

  const { width } = useWindowSize();

  const matchedChosenTicketPackage = useRecoilValue(quantityDiscountPackageRules);
  const showQuantityDiscountBannerState = useRecoilValue(showQuantityDiscountBanner);

  const { t } = useTranslation();
  let cheapestFieldType: IFieldType | undefined;
  fieldTypes.forEach((fieldType) => {
    const price = Number(fieldType.price);

    if (
      price
      && (cheapestFieldType === undefined
        || Number(cheapestFieldType.price) > price)
    ) {
      cheapestFieldType = fieldType;
    }
  });
  let price;
  if (cheapestFieldType || chosenField?.field_type) {
    const fieldTypePrice: number = Number(chosenField?.field_type.price)
    || Number(cheapestFieldType?.price);
    price = calculatePrice(
      priceGroups,
      fieldTypePrice,
      fees,
      undefined,
      showQuantityDiscountBannerState,
      matchedChosenTicketPackage?.rulePrice,
    );
  }

  const [_toggleCart] = useRecoilState(toggleCart);

  if (_toggleCart === true) {
    document.body.style.overflowY = 'hidden';
  }

  const locales: any = { nb, enUS, sv };

  const startsAt = date
    && format(parseISO(date), 'EEEE dd. MMMM HH:mm', {
      locale: locales[i18n.use(LanguageDetector).language],
    });

  return width >= 1300 ? (
    <div className="TicketOrder">
      {disabled === true ? (
        <button className="TicketOrder-btn NONE" type="button" disabled>
          {btnDisabledText}
        </button>
      ) : (
        <Link
          to={`/${team}/${matchId}${routeTo}`}
          className="TicketOrder-btn ОК"
          style={{ backgroundColor: `${teamColor}` }}
        >
          {t('TicketOrder.BtnOK')}
        </Link>
      )}

      <div className="TicketOrder-userOrder">
        {!matchLoading ? (
          <>
            <h1 className="TicketOrder-userOrder--title">
              {t('TicketOrder.OrderTitle')}
            </h1>
            <p className="TicketOrder-userOrder--teams">
              {homeTeam}
              {' '}
              -
              {' '}
              {awayTeam}
            </p>
            <p
              className="TicketOrder-userOrder--date"
              style={{ textTransform: 'capitalize' }}
            >
              {startsAt}
            </p>
          </>
        ) : (
          <Loading />
        )}
      </div>
      <div className="TicketOrder-sum">
        <p className="TicketOrder-sum--quantityTitle">
          {t('TicketOrder.OrderQuantityTitle')}
        </p>
        {priceGroups
          .filter((priceGroup) => priceGroup.amount > 0)
          .map((priceGroup: any, index: any, arr: any) => {
            // Get last element
            if (arr.length - 1 === index) {
              return (
                <p className="TicketOrder-sum--quantity" key={priceGroup.title}>
                  {priceGroup.amount}
                  {' '}
                  {priceGroup.title}
                </p>
              );
            }

            return (
              <p className="TicketOrder-sum--quantity" key={priceGroup.title}>
                {priceGroup.amount}
                {' '}
                {priceGroup.title}
              </p>
            );
          })}
      </div>

      <div className="TicketOrder-price">
        <p className="TicketOrder-price--title">
          {t('TicketOrder.OrderPriceTitle')}
        </p>
        <p className="TicketOrder-price--pricing">
          {price ? `${convertCurrency(currency)} ${price?.toFixed?.(0)},-` : '0,-'}
        </p>
      </div>
    </div>
  ) : (
    <>
      {disabled === true ? (
        <button className="TicketOrderMobile-btn NONE" type="button" disabled>
          {btnDisabledText}
        </button>
      ) : (
        <Link
          to={`/${team}/${matchId}${routeTo}`}
          className="TicketOrderMobile-btn ОК"
          style={{ backgroundColor: `${teamColor}` }}
        >
          {t('TicketOrder.BtnOK')}
        </Link>
      )}
      <MobileButtons price={price} setToggleMap={setToggleMap} />
      <MobileCart />
    </>
  );
};

TicketOrder.defaultProps = {
  setToggleMap: undefined,
};

export default RecoilHoc(TicketOrder);
