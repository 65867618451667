import '../SASS/base/PaymentPage/_Payment.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Summary from '../components/Payment/Summary';
import PayOptions from '../components/Payment/PayOptions';
import LeftBox from '../components/LeftBox';
import {
  chosenFieldState,
  chosenRowState,
  chosenSeatState,
  chosenTicketState,
  currentTeamStripeKey,
} from '../atom';

const Payment = () => {
  const { team, matchId, transactionId } = useParams();
  const { t } = useTranslation();
  const [teamStripe] = useRecoilState(currentTeamStripeKey);
  const publicKey = teamStripe;
  let stripePromise = null;
  const [receiptLoading, setReceiptLoading] = useState<boolean | null>(null);

  const [chosenPriceGroup] = useRecoilValue(chosenTicketState);
  const [chosenSeat] = useRecoilValue(chosenSeatState);
  const chosenRow = useRecoilValue(chosenRowState);
  const chosenField = useRecoilValue(chosenFieldState);

  if (teamStripe !== '') {
    stripePromise = loadStripe(String(publicKey));
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (chosenField?.field_type.is_unmarked) {
      if (!chosenPriceGroup) {
        return navigate(`/${team}/${matchId}/tickets`);
      }
    } else if (!chosenPriceGroup || !chosenField) {
      return navigate(`/${team}/${matchId}/tickets`);
    }

    return undefined;
  }, [!chosenPriceGroup, !chosenSeat, !chosenRow, !teamStripe]);

  return (
    <div className="payment">
      <div className="payment-container">
        <div className="payment-container_imgOverlay">
          <img
            src={`${process.env.PUBLIC_URL}/imgs/never-offside.jpg`}
            className="payment-container_imgOverlay--img"
            alt="background"
          />
        </div>

        <div className="Tickets-container_leftBox">
          <LeftBox
            siteTitle={`5. ${t('TicketNavigator.TitlePayment')}`}
            chosenField={undefined}
            chosenRow={undefined}
            teamParam={team}
            matchIdParam={matchId}
            bottomSectionClassCategory="payment"
            component={(
              <>
                <h1 className="payment-container_leftBox-bottomSection--title">
                  {t('PaymentPage.OrderTitle')}
                </h1>
                <Summary />
                <span className="border" />

                {teamStripe.length > 0 && publicKey !== '' ? (
                  <Elements stripe={stripePromise}>
                    <PayOptions
                      team={team}
                      matchId={matchId}
                      setReceiptLoading={setReceiptLoading}
                      receiptLoading={receiptLoading}
                      transactionId={transactionId}
                    />
                  </Elements>
                ) : (
                  <PayOptions
                    team={team}
                    matchId={matchId}
                    setReceiptLoading={setReceiptLoading}
                    receiptLoading={receiptLoading}
                    transactionId={transactionId}
                  />
                )}
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Payment;
