import '../../SASS/base/FieldPage/_Fields.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useState } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useTransition, animated } from 'react-spring';
import { useNavigate, useParams } from 'react-router';
import {
  IField, IFieldType, IPriceGroup, IRecoilHoc,
} from '../../Interfaces';
import {
  chosenFieldState,
  FeeState,
  chosenFieldIdState,
  PriceGroupsState,
  chosenTicketState,
  quantityDiscountPackageRules,
  showQuantityDiscountBanner,
} from '../../atom';
import calculatePrice from '../../utils/CalcPrice';
import { convertCurrency } from '../../utils/utils';
import RecoilHoc from '../RecoilHoc';

interface IDropDownProps extends IRecoilHoc {
  field: IField;
  fieldType: IFieldType;
}

const Dropdown = ({ field, fieldType, currentTeamInfo: { currency } }: IDropDownProps) => {
  const { team, matchId } = useParams<string>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [priceGroups] = useRecoilState(PriceGroupsState);
  const [, setChosenField] = useRecoilState<IField | undefined>(
    chosenFieldState,
  );
  const [, setChosenFieldId] = useRecoilState<number>(chosenFieldIdState);
  const [hover, setHover] = useState<boolean>(false);
  const [fees] = useRecoilState(FeeState);
  const [chosenTickets] = useRecoilState(chosenTicketState);

  const transition = useTransition(hover, {
    config: { duration: 100 },
    from: { x: 20, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    leave: { x: -20, opacity: 0 },
  });

  const matchedChosenTicketPackage = useRecoilValue(quantityDiscountPackageRules);
  const showQuantityDiscountBannerState = useRecoilValue(showQuantityDiscountBanner);

  const price = calculatePrice(
    priceGroups,
    Number(fieldType.price),
    fees,
    undefined,
    showQuantityDiscountBannerState,
    matchedChosenTicketPackage?.rulePrice,
  );

  const setUserField = () => {
    if (fieldType.is_unmarked) {
      setChosenField(field);
      setChosenFieldId(field.id);
      navigate(`/${team}/${matchId}/payment`);
    } else {
      setChosenField(field);
      setChosenFieldId(field.id);
      navigate(`/${team}/${matchId}/rows`);
    }
  };

  const fieldIsDiabled = field.buyable_websale === 0;

  const fieldIsAvailabeWithPriceGroups = !priceGroups
    .filter((priceGroup) => priceGroup.amount > 0)
    .every((priceGroup) => priceGroup.availableFields.includes(field.id));

  return (
    <button
      className="dropDown_fieldCard"
      type="button"
      key={field.id}
      disabled={fieldIsAvailabeWithPriceGroups}
      onClick={setUserField}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {field.overview_image !== null && (
        <div className="dropDown_fieldCard--picture">
          <img
            className="dropDown_fieldCard--picture_photo"
            src={field.overview_image?.url}
            alt="Stadion overview"
          />
        </div>
      )}
      {!fieldIsAvailabeWithPriceGroups ? (
        <div
          className="dropDown_fieldCard--cardInfo"
          style={
            field.overview_image !== null
              ? { padding: '0 1.5rem', justifyContent: 'center' }
              : { padding: '1.5rem' }
          }
        >
          <h1 className="dropDown_fieldCard--cardInfo-title">
            {t('FieldsDropDown.FieldTitle')}
            {' '}
            {field.name}
          </h1>
          <p className="dropDown_fieldCard--cardInfo-seatsLeft">
            <span>&#8226;</span>
            {field.availableSeats}
            {' '}
            {t('FieldsDropDown.SeatsLeftTitle')}
          </p>
          <p className="dropDown_fieldCard--cardInfo-totalPrice">
            {t('FieldsDropDown.TotalPriceTitle', { currency: convertCurrency(currency) })}
            {' '}
            {price.toFixed(2)}
            ,-
          </p>
          {transition((style, item) => (item ? (
            <animated.div style={style} className="arrowContainer">
              <RiArrowRightSLine />
            </animated.div>
          ) : (
            ''
          )))}
        </div>
      ) : (
        <div
          className="dropDown_fieldCard--cardInfo notAvailable"
          style={
            field.overview_image !== null
              ? { gridColumn: '2 / 2', padding: '0 1.5rem' }
              : { gridColumn: '1 / -1', padding: '1.5rem' }
          }
        >
          <h1 className="dropDown_fieldCard--cardInfo-title notAvailableTitle">
            {t('FieldsDropDown.FieldIsNotAvailableWith', {
              field: field.name,
            })}
            &nbsp;
            {chosenTickets.map((ticket: IPriceGroup, index: any, arr: any) => {
              if (arr.length - 1 === index) {
                return ticket.title.trim();
              }

              const commaTicket = `${ticket.title.trim()}, `;
              return commaTicket;
            })}
          </h1>
        </div>
      )}
    </button>
  );
};

export default RecoilHoc(Dropdown);
