/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ITeam, IMatch } from '../Interfaces';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import '../SASS/base/_TeamsPage.scss';
import '../fonts/Gellix-Regular.otf';
import MatchCard from '../components/MatchCard';
import api from '../api/api';
import Loading from '../components/Loading';
import { AuthState, UserTeams } from '../atom';
import AcceptTeamRegistration from '../components/AcceptTeamRegistration';

const TeamsPage = () => {
  const { team } = useParams<string>();
  const [currentTeam, setCurrentTeam] = useState<ITeam | null>(null);
  const [loading, setLoading] = useState(true);
  const recoilUser = useRecoilValue(AuthState);
  const userTeams = useRecoilValue(UserTeams);

  const currentTeamName = currentTeam?.name || '';
  const currentTeamShortname = currentTeam?.shortname?.toLowerCase() || '';
  const [matches, setMatches] = useState<IMatch[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchTeamAndMatches = async () => {
      try {
        setLoading(true);
        const [foundTeam, matchItems] = await Promise.all([
          api().teams.getOneTeam(team || ''),
          api().matches.getMatches(team || ''),
        ]);
        setMatches(matchItems);
        setCurrentTeam(foundTeam);
      } catch (err) {
        let errMessage = t('Errors.InternalError');
        if (err instanceof Error) {
          errMessage = err.message;
        }
        toast.error(errMessage);
      } finally {
        setLoading(false);
      }
    };
    fetchTeamAndMatches();
  }, [team]);
  const headerStyle = {
    backgroundImage: 'url(/imgs/never-offside.jpg)',
  };

  return (
    <>
      {recoilUser.isLoggedIn === true && !userTeams?.includes(String(team)) && (
        <AcceptTeamRegistration team={team} fullTeamName={currentTeamName} />
      )}
      <div className="TeamsPage">
        <div className="TeamsPage-container">
          <header className="TeamsPage-container_banner" style={headerStyle}>
            {!loading && currentTeam && (
              <div className="TeamsPage-container_banner-titleWrap">
                <h1 className="TeamsPage-container_banner-titleWrap--title">
                  {`${t('TeamsPage.HeaderTitle')} ${currentTeamName}`}
                </h1>
              </div>
            )}
          </header>
          <div className="TeamsPage-container_matches">
            <div className="TeamsPage-container_matches-header">
              <h1>{t('TeamsPage.UnderTitle')}</h1>
            </div>
          </div>
          {!loading ? (
            <>
              {matches.length ? (
                <div className="TeamsPage-container_matchList">
                  {userTeams?.includes(String(team)) ? (
                    matches.map((match: IMatch) => (
                      <MatchCard
                        currentTeamShortname={currentTeamShortname}
                        match={match}
                        key={match.id}
                      />
                    ))
                  ) : !userTeams?.includes(String(team))
                    && recoilUser.isLoggedIn === true ? (
                      <h1 style={{ textAlign: 'center' }}>
                        {t('TeamsPage.NoIncludesTeam')}
                      </h1>
                    ) : recoilUser.isLoggedIn === false ? (
                      matches.map((match: IMatch) => (
                        <MatchCard
                          currentTeamShortname={currentTeamShortname}
                          match={match}
                          key={match.id}
                        />
                      ))
                    ) : (
                      ''
                    )}
                </div>
              ) : (
                <div
                  className="TeamsPage-container_noResult"
                  style={{
                    margin: '10rem auto',
                  }}
                >
                  <h1 className="TeamsPage-container_noResult--text">
                    {t('NotFound.NoMatch')}
                  </h1>
                  <Link to="/" className="TeamsPage-container_noResult--link">
                    {t('NotFound.GoBack')}
                  </Link>
                </div>
              )}
            </>
          ) : (
            <Loading />
          )}

          <div className="TeamsPage-container_terms">
            <Link
              target="_blank"
              to={`/${team}/terms_of_sale`}
              className="TeamsPage-container_terms-saleTerms"
            >
              {t('Terms.SaleTermsTitle')}
            </Link>

            <h1>|</h1>

            <Link
              target="_blank"
              to={`/${team}/terms`}
              className="TeamsPage-container_terms-terms"
            >
              {t('Terms.TermsTitle')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamsPage;
