/* eslint-disable no-nested-ternary */
import '../SASS/base/SeatPage/_Seats.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import api from '../api/api';
import {
  chosenRowState,
  seatsState,
  seatLoading,
  matchState,
  ticketOrderLoading,
  totalTicketState,
  chosenSeatQuantity,
  chosenFieldState,
  fieldImage,
  chosenTicketState,
  chosenSeatState,
} from '../atom';
import TicketOrder from '../components/Tickets/TicketOrder';
import {
  IField, IMatch, ISeat,
} from '../Interfaces';
import SeatCard from '../components/Seats/SeatCard';
import LeftBox from '../components/LeftBox';
import MobileMapModal from '../components/Mobile/MobileMapModal';
import useWindowSize from '../hooks/useWindowSize';
import useAuth from '../hooks/useAuth';
import Loading from '../components/Loading';
import Sleep from '../utils/Sleep';

const Seats = () => {
  const [row] = useRecoilState(chosenRowState);
  const [seats, setSeats] = useRecoilState<ISeat[]>(seatsState);
  const [_seatLoading, setSeatLoading] = useRecoilState(seatLoading);
  const [match] = useRecoilState<IMatch | undefined>(matchState);
  const [matchLoading] = useRecoilState<boolean>(ticketOrderLoading);
  const [totalTickets] = useRecoilState(totalTicketState);
  const [_chosenSeatQuantity, setChosenSeatQuantity] = useRecoilState(chosenSeatQuantity);
  const [chosenField] = useRecoilState<IField | undefined>(chosenFieldState);
  const [_fieldImage] = useRecoilState(fieldImage);

  const { team, matchId } = useParams<string>();
  const [toggleMap, setToggleMap] = useState<boolean>(false);

  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [chosenPriceGroup] = useRecoilValue(chosenTicketState);
  const chosenRow = useRecoilValue(chosenRowState);
  const navigate = useNavigate();
  const [seatValue, setSeatValue] = useRecoilState(chosenSeatState);

  useEffect(() => {
    setSeatValue([]);
    setChosenSeatQuantity(0);
    if (!chosenPriceGroup || !chosenRow || !chosenField) {
      return navigate(`/${team}/${matchId}/tickets`, { replace: true });
    }

    return undefined;
  }, []);

  useEffect(() => {
    if (team && matchId) {
      const fetchSeats = async () => {
        try {
          setSeatLoading(true);
          Sleep(5000);
          const seatRes: ISeat[] | any = await api().matches.getSeats(
            team,
            matchId,
            Number(row?.id),
          );

          if (!seatRes?.statusCode?.toString().startsWith(40)) {
            const _seats: ISeat[] = [];

            await seatRes?.forEach((seat: ISeat) => {
              _seats.push({
                ...seat,
                chosenSeat: 0,
              });
            });

            setSeats(_seats);
          } else {
            toast.error(seatRes?.message);
          }
        } catch (err) {
          if (err instanceof Error) {
            toast.error(err.message);
          }
        } finally {
          setSeatLoading(false);
        }
      };
      fetchSeats();
    }
  }, [row?.id, team, matchId]);

  return (
    <div className="seats">
      <div className="seats-container">
        {chosenField?.overview_image !== null ? (
          width > 680 ? (
            <img
              src={chosenField?.overview_image.url}
              alt="Overview of current row"
              className="seats-container_img"
            />
          ) : (
            <MobileMapModal
              toggleMap={toggleMap}
              setToggleMap={setToggleMap}
              imageA={chosenField?.overview_image.url}
              imageB={_fieldImage}
            />
          )
        ) : (
          <img
            src={_fieldImage}
            alt="Overview of current row"
            className="rows-container_img"
          />
        )}

        <div className="Tickets-container_leftBox">
          <LeftBox
            siteTitle={`4. ${
              totalTickets <= 1
                ? t('TicketNavigator.TitleSeatOne')
                : t('TicketNavigator.TitleSeatMultiple')
            } 
              (${_chosenSeatQuantity} / ${totalTickets})`}
            chosenField={`Felt ${chosenField?.name}`}
            chosenRow={`Rad ${row?.number}`}
            teamParam={team}
            matchIdParam={matchId}
            bottomSectionClassCategory="seats"
            component={
              seats
              && (!_seatLoading ? (
                seats.map((seat: ISeat) => (
                  <SeatCard key={seat.seats_id} wholeSeat={seat} />
                ))
              ) : (
                <Loading />
              ))
            }
          />
        </div>

        <div className="rows-container_bottomBox">
          <TicketOrder
            matchLoading={matchLoading}
            homeTeam={match?.home_team.name}
            awayTeam={match?.away_team.name}
            date={match?.match_starts_at}
            routeTo="/payment"
            btnDisabledText={t('TicketOrder.BtnDisabledSeat')}
            disabled={totalTickets !== _chosenSeatQuantity}
            setToggleMap={setToggleMap}
          />
        </div>
      </div>
    </div>
  );
};

export default Seats;
