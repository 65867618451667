import '../../SASS/base/SeatPage/_Seats.scss';
import { AiOutlineCheck } from 'react-icons/ai';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { ISeat } from '../../Interfaces';
import {
  chosenSeatState, seatsState, chosenSeatQuantity, totalTicketState,
} from '../../atom';

interface ISeatProps {
    wholeSeat: ISeat,
}

const SeatCard = ({ wholeSeat }: ISeatProps) => {
  const [chosenSeat, setChosenSeat] = useRecoilState<ISeat[]>(chosenSeatState);
  const [_chosenSeatQuantity, setChosenSeatQuantity] = useRecoilState<number>(chosenSeatQuantity);
  const [seats, setSeats] = useRecoilState<ISeat[]>(seatsState);
  const [totalTickets] = useRecoilState(totalTicketState);

  const { t } = useTranslation();

  const selectSeatCard = (seatId: number) => {
    const tmpSeat: ISeat[] = JSON.parse(JSON.stringify([...seats]));

    const seatIndex = tmpSeat.findIndex(
      (_seat: ISeat) => _seat.seats_id === seatId,
    );

    tmpSeat[seatIndex].chosenSeat = 1 - tmpSeat[seatIndex].chosenSeat;

    setSeats(tmpSeat);

    setChosenSeatQuantity(tmpSeat
      .reduce((acc: number, seatChosen: ISeat) => seatChosen.chosenSeat + acc, 0));

    if (tmpSeat[seatIndex].chosenSeat === 1) {
      setChosenSeat([...chosenSeat, tmpSeat[seatIndex]]);
    } else {
      setChosenSeat(chosenSeat.filter(
        (seat) => seat.seats_id !== seatId,
      ));
    }
  };

  if (_chosenSeatQuantity !== totalTickets) {
    return (
      <button
        key={wholeSeat.seats_seat_id}
        type="button"
        className={wholeSeat.chosenSeat === 1 ? 'seatCard chosen' : 'seatCard'}
        onClick={() => selectSeatCard(wholeSeat.seats_id)}
      >
        <h1 className="seatCard-title">
          {t('SeatPage.SingleSeat')}
          {' '}
          {wholeSeat.seats_seat_id}
        </h1>

        {wholeSeat.chosenSeat === 1 ? (
          <div className="checkContainer">
            <AiOutlineCheck />
          </div>
        ) : (
          <div className="circleContainer">
            <RiCheckboxBlankCircleFill />
          </div>
        )}
      </button>
    );
  }
  return (
    <button
      key={wholeSeat.seats_seat_id}
      type="button"
      className={wholeSeat.chosenSeat === 1 ? 'seatCard chosen' : 'seatCard'}
      disabled={wholeSeat.chosenSeat !== 1}
      onClick={() => selectSeatCard(wholeSeat.seats_id)}
    >
      <h1 className="seatCard-title">
        {t('SeatPage.SingleSeat')}
        {' '}
        {wholeSeat.seats_seat_id}
      </h1>
      {wholeSeat.chosenSeat === 1 ? (
        <div className="checkContainer">
          <AiOutlineCheck />
        </div>
      ) : (
        <div className="circleContainer">
          <RiCheckboxBlankCircleFill />
        </div>
      )}
    </button>
  );
};

export default SeatCard;
