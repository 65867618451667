import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

interface ICorrectCredentialsProps {
  correctNum: boolean;
  setSMSCode: React.Dispatch<React.SetStateAction<string>>;
  SMSCode: string;
  resendCode: () => void;
  handleSubmit: () => void;
  setResendCode: React.Dispatch<React.SetStateAction<boolean>>;
}

const CorrectCredentials = ({
  correctNum,
  SMSCode,
  setSMSCode,
  resendCode,
  handleSubmit,
  setResendCode,
}: ICorrectCredentialsProps) => {
  const { t } = useTranslation();

  const { team } = useParams<string>();
  if (correctNum) {
    return (
      <div className="Login-container_Form_verificationWrap">
        <p className="Login-container_Form_verificationWrap--verificationText">
          {t('LogIn.VerificationCode')}
        </p>
        <div className="Login-container_Form_verificationWrap--inputWrap">
          <input
            type="number"
            value={SMSCode}
            required
            name="countryCode"
            id="countryCode"
            step="1"
            className="Login-container_Form_verificationWrap--inputWrap-verificationInput"
            onChange={(e) => setSMSCode(e.target.value)}
          />
          <button
            onClick={() => {
              resendCode();
              setResendCode(true);
            }}
            type="button"
            className="Login-container_Form_verificationWrap--inputWrap-sendNewBtn"
          >

            {t('LogIn.NewCode')}
          </button>
          <button
            type="submit"
            disabled={SMSCode.length < 4}
            className={
              SMSCode.length < 4
                ? 'Login-container_Form-continue_btnNONE buttonDisabled'
                : 'Login-container_Form-continue_btnOK buttonPrimary'
                }
            onClick={handleSubmit}
          >
            {SMSCode.length < 4
              ? t('LogIn.NoLengthCodeText')
              : t('LogIn.ValidCodeText')}
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default CorrectCredentials;
