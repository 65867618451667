import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { IDiscount, IMatchedChosenTicketPackage, IRecoilHoc } from '../../../Interfaces';
import { convertCurrency } from '../../../utils/utils';
import RecoilHoc from '../../RecoilHoc';

interface IDiscountSummaryProps extends IRecoilHoc {
    setShowAddDiscount: (arg0: boolean) => void,
    setShowCampaign: (arg0: boolean) => void,
    setDiscountRes: (arg0: undefined) => void
    discountRes: IDiscount | undefined,
    showQuantityDiscountBannerState: boolean,
    matchedChosenTicketPackage: IMatchedChosenTicketPackage | undefined,
  }

const DiscountSummary = ({
  setShowAddDiscount, setDiscountRes,
  setShowCampaign, discountRes,
  currentTeamInfo: { currency },
  showQuantityDiscountBannerState,
  matchedChosenTicketPackage,
}: IDiscountSummaryProps) => {
  const { t } = useTranslation();
  return (
    <div className="summary-campaign">
      <h1 className="summary-campaign_title">{t('PaymentPage.CampaignSectionTitle')}</h1>
      {discountRes && discountRes.active && (
        <button
          type="button"
          className="summary-campaign_removeBtn"
          onClick={() => {
            setShowAddDiscount(true);
            setDiscountRes(undefined);
            setShowCampaign(false);
          }}
        >
          <MdClose />
          {' '}
          {t('PaymentPage.Close')}
        </button>
      )}
      {discountRes && discountRes.active && (
        <>
          <p className="summary-campaign_discountTitle">{discountRes?.name}</p>
          {discountRes?.percentage === 1 ? (
            <span className="summary-campaign_discountPrice">
              -
              {discountRes?.discount}
              %
            </span>
          ) : (
            <span className="summary-campaign_discountPrice">
              {`-${convertCurrency(currency)} ${discountRes?.discount},-`}
            </span>
          )}
        </>

      )}
      {showQuantityDiscountBannerState && matchedChosenTicketPackage && (
      <>
        <p className="summary-campaign_discountTitle" style={{ gridColumn: '1 / 1', gridRow: '2 / 2' }}>{matchedChosenTicketPackage?.ruleName}</p>
        <span className="summary-campaign_discountPrice" style={{ gridColumn: '2 / 2', gridRow: '2 / 2' }}>
          {`-${convertCurrency(currency)} ${matchedChosenTicketPackage?.rulePrice},-`}
        </span>
      </>
      )}
    </div>
  );
};

export default RecoilHoc(DiscountSummary);
