import { atom } from 'recoil';

import {
  IPriceGroup, IFieldType, IFee,
  IField, IRow, IMatch,
  ISeat, IAuth, IDiscount, ITeam, IClubConfig, IQuantityPackageRule, IMatchedChosenTicketPackage,
} from './Interfaces';

const AuthState = atom({
  key: 'auth',
  default: {
    isLoggedIn: 'loading',
  } as IAuth,
});

const AuthToken = atom({
  key: 'token',
  default: '',
});

const longToken = atom({
  key: 'longToken',
  default: [] as string[],
});

// Fetched states

const SelectedState = atom({
  key: 'selected',
  default: {
    field: null,
  },
});

const PriceGroupsState = atom({
  key: 'priceGroups',
  default: [] as IPriceGroup[],
});

const FieldTypeState = atom({
  key: 'fieldType',
  default: [] as IFieldType[],
});

const RowsState = atom({
  key: 'row',
  default: [] as IRow[],
});

const seatsState = atom({
  key: 'seat',
  default: [] as ISeat[],
});

const matchState = atom({
  key: 'match',
  default: undefined as IMatch | undefined,
});

const FeeState = atom({
  key: 'Fee',
  default: [] as IFee[],
});

const totalTicketState = atom({
  key: 'totalTicket',
  default: 0 as number,
});

const discountState = atom({
  key: 'discount',
  default: undefined as IDiscount | undefined,
});

// Chosen states

const chosenTicketState = atom({
  key: 'chosenTickets',
  default: [] as any[],
});

const totalTicketPrice = atom({
  key: 'totalTicketPrice',
  default: 0,
});

const chosenFieldState = atom({
  key: 'chosenField',
  default: undefined as IField | undefined,
});

const chosenFieldIdState = atom({
  key: 'chosenFieldId',
  default: 0 as number,
});

const chosenRowState = atom({
  key: 'chosenRow',
  default: undefined as IRow | undefined,
});

const chosenRowIdState = atom({
  key: 'chosenRowId',
  default: 0 as number,
});

const chosenSeatState = atom({
  key: 'chosenSeat',
  default: [] as ISeat[] | any[],
});

const chosenSeatQuantity = atom({
  key: 'chosenSeatQuantity',
  default: 0 as number,
});

const teamColorState = atom({
  key: 'teamColor',
  default: [''] as string[],
});

// Loaders
const ticketOrderLoading = atom({
  key: 'ticketOrderLoading',
  default: true as boolean,
});

const fieldLoading = atom({
  key: 'fieldLoading',
  default: true as boolean,
});

const rowLoading = atom({
  key: 'rowLoading',
  default: true as boolean,
});

const seatLoading = atom({
  key: 'seatLoading',
  default: true as boolean,
});

const toggleCart = atom({
  key: 'toggleCart',
  default: false as boolean,
});

const fieldImage = atom({
  key: 'fieldImage',
  default: '' as string,
});

const currentTeamStripeKey = atom({
  key: 'stripeKey',
  default: '' as string,
});

const UserTeams = atom({
  key: 'userTeams',
  default: ['loading'] as any[],
});
const CurrentTeamInfo = atom({
  key: 'currentTeamInfo',
  default: {} as IClubConfig|undefined,
});

const stripeSuccessRetry = atom({
  key: 'stripeSuccessRetry',
  default: false as boolean,
});

const quantityDiscountPackageRules = atom({
  key: 'quantityDiscountPackageRules',
  default: undefined as IMatchedChosenTicketPackage | undefined,
});

const showQuantityDiscountBanner = atom({
  key: 'showQuantityDiscountBanner',
  default: false as boolean,
});

export {
  AuthState, SelectedState, PriceGroupsState,
  chosenTicketState, totalTicketPrice, ticketOrderLoading,
  chosenFieldState, FieldTypeState, fieldLoading,
  FeeState, RowsState, rowLoading,
  matchState, chosenRowState, seatsState,
  seatLoading, chosenSeatState, totalTicketState,
  chosenSeatQuantity, AuthToken, longToken,
  chosenFieldIdState, chosenRowIdState, teamColorState,
  toggleCart, fieldImage, discountState,
  currentTeamStripeKey, UserTeams,
  CurrentTeamInfo, stripeSuccessRetry,
  quantityDiscountPackageRules, showQuantityDiscountBanner,
};
