import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import api from '../api/api';
import { stripeSuccessRetry } from '../atom';
import LeftBox from '../components/LeftBox';
import Loading from '../components/Loading';
import { auth } from '../firebase';
import '../SASS/base/CheckingPayment/_CheckingPayment.scss';
import useTransactionId from '../hooks/useTransactionId';

const CheckingPayment = () => {
  const { team, matchId } = useParams();
  const location = useLocation();
  const stripeSuccessRetryPayment = useRecoilValue(stripeSuccessRetry);

  const navigate = useNavigate();
  const transactionId = useTransactionId(location);
  const { t } = useTranslation();

  useEffect(() => {
    if (team) {
      const verifyPayment = async () => {
        if (!stripeSuccessRetryPayment && transactionId) {
          try {
            const verifyPaymentRes = await api().payments.verifyPayment(
              team,
              transactionId,
            );

            if (verifyPaymentRes.status === 'Payment confirmed') {
              toast.info(`${t('ReceiptPage.EmailSentToast')} ${String(auth?.currentUser?.email)}`);
              navigate(`/${team}/${matchId}/transaction?${transactionId}`, { replace: true });
            }

            return verifyPaymentRes.status === 'Payment confirmed';
          } catch (error) {
            toast.error("Payment didn't go through properly, please try again");
            navigate(`/${team}/${matchId}/unpaid?${transactionId}`, { replace: true });
          }
        }
        return true;
      };
      verifyPayment();
    }
  }, [team]);

  return (
    <div className="checkingPayment">
      <div className="checkingPayment-container">
        <div className="checkingPayment-container_imgOverlay">
          <img
            src={`${process.env.PUBLIC_URL}/imgs/never-offside.jpg`}
            className="checkingPayment-container_imgOverlay--img"
            alt="Background"
          />
        </div>

        <div className="checkingPayment-container_leftBox">
          <LeftBox
            siteTitle=""
            chosenField={undefined}
            chosenRow={undefined}
            teamParam={team}
            matchIdParam={matchId}
            bottomSectionClassCategory="checkingPayment"
            component={(
              <div style={{
                margin: '5.5rem auto 0 auto', textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '3rem',
              }}
              >
                <Loading />
                <h1>Please wait, checking payment...</h1>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckingPayment;
