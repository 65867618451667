import {
  gql, useMutation,
} from '@apollo/client';

const afterSignUpMutation = gql`
mutation SignUp(
  $team: String!
  $firstName: String
  $lastName: String
  $email: String
  $password: String
) {
  signUp(
    team: $team
    firstname: $firstName
    lastname: $lastName
    email: $email
    password: $password
  )
}
`;

const assignUserToTeamMutation = gql`
  mutation SignUp($team: String!, $email: String) {
    signUp(team: $team, email: $email)
  }
`;

const userServiceInfoQuery = gql`
  query GetUsers($whereClause: UserWhereInput!) {
    users(where: $whereClause) {
      id
      teams(where: {deleted: {equals: false}}) {
        monolithUserId
        team {
          name
        }
      }
    }
  }
`;
const termsQuery = gql`
  query {
    terms {
      type,
      title,
      intro,
      description
    }
  }
`;

const updateUserInfoMutation = gql`
  mutation UpdateUser(
    $where: UserWhereUniqueInput!
    $data: UserUpdateInput!
  ) {
    updateUser(where: $where, data: $data) {
      gender
      dateOfBirth
      zip
      firstname
      lastname
      email
    }
  }
`;
const sendPasswordResetMutation = gql`
  mutation SendResetCode($email: String!) {
    sendUserPasswordResetLink(email: $email)
  }
`;
const redeemPasswordResetMutation = gql`
  mutation ResetPass($email: String!, $token: String!, $password: String!) {
    redeemUserPasswordResetToken(
      email: $email
      token: $token
      password: $password
    ) {
      code
      message
    }
  }
`;
const useGql = () => {
  const [afterUserSignup, {
    data: afterSignUpData,
    error: afterUserSignUpError,
  }] = useMutation(afterSignUpMutation);

  const [assignUserToTeam, {
    data: assignUserToTeamData,
    loading: assignUserToTeamLoading, error: assignUserToTeamError,
  }] = useMutation(assignUserToTeamMutation);

  return {
    afterUserSignup,
    assignUserToTeam,
    assignUserToTeamData,
    assignUserToTeamError,
    assignUserToTeamLoading,
    afterSignUpData,
    afterUserSignUpError,
    userServiceInfoQuery,
  };
};
export {
  afterSignUpMutation, assignUserToTeamMutation,
  userServiceInfoQuery, updateUserInfoMutation, sendPasswordResetMutation,
  redeemPasswordResetMutation,
  termsQuery,
};
export default useGql;
