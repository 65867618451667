import '../SASS/base/PriceGroupPage/_Tickets.scss';
import { useNavigate, useParams, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  PriceGroupsState,
  ticketOrderLoading,
  FieldTypeState,
  fieldLoading,
  FeeState,
  matchState,
  totalTicketState,
  chosenTicketState,
  currentTeamStripeKey,
  chosenFieldState,
  chosenRowState,
  chosenSeatState,
  CurrentTeamInfo,
  quantityDiscountPackageRules,
  showQuantityDiscountBanner,
} from '../atom';
import TicketOrder from '../components/Tickets/TicketOrder';
import api from '../api/api';
import {
  IMatch,
  IPriceGroup,
  IFieldType,
  IField,
  IFee,
  ITeam,
  IMatchedChosenTicketPackage,
  IQuantityPackageRule,
  ITicket,
} from '../Interfaces';
import TicketChooser from '../components/Tickets/TicketChooser';
import LeftBox from '../components/LeftBox';
import useWindowSize from '../hooks/useWindowSize';
import useAuth from '../hooks/useAuth';
import Loading from '../components/Loading';

const PriceGroup = () => {
  const currentTeamInfo = useRecoilValue(CurrentTeamInfo);
  const { team, matchId } = useParams();
  const [match, setMatch] = useRecoilState<IMatch | undefined>(matchState);

  const [priceGroupLoading, setPriceGroupLoading] = useState<boolean>(false);
  const [matchLoading, setMatchLoading] = useRecoilState<boolean>(ticketOrderLoading);
  const [, setFieldLoading] = useRecoilState(fieldLoading);

  const [priceGroups, setPriceGroups] = useRecoilState(PriceGroupsState);
  const [, setFieldTypes] = useRecoilState(FieldTypeState);
  const [, setResFee] = useRecoilState(FeeState);
  const [totalTickets, setTotalTickets] = useRecoilState(totalTicketState);
  const [chosenTickets, setChosenTickets] = useRecoilState(chosenTicketState);
  const [, setFieldValue] = useRecoilState(chosenFieldState);
  const [, setRowValue] = useRecoilState(chosenRowState);
  const [, setSeatValue] = useRecoilState(chosenSeatState);
  const [_quantityDiscountPackageRules,
    setQuantityDiscountPackageRules] = useState<IQuantityPackageRule[]>([]);

  const [maxTicketAmount] = useState<number>(10);
  const [ticketsDisabledMaxAmount, setTicketsDisabledMaxAmount] = useState<boolean>(false);

  const { width } = useWindowSize();
  const { t } = useTranslation();
  useEffect(() => {
    setFieldValue(undefined);
    setRowValue(undefined);
    setSeatValue([]);

    if (team && matchId) {
      const fetchMatches = async () => {
        try {
          setMatchLoading(true);
          const matchRes = await api().matches.getMatches(team);
          setMatch(
            matchRes.find((_match: IMatch) => _match.id === Number(matchId)),
          );
        } catch (err) {
          const errMessage = 'Noe gikk galt ved henting av kamper';
          if (err instanceof Error) {
            err.message = errMessage;
          }
          toast.error(errMessage);
        } finally {
          setMatchLoading(false);
        }
      };

      const fetchPriceGroups = async () => {
        try {
          setPriceGroupLoading(true);
          setFieldLoading(true);

          const [resFields, resPriceGroups, resFee] = await Promise.all([
            api().matches.getFields(team, matchId),
            api().matches.getPriceGroups(team, matchId),
            api().matches.getFees(team, matchId),
          ]);

          const _fieldTypes: IFieldType[] = [];

          resFields.forEach((field: IField) => {
            if (
              _fieldTypes.some(
                (fieldType: IFieldType) => fieldType.id === field.field_type.id,
              ) === false
            ) {
              _fieldTypes.push({
                ...field.field_type,
                isOpen: false,
                fields: [],
              });
            }

            const fieldFieldTypeIndex = _fieldTypes.findIndex(
              (fieldType2: IFieldType) => fieldType2.id === field.field_type.id,
            );

            _fieldTypes[fieldFieldTypeIndex]?.fields?.push(field);
          });

          let cheapestPrice: Number | undefined;
          _fieldTypes.forEach((fieldType) => {
            const price = Number(fieldType.price);

            if (
              price
              && (cheapestPrice === undefined || cheapestPrice > price)
            ) {
              cheapestPrice = price;
            }
          });

          setFieldTypes(_fieldTypes);
          setPriceGroups(
            resPriceGroups.map((priceGroup: IPriceGroup) => {
              let minPrice = Number(priceGroup.percentage) * 0.01 * Number(cheapestPrice);
              resFee.forEach((fee: IFee) => {
                minPrice *= fee.percentage * 0.01 + 1;
              });
              return {
                ...priceGroup,
                min_price: Math.floor(minPrice),
                amount: 0,
              };
            }),
          );
          setResFee(resFee);
        } catch (err) {
          if (err instanceof Error) {
            toast.error(err.message);
          }
        } finally {
          setPriceGroupLoading(false);
          setFieldLoading(false);
        }
      };

      fetchMatches();
      fetchPriceGroups();

      const fetchQuantityPackages = async () => {
        try {
          const { rules } = await api().payments.packages(team);
          setQuantityDiscountPackageRules(rules.filter(
            (rule: IQuantityPackageRule) => rule.active,
          ));
        } catch (err) {
          if (err instanceof Error) {
            toast.error(err.message);
          }
        }
      };
      fetchQuantityPackages();
    }

    return () => {
      setFieldValue(undefined);
      setRowValue(undefined);
      setSeatValue([]);
      setQuantityDiscountPackageRules([]);
    };
  }, [team, matchId]);

  const updatePriceGroupQuantity = (priceGroupId: number, value: number) => {
    const tmpPriceGroups = JSON.parse(JSON.stringify([...priceGroups]));
    const priceGroupIndex = tmpPriceGroups?.findIndex(
      (priceGroup: IPriceGroup) => priceGroup.id === priceGroupId,
    );

    if (priceGroupIndex === undefined) {
      return;
    }

    tmpPriceGroups[priceGroupIndex].amount += value;

    if (tmpPriceGroups[priceGroupIndex].amount < 0) {
      tmpPriceGroups[priceGroupIndex].amount = 0;
    }

    if (tmpPriceGroups[priceGroupIndex].amount >= maxTicketAmount) {
      tmpPriceGroups[priceGroupIndex].amount = maxTicketAmount;
    }
    setPriceGroups(tmpPriceGroups);

    setTotalTickets(
      tmpPriceGroups.reduce(
        (acc: number, priceGroup: IPriceGroup) => acc + priceGroup.amount,
        0,
      ),
    );
  };

  useEffect(() => {
    if (totalTickets === 10) {
      setTicketsDisabledMaxAmount(true);
    }

    return () => {
      setTicketsDisabledMaxAmount(false);
    };
  }, [totalTickets]);

  useEffect(() => {
    setChosenTickets(
      priceGroups
        .filter((priceGroup) => priceGroup.amount > 0)
        .map((foo: IPriceGroup) => foo),
    );
  }, [totalTickets]);

  const [teamStripe, setTeamStripe] = useRecoilState(currentTeamStripeKey);

  useEffect(() => {
    if (team) {
      const fetchTeamStripe = async () => {
        try {
          const teamRes: ITeam = await api().teams.getOneTeam(team);
          setTeamStripe(teamRes.stripe_publishable_key);
        } catch (err) {
          if (err instanceof Error) {
            toast.error(err.message);
          }
        }
      };
      fetchTeamStripe();
    }
  }, [teamStripe]);

  const setShowQuantityDiscountBannerState = useSetRecoilState(showQuantityDiscountBanner);
  const [matchedChosenTicketPackage,
    setMatchedChosenTicketPackage] = useRecoilState<
    IMatchedChosenTicketPackage | undefined>(quantityDiscountPackageRules);

  useEffect(() => {
    const chosenTicketsIdAndAmount = chosenTickets?.flatMap((ticket) => ({
      itemQuant: ticket.amount || 1 as number,
      itemId: ticket?.id as number,
    }));

    const items = _quantityDiscountPackageRules.map((rule) => ({
      ruleId: rule.id,
      ruleName: rule.package.name,
      ruleDesc: rule.package.description,
      rulePrice: rule.package.price,
      items: rule.items.map((item) => ({
        itemId: item.price_group_id,
        itemQuant: item.quantity,
        itemModifier: item.modifier,
      })),
    }));

    const matchedChosenTicketToItems = items.map((item) => ({
      ...item,
      matchedItems: item.items.filter((item2) => chosenTicketsIdAndAmount.some(
        (chosenTicket) => {
          switch (item2.itemModifier) {
            case 'eq': return chosenTicket.itemId
            === item2.itemId && chosenTicket.itemQuant === item2.itemQuant;
            case 'gteq': return chosenTicket.itemId
            === item2.itemId && chosenTicket.itemQuant >= item2.itemQuant;
            case 'gt': return chosenTicket.itemId === item2.itemId
              && chosenTicket.itemQuant > item2.itemQuant;
            case 'lteq': return chosenTicket.itemId === item2.itemId
              && chosenTicket.itemQuant <= item2.itemQuant;
            case 'lt': return chosenTicket.itemId === item2.itemId
              && chosenTicket.itemQuant < item2.itemQuant;
            default: return false;
          }
        },
      )) as { itemId: number; itemQuant: number }[],
    })).filter((item) => item.items.length
    === item.matchedItems.length);

    if (matchedChosenTicketToItems.length > 0) {
      setMatchedChosenTicketPackage(matchedChosenTicketToItems[0]);
      setShowQuantityDiscountBannerState(true);
    } else {
      setMatchedChosenTicketPackage(undefined);
      setShowQuantityDiscountBannerState(false);
    }
  }, [chosenTickets]);

  return (
    <div className="Tickets">
      <div className="Tickets-container">
        {width > 680 ? (
          <div className="Tickets-container_imgOverlay">
            <img
              src={`${process.env.PUBLIC_URL}/imgs/never-offside.jpg`}
              className="Tickets-container_imgOverlay--img"
              alt="background"
            />
          </div>
        ) : (
          ''
        )}
        <div className="Tickets-container_leftBox">
          <LeftBox
            siteTitle={`1. ${t('TicketNavigator.TitlePriceGroup')}`}
            chosenField={undefined}
            chosenRow={undefined}
            teamParam={team}
            matchIdParam={matchId}
            bottomSectionClassCategory="Tickets"
            component={
              priceGroups
              && (!priceGroupLoading ? (
                priceGroups.map((priceGroup) => (
                  <TicketChooser
                    key={priceGroup.id}
                    title={priceGroup.title}
                    minPrice={priceGroup.min_price}
                    description={priceGroup.description}
                    setAmount={updatePriceGroupQuantity}
                    amount={priceGroup.amount}
                    id={priceGroup.id}
                    ticketsDisabledMaxAmount={ticketsDisabledMaxAmount}
                  />
                ))
              ) : (
                <Loading />
              ))
            }
          />
        </div>
        <div className="Tickets-container_bottomBox">
          <TicketOrder
            matchLoading={matchLoading}
            homeTeam={match?.home_team.name}
            awayTeam={match?.away_team.name}
            date={match?.match_starts_at}
            routeTo="/fields"
            btnDisabledText={t('TicketOrder.BtnDisabledPriceGroup')}
            disabled={
              priceGroups.length === 0
              || priceGroups.some((priceGroup) => priceGroup.amount > 0) === false
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PriceGroup;
