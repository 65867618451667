import { signInWithCustomToken } from 'firebase/auth';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-cycle
import getSymbolFromCurrency from 'currency-symbol-map';
import { auth } from '../firebase';
import api from '../api/api';
import i18n from '../i18next';
import isDev from './isDev';

const t = (key:string):string => i18n.t(key);
export const throwErrorToast = (err: any) => {
  switch (err.code) {
    case 'auth/wrong-password': return toast.error(t('Errors.WrongPassword'));
    case 'auth/user-not-found': return toast.error(t('Errors.NotFound'));
    case 'auth/too-many-requests': return toast.error(t('Errors.TooManyRequests'));
    case 'auth/invalid-email': return toast.error(t('Errors.InvalidMail'));
    case 'auth/email-already-exists': return toast.error(t('Errors.EmailExists'));
    case 'auth/user-disabled': return toast.error(t('Errors.UserDisabled'));
    case 'auth/user-token-expired': return toast.error(t('Errors.TokenExpired'));
    case 'auth/internal-error': return toast.error(t('Errors.InternalError'));
    case 'auth/email-already-in-use': return toast.error(t('Errors.EmailAlreadyInUse'));
    case 'auth/password-too-short': return toast.error(t('Errors.PasswordTooShort'));
    case 'auth/invalid-phone-number': return toast.error('Invalid phone number');
    case 'INVALID_CODE': return toast.error(t('Errors.InvalidVerificationCode'));
    case 'INVALID_PASSWORD': return toast.error(t('Errors.InvalidPassword'));
    case 'auth/something-went-wrong': return toast.error(t('Errors.InternalError'));
    case 'auth/token-not-present': return toast.error(t('Errors.TokenRequired'));
    case 'auth/password-doesnt-match': return toast.error(t('Errors.PasswordDoesNotMatch'));
    case 'TOKEN_EXPIRED': return toast.error(t('Errors.PasswordResetTokenExpired'));
    case 'TOKEN_REDEEMED': return toast.error(t('Errors.TokenAlreadyRedeemed'));
    default: return toast.error(t('Errors.InternalError'));
  }
};
export const fetchCustomClaims = async () => {
  const currFirToken = await auth?.currentUser?.getIdToken() || '';
  const { token: customToken } = await api().authorization.getCustomToken(currFirToken);
  await signInWithCustomToken(auth, customToken);
  const userObject = await auth?.currentUser?.getIdTokenResult();
  const mappedTeamNames = Object.keys(userObject?.claims?.teams || {});
  return mappedTeamNames;
};
export const convertCurrency = (currencyCode:string, capitalize:'FIRST'|'ALL'|'NONE' = 'FIRST') => {
  const symbol = getSymbolFromCurrency(currencyCode) || '';
  switch (capitalize) {
    case 'ALL': return symbol.toUpperCase();
    case 'FIRST': return symbol.charAt(0).toUpperCase() + symbol.slice(1);
    default: return symbol;
  }
};
export const localURL = 'http://localhost:8080/api';
export const IDENTITY_TOOLKIT_URL = 'https://identitytoolkit.googleapis.com/v1';
export const IDENTITY_RELYING_PARTY_URL = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty';
