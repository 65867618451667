/* eslint-disable react/jsx-no-useless-fragment */
import '../SASS/base/PurchasePage/_PurchaseHistory.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { isFuture, isPast, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { StringLiteralType } from 'typescript';
import { useRecoilValue } from 'recoil';
import { IRecoilHoc, IUserTicket } from '../Interfaces';
import api from '../api/api';
import PurchaseHistoryCard from '../components/PurchaseHistoryCard';
import useAuth from '../hooks/useAuth';
import Loading from '../components/Loading';
import { auth } from '../firebase';
import RecoilHoc from '../components/RecoilHoc';
import { CurrentTeamInfo } from '../atom';

interface IActiveButton {
  isActive: boolean;
  action: () => void;
  title: string;
}

enum TAB_NAMES {
  PAST = 'PAST',
  UPCOMMING = 'UPCOMMING',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
}
// eslint-disable-next-line react/prop-types
const ActionButton = ({
  isActive, action, title,
}: IActiveButton) => (
  <button
    type="button"
    className={`PurchaseHistory-container_tickets-button ${
      isActive && 'PurchaseHistory-container_tickets-button_selected'
    }`}
    onClick={action}
  >
    {title}
  </button>
);
const NotFoundCard = () => {
  const { t } = useTranslation();
  return (
    <div
      className="PurchaseHistory-container_noResult"
      style={{ margin: '0 auto' }}
    >
      <h1 className="PurchaseHistory-container_noResult--text">
        {t('NotFound.NoTickets')}
      </h1>
      <Link to="/" className="PurchaseHistory-container_noResult--link">
        {t('NotFound.GoBack')}
      </Link>
    </div>
  );
};
const PurchaseHistory = ({ currentTeamInfo: { club_name } }: IRecoilHoc) => {
  const { team } = useParams<{ team: string }>();
  const { t } = useTranslation();
  const [paidTickets, setPaidTickets] = useState<IUserTicket[]>([]);
  const [unpaidTickets, setUnpaidTickets] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState(TAB_NAMES.UPCOMMING);
  const noTicketsFound = (!paidTickets.length && selectedTab !== TAB_NAMES.ACTION_REQUIRED)
    || (selectedTab === TAB_NAMES.ACTION_REQUIRED && !unpaidTickets.length);
  useEffect(() => {
    if (team) {
      const fetchAllTickets = async () => {
        try {
          setLoading(true);
          const [unpaidTicketsRes, allTickets] = await Promise.all([
            api().tickets.getUnpaidTickets(team),
            api().tickets.getUserTickets(team),
          ]);
          const unpaidIds = Object.keys(unpaidTicketsRes);
          const paidUserTickets = allTickets.filter(
            (userTicket) => !unpaidIds.includes(userTicket.payment.transaction_id.toString()),
          );
          setUnpaidTickets(
            Object.values(unpaidTicketsRes ?? '')
              .flat()
              .filter((ticket: any) => !ticket.assigned_by),
          );
          setPaidTickets(paidUserTickets);
        } catch (err) {
          toast.error(String(err));
        } finally {
          setLoading(false);
        }
      };
      fetchAllTickets();
    }
    return () => {
      setPaidTickets([]);
      setUnpaidTickets([]);
    };
  }, [team, auth?.currentUser]);
  const timeAlignedUserTickets = useMemo(() => {
    let selectedOperation = isPast;
    if (selectedTab === TAB_NAMES.UPCOMMING) {
      selectedOperation = isFuture;
    }
    return paidTickets.filter(
      (userTicket) => selectedOperation(parseISO(userTicket.seat_match.match.match_starts_at)),
    );
  }, [selectedTab, paidTickets]);

  return (
    <div className="PurchaseHistory">
      <div className="PurchaseHistory-container">
        <div className="PurchaseHistory-container_tickets">
          <div className="PurchaseHistory-container_tickets-header">
            <h1>
              {t('PurchaseHistory.Title')}
              {' '}
              {club_name || team}
            </h1>
          </div>
          <div className="PurchaseHistory-container_tickets-buttons">
            <ActionButton
              title={t('PurchaseHistoryCard.Upcoming')}
              isActive={selectedTab === TAB_NAMES.UPCOMMING}
              action={() => {
                setSelectedTab(TAB_NAMES.UPCOMMING);
              }}
            />
            <ActionButton
              title={t('PurchaseHistoryCard.RequiresAction')}
              isActive={selectedTab === TAB_NAMES.ACTION_REQUIRED}
              action={() => {
                setSelectedTab(TAB_NAMES.ACTION_REQUIRED);
              }}
            />
            <ActionButton
              title={t('PurchaseHistoryCard.Past')}
              isActive={selectedTab === TAB_NAMES.PAST}
              action={() => {
                setSelectedTab(TAB_NAMES.PAST);
              }}
            />
          </div>
        </div>
        {!loading ? (
          <div className="PurchaseHistory-container_ticketList">
            {noTicketsFound && <NotFoundCard />}
            {selectedTab !== TAB_NAMES.ACTION_REQUIRED
              && timeAlignedUserTickets.map((userTicket: IUserTicket) => (
                <PurchaseHistoryCard
                  currentTeamShortname={team || ''}
                  userTicket={userTicket}
                  key={userTicket.id}
                  paid
                  disabled={selectedTab === TAB_NAMES.PAST}
                />
              ))}
            {selectedTab === TAB_NAMES.ACTION_REQUIRED
              && unpaidTickets.map((ticket: any) => (
                <PurchaseHistoryCard
                  currentTeamShortname={team || ''}
                  userTicket={ticket}
                  key={ticket.id}
                  paid={false}
                />
              ))}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default RecoilHoc(PurchaseHistory);
