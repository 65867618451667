import '../../SASS/base/RowPage/_Rows.scss';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useTransition, animated } from 'react-spring';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router';
import { IPriceGroup, IRow } from '../../Interfaces';
import {
  chosenRowState,
  chosenRowIdState,
  chosenTicketState,
} from '../../atom';

interface IRowCardProps {
  wholeRow: IRow;
}

const RowCard = ({ wholeRow }: IRowCardProps) => {
  const [hover, setHover] = useState<boolean>(false);
  const [chosenRow, setChosenRow] = useRecoilState<IRow | undefined>(
    chosenRowState,
  );
  const [, setChosenRowId] = useRecoilState<number>(chosenRowIdState);
  const [chosenTickets] = useRecoilState(chosenTicketState);

  const { team, matchId } = useParams<string>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const totalTicketQuantity = chosenTickets
    .map((ticket: IPriceGroup) => ticket.amount)
    .reduce((partialSum, a) => partialSum + a, 0);

  const transition = useTransition(hover, {
    config: { duration: 150 },
    from: { x: 20, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    leave: { x: 20, opacity: 0 },
  });

  const setSelectedRow = () => {
    setChosenRow(wholeRow);
    setChosenRowId(wholeRow.id);
    navigate(`/${team}/${matchId}/seats`);
  };

  return wholeRow.available_seats !== 0
    && totalTicketQuantity <= wholeRow.available_seats ? (
      <button
        type="button"
        className="rowCard"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={setSelectedRow}
      >
        <h1 className="rowCard-title">
          {t('RowsPage.Row')}
          {' '}
          {wholeRow.number}
        </h1>

        <h3
          style={
          hover
            ? { marginRight: '3rem', transition: 'margin-right .2s ease' }
            : { marginRight: '0rem', transition: 'margin-right .2s ease' }
        }
          className="rowCard-seatsLeft"
        >
          <span>&#8226;</span>
          {wholeRow.available_seats}
          {' '}
          {wholeRow.available_seats === 1
            ? t('RowsPage.availableSeat')
            : t('RowsPage.availableSeats')}
        </h3>

        {transition((style, item) => (item ? (
          <animated.div style={style} className="arrowContainer">
            <RiArrowRightSLine />
          </animated.div>
        ) : (
          ''
        )))}
      </button>
    ) : (
      <button
        disabled
        type="button"
        className="rowCard disabled"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <h1 className="rowCard-title">
          {t('RowsPage.Row')}
          {' '}
          {wholeRow.number}
        </h1>

        <h3 className="rowCard-seatsLeft">
          <span>&#8226;</span>
          {wholeRow.available_seats}
          {' '}
          {wholeRow.available_seats === 1
            ? t('RowsPage.availableSeat')
            : t('RowsPage.availableSeats')}
        </h3>
      </button>
    );
};

export default RowCard;
