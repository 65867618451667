import React from 'react';
import { useRecoilValue } from 'recoil';
import { CurrentTeamInfo } from '../atom';

// eslint-disable-next-line func-names
const RecoilHoc = (Component:any) => function (props:any) {
  const currentTeamInfo = useRecoilValue(CurrentTeamInfo);
  return (
    <Component
      {...props}
      currentTeamInfo={currentTeamInfo}
    />
  );
};

export default RecoilHoc;
